import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { useRef } from 'react';
import { useState } from 'react';

import { AppContext } from '../AppContext';
import { compressImg } from '../../util/resizer';
import ContextMenu from './ContextMenu';

import './PhotosInput.scss';


const PhotoInput = ({ value, disabled, readOnly, maxSize, onChange }) => {

    const { t } = useContext(AppContext);
    const inputRef = useRef();

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [imgIdx, setImgIdx] = useState(null);

    const handleContextMenu = (e, i) => {
        if (disabled || readOnly) {
            return;
        }

        e.preventDefault();
        e.persist();

        setShowContextMenu(true);
        setMousePosition({ x: e.clientX, y: e.clientY });
        setImgIdx(i);
    }

    const loadPhoto = async e => {
        if (disabled || readOnly) {
            return;
        }

        let files = e.target.files || e.dataTransfer.files;
        if (files && files.length) {
            let maxInsertImageFileSize = (maxSize || 6) * 1024 * 1024; // 6MB
            let allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif'];

            
            for (let i = 0; i < files.length; i++) {
                let file = files[i];

                if (allowedTypes.indexOf(file.type) < 0) {
                    console.error('Unsupported file type', file);
                    // addNotification({
                    //     severity: 4,
                    //     autoclose: 10,
                    //     description: t('PhotoInput.UnsupportedFileType')
                    // });
                    continue;
                }
                if (file.size > maxInsertImageFileSize) {
                    console.error('Max file size exceeded.', file);
                    // addNotification({
                    //     severity: 4,
                    //     autoclose: 10,
                    //     description: t('PhotoInput.MaxFileSize')
                    // });
                    continue;
                }

                let compressedFile = await compressImg(file);
                let photo = {
                    file: compressedFile,
                    imgUrl: URL.createObjectURL(compressedFile),
                    new: true,
                    orig: file,
                    previewUrl: URL.createObjectURL(file)
                };
                let newPhotos = [...(value || []), photo];
                invokeChange(newPhotos);
            }
        }
    }

    const invokeChange = val => {
        if (onChange) {
            onChange(val);
        }
    }

    const closeContextMenu = () => {
        setShowContextMenu(false);
    }

    const deleteImg = () => {
        if (disabled || readOnly) {
            return;
        }

        if (imgIdx >= 0 && value && value.length > imgIdx) {
            let photos = [...value];
            if (value[imgIdx].new) {
                photos.splice(imgIdx, 1);
            } else {
                photos[imgIdx] = { ...value[imgIdx], remove: true };
            }
            invokeChange(photos);
        }
        closeContextMenu();
    }

    const add = e => {
        e.preventDefault();
        const element = inputRef.current;
        if (element) {
            element.click();
            element.value = null;
        }
    };

    return (
        <div className="photos-container">
                {(value || []).map((o, i) => {
                    if (o.remove) {
                        return null;
                    }

                    return (
                        <div className="img-container" key={i}>
                            <img
                                src={o.imgUrl}
                                alt={t('PhotoInput.Photo')}
                                onClick={() => {
                                    window.open(o.previewUrl || o.imgUrl, "_blank");
                                }}
                                onContextMenu={e => handleContextMenu(e, i)}
                            />
                        </div>
                    );
                })}
                {!readOnly &&
                    <div className="btn-container">
                        <button
                            className="button main-primary min-width add-photo"
                            disabled={disabled}
                            onClick={add}
                        >
                            <input
                                type='file'
                                ref={inputRef}
                                accept="image/gif,image/jpeg,image/png,image/bmp"
                                capture='environment'
                                onChange={loadPhoto}
                                onClick={e => e.stopPropagation()}
                            />
                            <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </button>
                    </div>
                }
                {showContextMenu &&
                    <ContextMenu
                        mousePosition={mousePosition}
                        onClose={closeContextMenu}
                    >
                        <ul>
                            <li onClick={deleteImg}>
                                <span className="context-link">{t('Btn.Delete')}</span>
                            </li>
                        </ul>
                    </ContextMenu>
                }
            </div>
    );
}

export default PhotoInput;