import React, { Component, Fragment, useContext } from 'react';
import { Route } from 'react-router';
import { Login } from './Login'
import { Logout } from './Logout'
import { ApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from './ErrorPage';
import { AppContext } from '../AppContext';
//import ErrorBoundary from './ErrorBoundary';

export default class ApiAuthorizationRoutes extends Component {

    render() {
        return (
            <Fragment>
                <Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)} />
                <Route path={ApplicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)} />
                <Route path={ApplicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)} />
                <Route path={ApplicationPaths.Profile} render={() => loginAction(LoginActions.Profile)} />
                <Route path={ApplicationPaths.Register} render={() => loginAction(LoginActions.Register)} />
                <Route path={ApplicationPaths.LogOut} render={() => logoutAction(LogoutActions.Logout)} />
                <Route path={ApplicationPaths.LogOutCallback} render={() => logoutAction(LogoutActions.LogoutCallback)} />
                <Route path={ApplicationPaths.LoggedOut} render={() => logoutAction(LogoutActions.LoggedOut)} />
            </Fragment>);
    }
}

function loginAction(name) {
    return (
        <Boundary>
            <Login action={name}></Login>
        </Boundary>
    );
}

function logoutAction(name) {
    return (
        <Boundary>
            <Logout action={name}></Logout>
        </Boundary>
    );
}

function AuthError(props) {
    const { t } = useContext(AppContext);

    let msg = t('App.AuthBoundMsg');
    if (_.get(props, 'error.message')) {
        msg = (
            <>
                <div>{msg}</div>
                <div>{`(${props.error.message})`}</div>
            </>
        );
    }

    return (
        <ErrorPage
            title={t('App.AuthBoundTit')}
            message={msg}
            {...props}
        />
    );
}

function Boundary({ children }) {
    return (
        <ErrorBoundary
            FallbackComponent={AuthError}
            onReset={(details) => {
                // Reset the state of your app so the error doesn't happen again
                window.location.assign('/');
            }}
        >
            {children}
        </ErrorBoundary>
    );
}