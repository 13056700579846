import React from 'react';
import Selectbox from './selectbox/Selectbox';

import './SelectItem.js.scss';


const SelectItem = React.forwardRef(({ label, ...props }, ref) => {

    return (
        <div className='labval-item'>
            <label>{label}</label>
            <div className='value'>
                <Selectbox
                    ref={ref}
                    {...props}
                />
            </div>
        </div>
    );
})

export default SelectItem;