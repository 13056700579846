import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classNames from 'classnames';
import React, { useContext } from 'react';
import { invoke } from '../../util/helper';
import { AppContext } from '../AppContext';

import './SidePanelSelector.scss';


export const PAN_FILTER = 'filter';
export const PAN_RESERV = 'reserv';

const SidePanelSelector = ({ selected, onChange }) => {

    const { t } = useContext(AppContext);

    return (
        <div className="side-panel-selector border-bottom">
            <SidePanelSelectorItem
                title={t('SidePanSel.Filter')}
                icon="fa-solid fa-filter"
                selected={selected === PAN_FILTER}
                onClick={() => invoke(onChange, PAN_FILTER)}
            />
            <SidePanelSelectorItem
                title={t('SidePanSel.Reserv')}
                icon="fa-regular fa-calendar"
                selected={selected === PAN_RESERV}
                onClick={() => invoke(onChange, PAN_RESERV)}
            />
        </div>
    );
}

const SidePanelSelectorItem = ({ title, icon, selected, onClick }) => {
    return (
        <div
            className={classNames('side-panel-selector-item', { selected })}
            title={title}
            onClick={onClick}
        >
            <div className="ico"><FontAwesomeIcon icon={icon} /></div>
            {title && <div className="text">{title}</div>}
        </div>
    );
}

export default SidePanelSelector;