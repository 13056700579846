import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DropdownMenu, Input, DropdownItem } from 'reactstrap';

import './DropdownInput.scss';


const DropdownInput = ({ className, value, onChange, readOnly, placeholder, autoFocus, innerRef, options }) => {

    const inputRef = innerRef || useRef();
    const containerRef = useRef();

    const [focused, setFocused] = useState(false);
    const [showDrop, setShowDrop] = useState(false);
    const [selected, setSelected] = useState(null);

    const items = useMemo(() => {
        return (options || []).filter(o => !value || o.toLowerCase().includes(value.toLowerCase()));
    }, [value, options]);

    useEffect(() => {
        let val = focused && items.length > 0;
        if (items.length === 1 && items[0] === value) {
            val = false;
        }
        setShowDrop(val);
    }, [items, focused]);

    useEffect(() => {
        setSelected(null);
    }, [items]);

    const onInputChange = e => {
        invokeChange(e.target.value);
    };

    const invokeChange = v => {
        if (onChange) {
            onChange(v);
        }
    }

    const onFocus = () => {
        setFocused(true);
    }

    const onBlur = e => {
        if (!containerRef.current.contains(e.relatedTarget)) {
            setFocused(false);
        }
    }

    const select = v => {
        invokeChange(v);
        setFocused(false);
    }

    const onKeyDown = e => {
        // console.log('onKeyDown', e);
        if (e) {
            switch (e.keyCode) {
                case 9: // TAB
                    if (selected !== null && items.length > selected) {
                        select(items[selected]);
                    }
                    break;
                case 13: // ENTER
                    if (selected !== null && items.length > selected) {
                        select(items[selected]);
                    }
                    break;
                case 27: // ESC
                    setFocused(false);
                    break;
                case 38: // UP
                    if (items.length > 0) {
                        if (selected === null) {
                            setSelected(items.length - 1);
                        } else {
                            if (selected > 0) {
                                setSelected(selected - 1);
                            } else {
                                setSelected(items.length - 1);
                            }
                        }
                    }
                    break;
                case 40: // DOWN
                    if (items.length > 0) {
                        if (selected === null) {
                            setSelected(0);
                        } else {
                            if (selected + 1 < items.length) {
                                setSelected(selected + 1);
                            } else {
                                setSelected(0);
                            }
                        }
                    }
                    break;
            }
        }
    }

    return (
        <div
            className={classNames('dropdown-input', className)}
            ref={containerRef}
        >
            <Input
                value={value}
                onChange={onInputChange}
                readOnly={readOnly || false}
                placeholder={placeholder || ''}
                autoFocus={autoFocus || false}
                innerRef={inputRef}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <DropdownMenu
                className={classNames({ show: showDrop })}
            >
                {items.map((o, i) => {
                    return (
                        <div
                            key={i}
                            className={classNames('dropdown-item', { selected: i === selected })}
                            onClick={() => select(o)}
                        >{o}</div>
                    );
                })}
            </DropdownMenu>
        </div>
    );
}

export default DropdownInput;