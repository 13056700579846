import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import CheckInput from './CheckInput';
import _ from 'lodash';
import * as classNames from 'classnames';
import { arraySort } from '../../util/sort';


const CarFilter = ({ cars, value, className, onChange }) => {

    const { t, pathConfig } = useContext(AppContext);

    const [all, setAll] = useState(!(value || []).length);
    const [sortedCars, setSortedCars] = useState([]);
    const [selected, setSelected] = useState((value || []).reduce((s, o) => {
        s[o] = true;
        return s;
    }, {}));

    useEffect(() => {
        let sc = arraySort(cars || [], 'name');
        setSortedCars(sc);
        setSelected(s => {
            let deleted = false;
            let selectedNew = { ...s };
            Object.keys(selectedNew).forEach(k => {
                if (!_.some(sc, o => o.id == k)) {
                    delete selectedNew[k];
                    deleted = true;
                }
            })
            if (deleted) {
                return selectedNew;
            } else {
                return s;
            }
        });
    }, [cars]);

    const invokeChange = (all, selected) => {
        if (onChange) {
            if (all) {
                onChange(null);
            } else {
                onChange(sortedCars.filter(o => selected[o.id]).map(o => o.id));
            }
        }
    }

    let title = t('Filter.Title');
    if (pathConfig && pathConfig.filterName) {
        title += ` ${pathConfig.filterName}`;
    }

    return (
        <div className={classNames('car-filter', className)}>
            <div className="car-filter-header">
                <div className="car-filter-title">{title}</div>
                <div
                    className="check-all"
                    onClick={e => {
                        setAll(true);
                        setSelected({});
                        invokeChange(true, {});
                    }}
                >
                    <span className="all-text">{t('Filter.All')}</span>
                    <CheckInput
                        value={all}
                        onClick={(e, ch) => {
                            e.stopPropagation();
                            setAll(true);
                            setSelected({});
                            invokeChange(true, {});
                        }}
                    />
                </div>
            </div>
            <div className="car-filter-list">
                {sortedCars.map(o => {
                    return (
                        <div
                            key={o.id}
                            className="car-filter-item"
                            onClick={e => {
                                if (!!selected[o.id]) {
                                    let sel = { ...selected };
                                    delete sel[o.id];
                                    let all = !Object.keys(sel).length;
                                    setSelected(sel);
                                    setAll(all);
                                    invokeChange(all, sel);
                                } else {
                                    let sel = { ...selected };
                                    sel[o.id] = true;
                                    setSelected(sel);
                                    setAll(false);
                                    invokeChange(false, sel);
                                }
                            }}
                        >
                            <div className='img-container'>
                                {o.imgId &&
                                    <img
                                        src={`api/img/${o.imgId}`}
                                        title={o.name}
                                    />
                                }
                                <div className="title">{o.name}</div>
                            </div>
                            <div className="check-container">
                                <CheckInput
                                    value={Object.keys(selected || {}).includes(`${o.id}`)}
                                    onClick={(e, ch) => {
                                        e.stopPropagation();
                                        if (ch) {
                                            let sel = { ...selected };
                                            delete sel[o.id];
                                            let all = !Object.keys(sel).length;
                                            setSelected(sel);
                                            setAll(all);
                                            invokeChange(all, sel);
                                        } else {
                                            let sel = { ...selected };
                                            sel[o.id] = true;
                                            setSelected(sel);
                                            setAll(false);
                                            invokeChange(false, sel);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default CarFilter;