export const LSK_APP = 'carshare';
export const LSK_LANG = 'language';

export function getLocStorItem(key) {
    if (!window.localStorage) {
        return undefined;
    }
    
    let lsKey = `${LSK_APP}_${key}`;
    // console.log('get ls item', lsKey);
    let item = window.localStorage.getItem(lsKey);
    if (item) {
        return (JSON.parse(item) || {}).val;
    }
    return undefined;
}

export function setLocStorItem(key, value) {
    if (!window.localStorage) {
        return;
    }

    let lsKey = `${LSK_APP}_${key}`;
    // console.log('set ls item', lsKey);
    window.localStorage.setItem(lsKey, JSON.stringify({ val: value }));
}

export function remLocStorItem(key) {
    if (!window.localStorage) {
        return;
    }

    let lsKey = `${LSK_APP}_${key}`;
    // console.log('remove ls item', lsKey);
    window.localStorage.removeItem(lsKey);
}
