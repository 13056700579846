import React, { useContext } from 'react';
import Select, { components } from 'react-select';
import { selectReadOnlyStyle, selectStyle } from '../../style/react-select';
import { AppContext } from '../AppContext';


const NoOptionsMessage = props => {

    const { t } = useContext(AppContext);

    return (
        <components.NoOptionsMessage {...props}>
            <span>{t('Select.NoOptions')}</span>
        </components.NoOptionsMessage>
    );
};

const imgLabel = o => o && typeof (o.label) === 'string' ? {
    ...o,
    label: (
        <div className='custom-select-item'>
            {o.imgId &&
                <img src={`api/img/${o.imgId}`} />
            }
            <span>{o.label}</span>
        </div>
    )
} : o;

const Selectbox = React.forwardRef(({ readonly, isClearable, value, options, ...otherProps }, ref) => {

    return (
        <Select
            ref={ref}
            styles={readonly ? selectReadOnlyStyle : selectStyle}
            isSearchable={!readonly}
            openMenuOnClick={!readonly}
            menuIsOpen={readonly ? false : undefined}
            components={{ NoOptionsMessage }}
            onMenuClose={() => {
                //console.log('dev', 'onMenuClose', document.activeElement);
                document.activeElement?.blur();
            }}
            isClearable={isClearable && !readonly}
            value={imgLabel(value)}
            options={(options || []).map(imgLabel)}
            {...otherProps}
        />
    );
})

export default Selectbox;