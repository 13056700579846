import React from 'react';
import { useContext } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AppContext } from '../components/AppContext';


export const FSRMT_PHONE = 'ReqPhone';
export const FSRMT_IDENTITY = 'ReqIdentity';
export const FSRMT_PHONEIDENTITY = 'ReqPhoneAndIdentity';

function FinishSettingsRequiredModal({ textKey, onClick, onClose }) {

    const { t } = useContext(AppContext);

    return (
        <Modal
            className='finish-settings-required-modal'
            isOpen={true}
            autoFocus={false}
            backdrop='static'
            centered
            size="sm"
        >
            <ModalHeader toggle={onClose}>
                <span>{t('FinishSettingsRequiredModal.Title')}</span>
            </ModalHeader>
            <ModalBody>
                <p>{t(`FinishSettingsRequiredModal.${textKey}`)}</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="carshare"
                    onClick={onClick}
                >{t('FinishSettingsRequiredModal.Btn')}</Button>
            </ModalFooter>
        </Modal>
    );
}

export default FinishSettingsRequiredModal;