const de = {
    "App": {
        "AuthBoundMsg": "Bitte wenden Sie sich an den Administrator und beschreiben Sie, wie der Fehler aufgetreten ist.",
        "AuthBoundTit": "Authentifizierungsfehler",
        "ConfigError": "Fehler beim Laden der Konfiguration. Bitte versuchen Sie es erneut oder wenden Sie sich an Ihren Systemadministrator.",
        "ConfigLoadign": "Konfiguration wird geladen...",
        "Init": "Anwendung initialisieren...",
        "ProcLogin": "Anmeldung",
        "ProcLoginClbck": "Anmeldung",
        "ProcLoginClbckTxt": "Ich verarbeite Benutzerinformationen...",
        "ProcLoginTxt": "Ich sende eine Login-Anfrage...",
        "ProcLogout": "Ausloggen",
        "ProcLogoutClbck": "Ausloggen",
        "ProcLogoutClbckTxt": "Ich bearbeite die Antwort...",
        "ProcLogoutTxt": "Ich sende eine Abmeldeanfrage...",
        "ProcPay": "Zahlung",
        "ProcPayCallback": "Zahlungsabwicklung..."
    },
    "Btn": {
        "Accept": "Bestätigen",
        "Cancel": "Stornieren",
        "Capture": "Machen Sie ein Foto",
        "Clear": "Löschen",
        "Close": "Schließen",
        "Delete": "Löschen",
        "Login": "Anmeldung",
        "Logout": "Ausloggen",
        "No": "NEIN",
        "Pay": "Zahlen",
        "Save": "Auferlegen",
        "Send": "Schicken",
        "TakePhoto": "Mach ein Foto...",
        "Yes": "Ja"
    },
    "Carshare": {
        "NoCar": "Derzeit sind keine Fahrzeuge zu vermiete"
    },
    "Const": {
        "AllModel": "Alle Modelle",
        "NoModel": "Kein Modell"
    },
    "Contacts": {
        "InfoSupport": "Informationsunterstützung",
        "Manual": "Handbuch",
        "Manuals": {
            "Main": "Carsharing",
            "Main_auto-lion": "Carsharing AUTO-LION",
            "Main_carsharingkacmacek": "CARsharing Kačmáček",
            "Main_kb": "KB - Bikesharing",
            "Main_malekarpaty": "Malé Karpaty - BikeSharing",
            "Main_mkparking": "Malé Karpaty - BikeParking",
            "MainFile": "xmarton_carsharing_manual_cs.pdf",
            "MainFile_auto-lion": "carsharing_auto-lion_manual_cs.pdf",
            "MainFile_carsharingkacmacek": "carsharing_kacmacek_manual_cs.pdf",
            "MainFile_kb": "kb_bikesharing_manual_cs.pdf",
            "MainFile_malekarpaty": "male_karpaty_bikesharing_manual_cs.pdf",
            "MainFile_mkparking": "male_karpaty_bikeparking_manual_cs.pdf",
            "MainName": "Xmarton Carsharing - Návod.pdf",
            "MainName_auto-lion": "Carsharing AUTO-LION - Návod.pdf",
            "MainName_carsharingkacmacek": "CARsharing Kačmáček - Návod.pdf",
            "MainName_kb": "KB BikeSharing - Návod.pdf",
            "MainName_malekarpaty": "Malé Karpaty BikeSharing - Návod.pdf",
            "MainName_mkparking": "Malé Karpaty BikeParking - Návod.pdf"
        },
        "ServisSupport": "Unterstützung beim Fahrradservice",
        "Support": "Technische Unterstützung",
        "Title": "Handbuch und Kontakte"
    },
    "Damage": {
        "Data": "Daten",
        "DataNotLoadedErr": "Fahrzeugdaten konnten nicht geladen werden.",
        "Email": "E-Mail des Fahrers",
        "EmailPlaceholder": "Geben Sie die E-Mail-Adresse des Fahrers ein",
        "FindCar": "Suche nach einem Fahrzeug",
        "Name": "Name des Fahrers",
        "NamePlaceholder": "Geben Sie den Namen des Fahrers ein",
        "NoDataErr": "Nicht vorhandenes oder nicht autorisiertes Fahrzeug.",
        "RegNum": "Kennzeichen",
        "RegNumPlaceholder": "Geben Sie die Registrierungsnummer ein",
        "Title": "MÄNGEL DES FAHRZEUGS"
    },
    "Damages": {
        "NewBtn": "Neu melden",
        "NoDamages": "Es sind keine Mängel am Fahrzeug gemeldet.",
        "NoNameEmail": "Bitte geben Sie zuerst Ihren Namen und Ihre E-Mail-Adresse ein.",
        "NoRegNum": "Bitte geben Sie zuerst das Kennzeichen ein.",
        "Title": "Mängel und Beschädigungen"
    },
    "Filter": {
        "All": "alle",
        "Title": "Modelle"
    },
    "FinishSettingsRequiredModal": {
        "Btn": "Benutzereinstellungen",
        "ReqIdentity": "Bitte überprüfen Sie Ihre Identität, bevor Sie ein Fahrzeug reservieren.",
        "ReqPhone": "Bitte geben Sie die Kontakttelefonnummer ein, bevor Sie das Fahrzeug buchen.",
        "ReqPhoneAndIdentity": "Bevor Sie ein Fahrzeug reservieren, geben Sie bitte Ihre Kontakttelefonnummer ein und überprüfen Sie Ihre Identität.",
        "Title": "Abschluss der Einrichtung"
    },
    "Handover": {
        "Code": "Sammlungscode",
        "DataLoading": "Daten werden geladen...",
        "Name": "Fahrzeug",
        "NoCarId": "Keine Fahrzeug-ID.",
        "Nodata": "Es wurden keine Daten geladen.",
        "NoType": "Keine Übergabeart.",
        "ParkingNo": "Standnummer",
        "Place": "Parken",
        "ReturnAndLock": "Zurückgeben und verriegeln",
        "ReturnTitle": "RÜCKGABE DES FAHRZEUGS",
        "SuccessReturnText": "Vielen Dank und wir freuen uns auf Ihre nächste Buchung.",
        "SuccessReturnTitle": "Das Fahrzeug wurde erfolgreich zurückgegeben",
        "SuccessTakeoverText": "Die Radkette wird nun 30 Sekunden lang entriegelt. Entfernen Sie während dieser Zeit die Kette. Entriegeln Sie das Rad und führen Sie die Kette wieder in das Schloss ein.",
        "SuccessTakeoverTitle": "Das Fahrzeug wurde erfolgreich übernommen",
        "TakeAndUnlock": "Herunterladen und entsperren",
        "TakeoverTitle": "WIEDERHOLUNG DES FAHRZEUGS",
        "UnlockBike": "Entriegeln Sie das Fahrrad",
        "UnlockError": "Fehler beim Senden des Befehls zum Entsperren des Ständers.",
        "UnlockParking": "Parken entsperren",
        "UnlockSuccess": "Die Anfrage zum Entsperren des Standes wurde gesendet."
    },
    "Check": {
        "BeforeLeave": "Möchten Sie die Seite wirklich verlassen und das laufende Formular verwerfen?",
        "Consents": "Vereinbarungen",
        "Data": "Daten",
        "DataNotLoadedErr": "Fahrzeugdaten konnten nicht geladen werden.",
        "DataSaved": "Daten erfolgreich gesendet.",
        "DataSaveErr": "Beim Senden von Daten ist ein Fehler aufgetreten.",
        "Email": "E-Mail des Fahrers",
        "EmailPlaceholder": "Geben Sie die E-Mail-Adresse des Fahrers ein",
        "EmailRequired": "Bitte geben Sie den Namen und die E-Mail-Adresse des Fahrers ein.",
        "FindCar": "Suche nach einem Fahrzeug",
        "Name": "Name des Fahrers",
        "NamePlaceholder": "Geben Sie den Namen des Fahrers ein",
        "NameRequired": "Bitte geben Sie den Namen und die E-Mail-Adresse des Fahrers ein.",
        "NoDataErr": "Nicht vorhandenes oder nicht autorisiertes Fahrzeug.",
        "NotAllConsentsChecked": "Alle Einwilligungen müssen bestätigt werden.",
        "NotAllPhotosLoaded": "Bitte fügen Sie Fotos hinzu.",
        "Photos": "Fotografieren",
        "RegNum": "Kennzeichen",
        "RegNumPlaceholder": "Geben Sie die Registrierungsnummer ein",
        "ReturnBtn": "Fahrzeug zurückgeben",
        "ReturnDone": "Das Fahrzeug wurde erfolgreich zurückgegeben.",
        "Sig": "Unterschrift",
        "SigRequired": "Bitte vervollständigen Sie die Unterschrift.",
        "TakeDone": "Das Fahrzeug wurde erfolgreich übernommen.",
        "TakeoverBtn": "Fahrzeug übernehmen",
        "Title": "LIEFERUNG DES FAHRZEUGS",
        "Type": "Überprüfen Sie bei",
        "TypePlaceholder": "Wählen Sie die Art der Inspektion aus",
        "TypeRequired": "Bitte wählen Sie einen Schecktyp aus."
    },
    "Identity": {
        "Unverified": "Nicht bestätigt"
    },
    "Lang": {
        "cs": "Čeština",
        "de": "Deutsch",
        "en": "English",
        "pl": "Polski",
        "sk": "Slovenčina"
    },
    "Login": {
        "BadLogin": "Ungültige Anmeldeinformationen",
        "ByGoogle": "Anmeldung mit Google",
        "ByXmarton": "Melden Sie sich über Xmarton an",
        "ConsentLink": "https://www.xmarton.com/data/Informace_o_zpracovani_osobnich_udaju_pro_zakazniky.pdf",
        "ConsentLinkText": "Verarbeitung personenbezogener Daten",
        "ConsentTextPostfix": " zu.",
        "ConsentTextPrefix": "Indem Sie fortfahren, stimmen Sie der ",
        "ErrTitle": "Login Fehler",
        "Password": "Passwort",
        "Title": "Anmeldung",
        "Username": "E-mail"
    },
    "Logout": {
        "ErrTitle": "Abmeldefehler",
        "NotLocalLogin": "Die Abmeldung wurde in der Anwendung nicht initiiert."
    },
    "Map": {
        "ApiKeyError": "Ein Anwendungskonfigurationsfehler ist aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an Ihren Systemadministrator.",
        "EnableLocationMsg": "Bitte aktivieren Sie die Standortzugriffsberechtigung.",
        "Filter": "Modelle",
        "FindClose": "Finden Sie den nächstgelegenen",
        "LoadError": "Karte konnte nicht geladen werden, bitte versuchen Sie es erneut.",
        "Loading": "Karte wird geladen",
        "Location": "Ihr Standort",
        "Reservations": "Reservierung",
        "Satellite": "Satellitenkarte",
        "ZoomInTitle": "Hineinzoomen",
        "ZoomOutTitle": "Verzögerung"
    },
    "Nav": {
        "App": "Öffnen Sie die Anwendung",
        "Login": "Anmeldung",
        "Logouted": "Sie sind abgemeldet",
        "Title": "FAHRZEUGE ZU VERMIETEN"
    },
    "NewDamage": {
        "Category": "Kategorie",
        "CategoryRequired": "Kategorie ist erforderlich.",
        "DataSaved": "Mangel wurde erfolgreich gemeldet.",
        "DataSaveErr": "Beim Senden von Daten ist ein Fehler aufgetreten.",
        "Description": "Beschreibung",
        "DescriptionRequired": "Bitte vervollständigen Sie die Beschreibung.",
        "Event": "Versicherungsfall",
        "Photo": "Foto",
        "PhotoRequired": "Bitte fügen Sie ein Foto hinzu.",
        "RegNum": "Kennzeichen",
        "Title": "Mangel melden"
    },
    "PaymentResult": {
        "Error": "Bei der Verarbeitung des Zahlungsergebnisses ist ein Fehler aufgetreten. Bitte kontaktieren Sie Ihren Systemadministrator.",
        "Fail": "Die Zahlung wurde nicht erfolgreich durchgeführt",
        "Success": "Die Zahlung wurde erfolgreich durchgeführt",
        "Title": "Zahlung"
    },
    "PhoneConfirmModal": {
        "Code": "Bestätigungscode",
        "Text": "Um die Richtigkeit der Telefonnummer zu überprüfen, haben wir Ihnen eine SMS mit einem Bestätigungscode gesendet. Bitte geben Sie es zum Vervollständigen ein.",
        "Title": "Überprüfung der Telefonnummer"
    },
    "PhotoCapture": {
        "Title": "Fotografieren"
    },
    "PhotoInput": {
        "MaxFileSize": "Größenbeschränkung überschritten.",
        "Photo": "Foto",
        "UnsupportedFileType": "Nicht unterstütztes Dateiformat."
    },
    "Rent": {
        "Title": "Fahrzeugvermietung"
    },
    "Reservation": {
        "Cancel": {
            "Btn": "Reservierung stornieren",
            "Confirm": {
                "Question": "Möchten Sie die Reservierung wirklich stornieren?",
                "Title": "Reservierung stornieren"
            },
            "Error": "Die Reservierung wurde nicht storniert.",
            "Success": "Die Reservierung wurde erfolgreich storniert."
        },
        "Car": "Fahrzeug",
        "CarNotAvaibleForPeriod": "Das Fahrzeug ist erst im ausgewählten Zeitraum verfügbar",
        "CarPlaceholder": "Wählen Sie ein Fahrzeug aus",
        "DepositInfo": "Eine rückzahlbare Kaution {deposit} ist ebenfalls im Mietpreis enthalten.",
        "Equipment": "Gang",
        "EquipmentFor": "Ausrüstung für",
        "Finish": {
            "Btn": "Beenden Sie die Reservierung",
            "Confirm": {
                "Question": "Möchten Sie die Reservierung wirklich stornieren?",
                "Title": "Beenden Sie die Reservierung"
            },
            "Error": "Die Reservierung wurde nicht abgeschlossen.",
            "Success": "Die Reservierung wurde erfolgreich abgeschlossen."
        },
        "From": "Aus",
        "GdprLink": "Verarbeitung personenbezogener Daten",
        "GdprPrefix": "Grundsätze der",
        "CheckError": "Das Zahlungsgateway konnte nicht geöffnet werden. Bitte wenden Sie sich an den technischen Support.",
        "LastCarForPeriod": "Im ausgewählten Zeitraum ist das letzte Fahrzeug verfügbar",
        "Model": "Modell",
        "NoCarForModelPeriod": "Das angegebene Fahrzeugmodell ist im ausgewählten Zeitraum nicht verfügbar",
        "NoCarForPeriod": "Im ausgewählten Zeitraum ist kein Fahrzeug verfügbar",
        "Parking": "Parken",
        "Pay": {
            "Error": "Es ist ein Fehler aufgetreten, die Zahlung kann jetzt nicht bezahlt werden. Bitte versuchen Sie es später noch einmal oder wenden Sie sich an Ihren Systemadministrator."
        },
        "PayAndSend": "Senden und bezahlen",
        "PickUpTooltip": "Das Fahrzeug kann nur zum Zeitpunkt der Reservierung übernommen werden.",
        "Price": "Preis",
        "PriceFor": "Preis für",
        "Pricelist": "Vollständige Preisliste",
        "RepeteAfter": "Bereiten Sie nach der Bestellung die Reservierung eines weiteren Fahrzeugs vor",
        "SaveError": "Die Reservierung konnte nicht erstellt werden.",
        "SaveSuccess": "Die Reservierung wurde erfolgreich erstellt.",
        "Title": "Fahrzeugreservierung",
        "To": "Zu",
        "VopLink": "Allgemeinen Geschäftsbedingungen einverstanden",
        "VopPrefix": "Mit dem Absenden einer Bestellung erklären Sie sich mit den"
    },
    "ReservationState": {
        "Approved": "Genehmigt",
        "Canceled": "Abgesagt",
        "Finished": "Vollendet",
        "New": "warten auf die Bestätigung",
        "Paid": "Bestellt",
        "ReadyToTake": "Sie können das Fahrzeug übernehmen",
        "Rejected": "Abgelehnt",
        "Taken": "Fahrzeug gemietet",
        "Unknown": "Unbekannt",
        "WaitForPay": "Warten auf Zahlung"
    },
    "ReservList": {
        "Detail": "Buchungsdetails",
        "LoadCarDetailError": "Fahrzeugdetails konnten nicht geladen werden.",
        "LoginText": "Bitte melden Sie sich an, um Ihre Reservierungen anzuzeigen.",
        "NoParking": "Kein Parken",
        "NoReserv": "Keine Reservierungen",
        "Return": "Fahrzeug zurückgeben",
        "Takeover": "Fahrzeug übernehmen",
        "Title": "Meine Reservierungen",
        "UnknownControl": "Unbekannter Steuerungstyp",
        "Unlock": "Freischalten"
    },
    "Select": {
        "NoOptions": "Keine Optionen"
    },
    "Settings": {
        "ConfirmPhoneError": "Die Telefonnummer wurde nicht verifiziert.",
        "ConfirmPhoneSuccess": "Die Telefonnummer wurde erfolgreich verifiziert und eingerichtet.",
        "Email": "Email",
        "Identity": "Identität",
        "Lang": "Sprache",
        "Link": "Einstellungen",
        "Phone": "Telefonnummer",
        "SetPhoneError": "Beim Festlegen der Telefonnummer ist ein Fehler aufgetreten.",
        "Title": "Benutzereinstellungen",
        "VerifyIdentity": "Identität überprüfen",
        "VerifyPhone": "Überprüfen und speichern"
    },
    "SidePanSel": {
        "Filter": "Filter",
        "Reserv": "Reservierung"
    },
    "Sig": {
        "Title": "Unterschrift"
    },
    "TakeoverInfoModal": {
        "Open": "Öffnen Sie die Anwendung",
        "Text": "Zur Abholung und Rückgabe des Fahrzeugs installieren Sie bitte die mobile Anwendung XFLEET by Xmarton, mit der Sie bei der Reservierung auch das Fahrzeug entriegeln und den Start ermöglichen.",
        "Title": "Übernahme des Fahrzeugs"
    },
    "TakeoverTypes": {
        "return": "Zurückkehren",
        "takeover": "Übernehmen",
        "takeover_and_return": "Abholung und Rückgabe"
    },
    "WebTitle": "Fahrgemeinschaft"
}

export default de;