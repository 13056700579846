const cs = {
    "App": {
        "AuthBoundMsg": "Kontaktujte prosím správce a popište mu, jak k chybě došlo.",
        "AuthBoundTit": "Chyba autentikace",
        "ConfigError": "Chyba načítání konfigurace. Zkuste to znovu nebo kontaktujte správce systému.",
        "ConfigLoadign": "Načítání konfigurace...",
        "Init": "Inicializace aplikace...",
        "ProcLogin": "Přihlášení",
        "ProcLoginClbck": "Přihlášení",
        "ProcLoginClbckTxt": "Zpracovávám informace o uživateli...",
        "ProcLoginTxt": "Odesílám požadavek na přihlášení...",
        "ProcLogout": "Odhlášení",
        "ProcLogoutClbck": "Odhlášení",
        "ProcLogoutClbckTxt": "Zpracovávám odpověď...",
        "ProcLogoutTxt": "Odesílám požadavek na odhláešní...",
        "ProcPay": "Platba",
        "ProcPayCallback": "Zpracování platby..."
    },
    "Btn": {
        "Accept": "Potvrdit",
        "Cancel": "Zrušit",
        "Capture": "Vyfotit",
        "Clear": "Smazat",
        "Close": "Zavřít",
        "Delete": "Smazat",
        "Login": "Přihlásit se",
        "Logout": "Odhlásit se",
        "No": "Ne",
        "Pay": "Zaplatit",
        "Save": "Uložit",
        "Send": "Odeslat",
        "TakePhoto": "Pořídit fotku...",
        "Yes": "Ano"
    },
    "Carshare": {
        "NoCar": "Aktuálně není žádné volné vozidlo k zapůjčení"
    },
    "Const": {
        "AllModel": "Všechny modely",
        "NoModel": "Bez modelu"
    },
    "Contacts": {
        "InfoSupport": "Informační podpora",
        "Manual": "Návod",
        "Manuals": {
            "Main": "Carsharing",
            "Main_auto-lion": "Carsharing AUTO-LION",
            "Main_carsharingkacmacek": "CARsharing Kačmáček",
            "Main_kb": "KB - Bikesharing",
            "Main_malekarpaty": "Malé Karpaty - BikeSharing",
            "Main_mkparking": "Malé Karpaty - BikeParking",
            "MainFile": "xmarton_carsharing_manual_cs.pdf",
            "MainFile_auto-lion": "carsharing_auto-lion_manual_cs.pdf",
            "MainFile_carsharingkacmacek": "carsharing_kacmacek_manual_cs.pdf",
            "MainFile_kb": "kb_bikesharing_manual_cs.pdf",
            "MainFile_malekarpaty": "male_karpaty_bikesharing_manual_cs.pdf",
            "MainFile_mkparking": "male_karpaty_bikeparking_manual_cs.pdf",
            "MainName": "Xmarton Carsharing - Návod.pdf",
            "MainName_auto-lion": "Carsharing AUTO-LION - Návod.pdf",
            "MainName_carsharingkacmacek": "CARsharing Kačmáček - Návod.pdf",
            "MainName_kb": "KB BikeSharing - Návod.pdf",
            "MainName_malekarpaty": "Malé Karpaty BikeSharing - Návod.pdf",
            "MainName_mkparking": "Malé Karpaty BikeParking - Návod.pdf"
        },
        "ServisSupport": "Servisní podpora kol",
        "Support": "Technická podpora",
        "Title": "Návod a kontakty"
    },
    "Damage": {
        "Data": "Údaje",
        "DataNotLoadedErr": "Nepovedlo se načíst data vozidla.",
        "Email": "E-mail řidiče",
        "EmailPlaceholder": "Zadejte e-mail řidiče",
        "FindCar": "Vyhledat vozidlo",
        "Name": "Jméno řidiče",
        "NamePlaceholder": "Zadejte jméno řidiče",
        "NoDataErr": "Neexistující nebo neautorizované vozidlo.",
        "RegNum": "Registrační značka",
        "RegNumPlaceholder": "Zadejte registrační značku",
        "Title": "NEDOSTATKY VOZIDLA"
    },
    "Damages": {
        "NewBtn": "Nahlásit nový",
        "NoDamages": "Na vozidle nejsou nahlášeny žádné nedostatky.",
        "NoNameEmail": "Nejprve prosím vyplňte jméno a e-mail.",
        "NoRegNum": "Nejprve prosím vyplňte registrační značku.",
        "Title": "Nedostatky a poškození"
    },
    "Filter": {
        "All": "vše",
        "Title": "Modely"
    },
    "FinishSettingsRequiredModal": {
        "Btn": "Nastavení uživatele",
        "ReqIdentity": "Před rezervací vozidla prosím ověřte Vaši totožnost.",
        "ReqPhone": "Před rezervací vozidla prosím doplňte kontaktní telefon.",
        "ReqPhoneAndIdentity": "Před rezervací vozidla prosím doplňte kontaktní telefon a ověřte Vaši totožnost.",
        "Title": "Dokončení nastavení"
    },
    "Handover": {
        "Code": "Kód pro vyzvednutí",
        "DataLoading": "Data se načítají...",
        "Name": "Vozidlo",
        "NoCarId": "Žádné id vozidla.",
        "Nodata": "Nebyla načtena žádná data.",
        "NoType": "Žádný typ předání.",
        "ParkingNo": "Číslo stání",
        "Place": "Parkoviště",
        "ReturnAndLock": "Vrátit a zamknout",
        "ReturnTitle": "VRÁCENÍ VOZIDLA",
        "SuccessReturnText": "Děkujeme a těšíme se na další rezervaci.",
        "SuccessReturnTitle": "Vozidlo bylo úspěšně vráceno",
        "SuccessTakeoverText": "Nyní se odjistí řetěz kola na 30 sekund, během kterých řetěz vyjměte. Odjistěte kolo a řetěz vraťte zpět do zámku.",
        "SuccessTakeoverTitle": "Vozidlo bylo úspěšně převzato",
        "TakeAndUnlock": "Převzít a odemknout",
        "TakeoverTitle": "PŘEVZETÍ VOZIDLA",
        "UnlockBike": "Odemknout kolo",
        "UnlockError": "Chyba odeslání příkazu pro odemknutí stání.",
        "UnlockParking": "Odemknout stání",
        "UnlockSuccess": "Požadavek na odemknutí stání byl odeslán."
    },
    "Check": {
        "BeforeLeave": "Opravdu chcete opustit stránku a zahodit rozpracovaný fromulář?",
        "Consents": "Souhlasy",
        "Data": "Údaje",
        "DataNotLoadedErr": "Nepovedlo se načíst data vozidla.",
        "DataSaved": "Data byla úspěšně odeslána.",
        "DataSaveErr": "Došlo k chybě při odesílání dat.",
        "Email": "E-mail řidiče",
        "EmailPlaceholder": "Zadejte e-mail řidiče",
        "EmailRequired": "Doplňte prosím jméno a e-mail řidiče.",
        "FindCar": "Vyhledat vozidlo",
        "Name": "Jméno řidiče",
        "NamePlaceholder": "Zadejte jméno řidiče",
        "NameRequired": "Doplňte prosím jméno a e-mail řidiče.",
        "NoDataErr": "Neexistující nebo neautorizované vozidlo.",
        "NotAllConsentsChecked": "Je potřeba potvrdit všechny souhlasy.",
        "NotAllPhotosLoaded": "Doplňte prosím fotografie.",
        "Photos": "Vyfocení",
        "RegNum": "Registrační značka",
        "RegNumPlaceholder": "Zadejte registrační značku",
        "ReturnBtn": "Vrátit vozidlo",
        "ReturnDone": "Vozidlo bylo úspěšně vráceno.",
        "Sig": "Podpis",
        "SigRequired": "Doplňte prosím podpis.",
        "TakeDone": "Vozidlo bylo úspěšně převzato.",
        "TakeoverBtn": "Převzít vozidlo",
        "Title": "PŘEDÁNÍ VOZIDLA",
        "Type": "Kontrola při",
        "TypePlaceholder": "Vyberte typ kontroly",
        "TypeRequired": "Vyberte prosím typ kontroly."
    },
    "Identity": {
        "Unverified": "Neověřena"
    },
    "Lang": {
        "cs": "Čeština",
        "de": "Deutsch",
        "en": "English",
        "pl": "Polski",
        "sk": "Slovenčina"
    },
    "Login": {
        "BadLogin": "Neplatné přihlašovací údaje",
        "ByGoogle": "Přihlásit se přes Google",
        "ByXmarton": "Přihlásit se přes Xmarton",
        "ConsentLink": "https://www.xmarton.com/data/Informace_o_zpracovani_osobnich_udaju_pro_zakazniky.pdf",
        "ConsentLinkText": "zpracováním osobních údajů",
        "ConsentTextPostfix": ".",
        "ConsentTextPrefix": "Pokračováním souhlasíte se ",
        "ErrTitle": "Chyba přihlášení",
        "Password": "Heslo",
        "Title": "Přihlášení",
        "Username": "E-mail"
    },
    "Logout": {
        "ErrTitle": "Chyba odhlášení",
        "NotLocalLogin": "Odhlášení nebylo iniciováno v aplikaci."
    },
    "Map": {
        "ApiKeyError": "Došlo k chybě konfigurace aplikace. Zkuste to prosím znovu nebo kontaktujte správce systému.",
        "EnableLocationMsg": "Povolte prosím oprávnění pro přístup k poloze.",
        "Filter": "Modely",
        "FindClose": "Najít nejbližší",
        "LoadError": "Nepodařilo se načít mapu, zkuste to prosím znovu.",
        "Loading": "Načítání mapy",
        "Location": "Vaše poloha",
        "Reservations": "Rezervace",
        "Satellite": "Satelitní mapa",
        "ZoomInTitle": "Přiblížit",
        "ZoomOutTitle": "Oddálit"
    },
    "Nav": {
        "App": "Otevřít aplikaci",
        "Login": "Přihlásit se",
        "Logouted": "Jste odhlášeni",
        "Title": "VOZIDLA K ZAPŮJČENÍ"
    },
    "NewDamage": {
        "Category": "Kategorie",
        "CategoryRequired": "Kategorie je povinná.",
        "DataSaved": "Nedostatek byl úspěšně nahlášen.",
        "DataSaveErr": "Došlo k chybě při odesílání dat.",
        "Description": "Popis",
        "DescriptionRequired": "Doplňte prosím popis.",
        "Event": "Pojistná událost",
        "Photo": "Fotografie",
        "PhotoRequired": "Doplňte prosím fotografii.",
        "RegNum": "Registrační značka",
        "Title": "Nahlášení nedostatku"
    },
    "PaymentResult": {
        "Error": "Došlo k chybě při zpracování výsledku platby. Kontaktujte prosím správce systému.",
        "Fail": "Platba nebyla úspěšně provedena",
        "Success": "Platba byla úspěšně provedena",
        "Title": "Platba"
    },
    "PhoneConfirmModal": {
        "Code": "Ověřovací kód",
        "Text": "Pro ověření správnosti telefonního čísla jsme Vám zaslali SMS s ověřovacím kódem. Pro dokončení jej prosím zadejte.",
        "Title": "Ověření telefonního čísla"
    },
    "PhotoCapture": {
        "Title": "Pořízení fotografie"
    },
    "PhotoInput": {
        "MaxFileSize": "Byl překročen limit velikosti.",
        "Photo": "Fotka",
        "UnsupportedFileType": "Nepodporovaný typ souboru."
    },
    "Rent": {
        "Title": "Zapůjčení vozidla"
    },
    "Reservation": {
        "Cancel": {
            "Btn": "Zrušit rezervaci",
            "Confirm": {
                "Question": "Opravdu chcete zrušit rezervaci?",
                "Title": "Zrušit rezervaci"
            },
            "Error": "Rezervace nebyla zrušena.",
            "Success": "Rezervace byla úspěšně zrušena."
        },
        "Car": "Vozidlo",
        "CarNotAvaibleForPeriod": "Do zvoleného období není vozidlo volné",
        "CarPlaceholder": "Vyberte vozidlo",
        "DepositInfo": "K ceně pronájmu bude započítána i vratná kauce {deposit}.",
        "Equipment": "Výbava",
        "EquipmentFor": "Výbava pro",
        "Finish": {
            "Btn": "Ukončit rezervaci",
            "Confirm": {
                "Question": "Opravdu chcete ukončit rezervaci?",
                "Title": "Ukončit rezervaci"
            },
            "Error": "Rezervace nebyla ukončena.",
            "Success": "Rezervace byla úspěšně ukončena."
        },
        "From": "Od",
        "GdprLink": "zpracování osobních údajů",
        "GdprPrefix": "Zásady",
        "CheckError": "Platební bránu se nepodařilo otevřít, kontaktujte prosím technickou podporu.",
        "LastCarForPeriod": "Ve zvoleném období je zde volné poslední vozidlo",
        "Model": "Model",
        "NoCarForModelPeriod": "Ve zvoleném období zde není volný daný model vozidla",
        "NoCarForPeriod": "Ve zvoleném období zde není volné vozidlo",
        "Parking": "Parkoviště",
        "Pay": {
            "Error": "Došlo k chybě, platbu nyní není možné zaplatit. Zkuste to prosím později znovu nebo kontaktujte správce systému."
        },
        "PayAndSend": "Odeslat a zaplatit",
        "PickUpTooltip": "Vozidlo bude možné převzít až v čase rezervace.",
        "Price": "Cena",
        "PriceFor": "Cena pro",
        "Pricelist": "Kompletní ceník",
        "RepeteAfter": "Po objednání přichystat rezervaci dalšího vozidla",
        "SaveError": "Rezervaci se nepovedlo vytvořit.",
        "SaveSuccess": "Rezervace byla úspěšně vytvořena.",
        "Title": "Rezervace vozidla",
        "To": "Do",
        "VopLink": "obchodními podmínkami",
        "VopPrefix": "Odesláním objednávky souhlasíte s"
    },
    "ReservationState": {
        "Approved": "Schválena",
        "Canceled": "Zrušena",
        "Finished": "Ukončena",
        "New": "Čeká na schválení",
        "Paid": "Objednána",
        "ReadyToTake": "Vozidlo si můžete převzít",
        "Rejected": "Zamítnuta",
        "Taken": "Vozidlo zapůjčeno",
        "Unknown": "Neznámý",
        "WaitForPay": "Čeká na zaplacení"
    },
    "ReservList": {
        "Detail": "Detail rezervace",
        "LoadCarDetailError": "Detail vozidla se nepodařilo načíst.",
        "LoginText": "Pro zobrazení Vašich rezervací se prosím přihlaste.",
        "NoParking": "(Bez parkoviště)",
        "NoReserv": "Žádné rezervace",
        "Return": "Vrátit vozidlo",
        "Takeover": "Převzít vozidlo",
        "Title": "Moje rezervace",
        "UnknownControl": "Neznámý typ ovládání",
        "Unlock": "Odemknout"
    },
    "Select": {
        "NoOptions": "Žádné možnosti"
    },
    "Settings": {
        "ConfirmPhoneError": "Telefonní číslo nebylo ověřeno.",
        "ConfirmPhoneSuccess": "Telefonní číslo bylo úspěšně ověřeno a nastaveno.",
        "Email": "E-mail",
        "Identity": "Totožnost",
        "Lang": "Jazyk",
        "Link": "Nastavení",
        "Phone": "Telefonní číslo",
        "SetPhoneError": "Došlo k chybě nastavení telefonního čísla.",
        "Title": "Nastavení uživatele",
        "VerifyIdentity": "Ověřit totožnost",
        "VerifyPhone": "Ověřit a uložit"
    },
    "SidePanSel": {
        "Filter": "Filtr",
        "Reserv": "Rezervace"
    },
    "Sig": {
        "Title": "Podpis"
    },
    "TakeoverInfoModal": {
        "Open": "Otevřít aplikaci",
        "Text": "Pro převzetí a vrácení vozidla si prosím nainstalujte mobilní aplikaci XFLEET by Xmarton, pomocí které vozidlo v čase rezervace také odemknete a povolíte start.",
        "Title": "Převzetí vozidla"
    },
    "TakeoverTypes": {
        "return": "Vrácení",
        "takeover": "Převzetí",
        "takeover_and_return": "Převzetí a vrácení"
    },
    "WebTitle": "Carsharing"
}

export default cs;