const sk = {
    "Carshare": {
        "NoCar": "Aktuálne nie je žiadny voľný bicykel na zapožičanie"
    },
    "Contacts": {
        "Manuals": {
            "Main": "Bikesharig",
            "MainFile": "xmarton_bikesharing_manual_sk.pdf",
            "MainName": "Xmarton Bikesharing - Návod.pdf.pdf"
        }
    },
    "Damage": {
        "DataNotLoadedErr": "Nepodarilo sa načítať dáta bicykla.",
        "FindCar": "Vyhľadať bicykel",
        "NoDataErr": "Neexistujúci alebo neautorizovaný bicykel.",
        "Title": "NEDOSTATKY BICYKLA"
    },
    "Damages": {
        "NoDamages": "Na bicykli nie sú nahlásené žiadne nedostatky."
    },
    "FinishSettingsRequiredModal": {
        "ReqIdentity": "Pred rezerváciou bicykla prosím overte Vašu totožnosť.",
        "ReqPhone": "Pred rezerváciou bicykla prosím doplňte kontaktný telefón.",
        "ReqPhoneAndIdentity": "Pred rezerváciou bicykla prosím doplňte kontaktný telefón a overte Vašu totožnosť."
    },
    "Handover": {
        "Name": "Bicykel",
        "Place": "Miesto",
        "ReturnTitle": "VRÁTENIE BICYKLA",
        "SuccessReturnTitle": "Bicykel bol úspešne vrátený",
        "SuccessTakeoverTitle": "Bicykel bol úspešne prevzatý",
        "TakeoverTitle": "PREVZATIE BICYKLA"
    },
    "Check": {
        "DataNotLoadedErr": "Nepodarilo sa načítať dáta bicykla.",
        "FindCar": "Vyhľadať bicykel",
        "NoDataErr": "Neexistujúci alebo neautorizovaný bicykel.",
        "ReturnBtn": "Vrátiť bicykel",
        "ReturnDone": "Bicykel bol úspešne vrátený.",
        "TakeDone": "Bicykel bol úspešne prevzatý.",
        "TakeoverBtn": "Prevziať bicykel",
        "Title": "ODOVZDANIE BICYKLA"
    },
    "Nav": {
        "Title": "BICYKLE NA ZAPOŽIČANIE"
    },
    "Rent": {
        "Title": "Zapožičanie bicykla"
    },
    "Reservation": {
        "Car": "Bicykel",
        "CarNotAvaibleForPeriod": "Do zvoleného obdobia nie je bicykel voľný",
        "CarPlaceholder": "Vyberte bicykel",
        "LastCarForPeriod": "Vo zvolenom období je tu voľný posledný bicykel",
        "NoCarForModelPeriod": "Vo zvolenom období tu nie je voľný daný model bicykla",
        "NoCarForPeriod": "Vo zvolenom období tu nie je voľný bicykel",
        "Parking": "Miesto",
        "PickUpTooltip": "Bycikel bude možné prevziať až v čase rezervácie.",
        "RepeteAfter": "Po objednaní prichystať rezerváciu ďalšieho bicykla",
        "Title": "Rezervácia bicykla"
    },
    "ReservationState": {
        "ReadyToTake": "Bicykel si môžete prevziať",
        "Taken": "Bicykel zapožičaný"
    },
    "ReservList": {
        "LoadCarDetailError": "Detail bicykla sa nepodarilo načítať.",
        "Return": "Vrátiť bicykel",
        "Takeover": "Prevziať bicykel"
    }
}

export default sk;