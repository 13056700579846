import * as _ from 'lodash';
import * as moment from 'moment';
import * as toastr from 'toastr';
import * as path from 'path';
import classNames from 'classnames';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import Swal from 'sweetalert2'
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Tooltip } from 'react-tooltip'

import DateTimeInput, { DefaultDateTimeInputDateFormat } from './DateTimeInput';
import { AppContext } from '../AppContext';
import { toOption } from '../../util/options';
import Delimiter from '../Delimiter';
import apiService from '../../util/api';
import { invoke, isNullOrUndef, joinNullable } from '../../util/helper';
import { carToModel } from './Carshare';
import { RS_APPROVED, RS_CANCELED, RS_CREATED, RS_FINISHED } from '../../constants/reservationState';
import { TS_READY_TO_TAKE } from '../../constants/takeoverStatus';
import Selectbox from '../selectbox/Selectbox';
import { arraySort } from '../../util/sort';
import { LSK_REP_RESERV_CTX, LSK_RESERV_CTX, saveContext } from '../../util/context';
import { Fa5FadInfoCircle } from '../fontawesome5/fa5-custom';

import './ReservationModal.js.scss';
import { canPay } from '../../util/reservation';
import { invokePayment } from './CarshareMap';
import { PT_BLOCKPAY, PT_PAYFIRST } from '../../constants/PaymentType';
import { formatPrice } from '../../util/format';
import ReadonlyInput from '../ReadonlyInput';
import FinishSettingsRequiredModal, { FSRMT_PHONE } from '../../containers/FinishSettingsRequiredModal';


export const canCancelReservation = reservation => {
    const { state, takeoverStatus } = reservation || {};
    return state === RS_CREATED || (state === RS_APPROVED && !takeoverStatus);
}

export const canFinishReservation = reservation => {
    const { state, takeoverStatus } = reservation || {};
    return state === RS_APPROVED && takeoverStatus === TS_READY_TO_TAKE;
}


const toCarOption = o => {
    if (!o) {
        return null;
    }

    let res = { ...o, value: o.id, label: o.carName || o.modelName || o.id, imgId: o.modelImageId };
    return res;
}

const getItemPrice = item => {
    const { price, currency, priceDescription } = item || {};

    let res = formatPrice(price, currency);
    if (res && priceDescription) {
        res += ` (${priceDescription})`;
    }

    return res;
}

const ReservationModal = ({ data, cars, parkings, reservations, onCancel, onSubmit, providers }) => {

    const { t, user, invokeLogin, pathConfig, isInFrame, openApp, openSettings } = useContext(AppContext);

    const [parkingOptions, setParkingOptions] = useState(arraySort((parkings || []).map(toOption), 'label'));
    const [parking, setParking] = useState(null);
    const [parkingReadOnly, setParkingReadOnly] = useState(false);
    const [showParking, setShowParking] = useState(true);
    const [modelOptions, setModelOptions] = useState([]);
    const [model, setModel] = useState(null);
    const [modelReadOnly, setModelReadOnly] = useState(false);
    const [carOptions, setCarOptions] = useState((cars || []).map(toCarOption));
    const [car, setCar] = useState(null);
    const [carReadOnly, setCarReadOnly] = useState(false);
    const [from, setFrom] = useState(moment().seconds(0).milliseconds(0));
    const [to, setTo] = useState(moment().seconds(0).milliseconds(0).add(3, 'hours'));
    const [isValid, setIsValid] = useState(false);
    const [title, setTitle] = useState(t('Reservation.Title'));
    const [saving, setSaving] = useState(false);
    const [signal, setSignal] = useState(null);
    const [avaibleCars, setAvaibleCars] = useState(null);
    const [loadingAvaibleCars, setLoadingAvaibleCars] = useState(false);
    const [fromReadonly, setFromReadonly] = useState(false);
    const [toReadonly, setToReadonly] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showCancelReservation, setShowCancelReservation] = useState(false);
    const [showFinishReservation, setShowFinishReservation] = useState(false);
    const [vop, setVop] = useState('');
    const [gdpr, setGdpr] = useState('');
    const [price, setPrice] = useState('');
    const [deposit, setDeposit] = useState('');
    const [depositInfo, setDepositInfo] = useState('');
    const [info, setInfo] = useState('');
    const [priceListLink, setPriceListLink] = useState('');
    const [priceTooltipEnable, setPriceTooltipEnable] = useState(false);
    const [canChoseCar, setCanChoseCar] = useState(_.get(pathConfig, 'choseCar') === true);
    const [periodInfo, setPeriodInfo] = useState('');
    const [periodInfoRed, setPeriodInfoRed] = useState(false);
    const [canMakeReservation, setCanMakeReservation] = useState(false);
    const [showCar, setShowCar] = useState(false);
    const [dateFormat, setDateFormat] = useState(DefaultDateTimeInputDateFormat);
    const [showVopGdpr, setShowVopGdpr] = useState(false);
    const [finishSettingsRequired, setFinishSettingsRequired] = useState(null);
    const [repeat, setRepeat] = useState(false);
    const [showRepeat, setShowRepeat] = useState(false);

    const applyDuration = duration => {
        if (duration) {
            //console.log('apply duration on from');
            let dFrom = moment().seconds(0).milliseconds(0);
            let dTo = moment(dFrom).add(duration, 'seconds');
            setFrom(dFrom);
            setTo(dTo);
        }
    }

    // reservation buttons
    useEffect(() => {
        if (data.reservation) {
            let r = _.find(reservations, { id: data.reservation.id }) || data.reservation;

            //console.log('dev', 'reservation', { state: r.state, takeoverStatus: r.takeoverStatus });
            setShowCancelReservation(canCancelReservation(r));
            setShowFinishReservation(canFinishReservation(r));
        }
    }, [reservations, data]);

    // carOptions, periodInfo, periodInfoRed
    useEffect(() => {
        if (signal && canMakeReservation && !carReadOnly) {
            let carOptions = (cars || []).map(toCarOption);
            if (parking) {
                carOptions = carOptions.filter(o => o.idParkingPlace === parking.value);
            }
            if (model) {
                carOptions = carOptions.filter(o => o.idModel === model.value);
            }
            if (avaibleCars) {
                let ids = avaibleCars.map(o => o.id);
                carOptions = carOptions.filter(o => ids.includes(o.id));
            }
            arraySort(carOptions, 'label');
            setCarOptions(carOptions);

            setPeriodInfo(() => {
                switch (carOptions.length) {
                    case 0:
                        setPeriodInfoRed(true);
                        return model ? t('Reservation.NoCarForModelPeriod') : t('Reservation.NoCarForPeriod');
                    case 1:
                        setPeriodInfoRed(false);
                        return t('Reservation.LastCarForPeriod');
                    default:
                        setPeriodInfoRed(false);
                        return '';
                }
            });
        }
    }, [cars, parking, model, avaibleCars, signal, carReadOnly, canMakeReservation]);

    // periodInfo, periodInfoRed
    useEffect(() => {
        if (signal && canMakeReservation && carReadOnly) {
            let avaibleCar = getAvaibleCar(car, avaibleCars);
            setPeriodInfo(avaibleCar ? '' : t('Reservation.CarNotAvaibleForPeriod'));
            setPeriodInfoRed(!avaibleCar);
        }
    }, [car, avaibleCars, signal, carReadOnly, canMakeReservation]);

    // model
    useEffect(() => {
        if (!modelReadOnly) {
            if (model) {
                if (!_.some(modelOptions, { value: model.value })) {
                    if (modelOptions.length === 1 || (!canChoseCar && modelOptions.length > 0)) {
                        setModel(modelOptions[0]);
                    } else {
                        //console.log('dev', 'setting model null', { modelOptions, model });
                        setModel(null);
                    }
                }
            } else {
                if (modelOptions.length === 1 || (!canChoseCar && modelOptions.length > 0)) {
                    setModel(modelOptions[0]);
                }
            }
        }
    }, [modelOptions]);

    // modelOptions
    useEffect(() => {
        let noModel = { id: 0, name: t('Const.NoModel') };
        let filteredCars = (cars || []).filter(o => !parking || (o.idParkingPlace === parking.value));
        let modelOptions = _.map(_.mapKeys(filteredCars.map(o => toOption(carToModel(o) || noModel)), 'value'), o => o);
        arraySort(modelOptions, 'name');
        //console.log('dev', 'modelOptions', modelOptions);
        setModelOptions(modelOptions);
    }, [cars, parking]);

    // isInvalid
    useEffect(() => {
        setIsValid(validate());
    }, [from, to, car, avaibleCars]);

    // vop, gdpr
    useEffect(() => {
        let vop = '';
        let gdpr = '';
        if (providers) {
            let providerId = _.get(car, 'idProvider');
            if (!(providerId || providerId === 0)) {
                providerId = _.get(parking, 'idProvider');
            }
            if (providerId || providerId === 0) {
                let provider = _.find(providers, { id: providerId });
                //console.log('dev', 'provider', provider);
                if (provider) {
                    vop = provider.tradeCondition || '';
                    gdpr = provider.gdprCondition || '';
                }
            }
        }
        setVop(vop);
        setGdpr(gdpr);
    }, [parking, car, providers])

    // car
    useEffect(() => {
        //console.log('dev', 'carOptions effect', { carReadOnly, car, carOptions });
        if (!carReadOnly) {
            setCar(car => {
                if (car) {
                    if (!_.some(carOptions, { value: car.value })) {
                        if (!canChoseCar && carOptions.length > 0) {
                            return carOptions[0];
                        } else {
                            return null;
                        }
                    }
                } else {
                    if (!canChoseCar && carOptions.length > 0) {
                        return carOptions[0];
                    }
                }
                return car;
            });
        }
    }, [carOptions, canChoseCar, carReadOnly]);

    // price, info, priceListLink, priceTooltipEnable
    useEffect(() => {
        if (!(data && data.reservation)) {
            let avaibleCar = getAvaibleCar(car, avaibleCars);
            let price = getItemPrice(avaibleCar);
            setPrice(price);

            let { deposit, depositInfo, currency } = avaibleCar || {};
            setDeposit(deposit);
            if (deposit > 0) {
                if (depositInfo) {
                    setDepositInfo(depositInfo);
                } else {
                    setDepositInfo(t('Reservation.DepositInfo').replace('{deposit}', formatPrice(deposit, currency)));
                }
            } else {
                setDepositInfo('');
            }

            let { info, priceListLink } = avaibleCar || {};
            setInfo(info);
            setPriceListLink(priceListLink);
            setPriceTooltipEnable(!!(info || priceListLink));
        }
    }, [data, car, avaibleCars]);

    // canChoseCar
    useEffect(() => {
        setCanChoseCar(_.get(pathConfig, 'choseCar') === true);
    }, [pathConfig]);

    // avaibleCars
    useEffect(() => {
        if (!data.reservation) {
            let ac = new AbortController();
            loadAvaibleCars(from, to, parking, ac.signal);
            return () => {
                ac.abort();
            };
        }
    }, [from, to, parking]);

    useEffect(() => {
        setFinishSettingsRequired(c => {
            if (!user) {
                return null;
            }

            const entity = _.get(data, 'car') || _.get(data, 'parking');
            if (!entity) {
                return null;
            }

            const providerId = _.get(entity, 'idProvider');
            if (!providerId && providerId !== 0) {
                console.error('checkFinishSettingsRequired error: No provider id.')
                return null;
            }

            const provider = _.find(providers, { id: providerId });
            if (!provider) {
                console.error('checkFinishSettingsRequired error: No provider.')
                return null;
            }

            const { requireIdentityVerification, requireUserPhoneNumber } = provider;
            if (requireUserPhoneNumber && !_.get(user, 'phone')) {
                return FSRMT_PHONE;
            }
        });
    }, [data, providers, user]);

    // první nastavení dle vstupních dat
    useEffect(() => {
        let ac = new AbortController();
        setSignal(ac.signal);
        if (data.parking) {
            let parking = _.find(parkingOptions, { value: data.parking.id });
            if (parking) {
                setParking(parking);
            } else {
                parking = toOption(data.parking);
                setParkingOptions(arraySort([...parkingOptions, parking], 'label'));
                setParking(parking);
            }
            setCanMakeReservation(true);
            setShowVopGdpr(true);
            setShowRepeat(_.get(pathConfig, 'repRes') === true);
            setFromToByParkingProvider(parking, providers);
        }
        if (data.selectedModel) {
            setModel({ ...data.selectedModel, label: data.selectedModel.name });
        }
        if (data.selectedCar) {
            setCar({ ...data.selectedCar });
        }
        if (data.car) {
            let c = { ...data.car, value: data.car.id, label: data.car.carName };
            setTitle(t('Rent.Title'));
            setCarOptions([c]);
            setCar(c);
            setCarReadOnly(true);
            setShowCar(true);
            setShowParking(false);
            setFromReadonly(true);
            setParkingReadOnly(true);
            if (data.car.idModel) {
                let m = { value: data.car.idModel, label: data.car.modelName, imgId: data.car.modelImageId };
                setModel(m);
                setModelReadOnly(true);
            }
            setCanMakeReservation(true);
            setShowVopGdpr(true);
            setFromToByCarProvider(c, providers);
        }
        if (data.reservation) {
            let r = data.reservation;
            let noParking = !r.idParkingPlace;

            let c = r.idCar
                ? { value: r.idCar, label: joinNullable(' - ', r.carName, r.registrationNumber) || r.modelName || r.idCar, imgId: r.modelExternalHash }
                : { value: 0, label: '' };
            setCarOptions([c]);
            setCar(c);
            setCarReadOnly(true);
            setShowCar(noParking);

            let p = r.idParkingPlace
                ? { value: r.idParkingPlace, label: r.parkingPlaceName }
                : { value: 0, label: '' };
            setParking(p);
            setParkingReadOnly(true);
            setShowParking(!noParking);

            let m = r.modelId
                ? { value: r.modelId, label: r.modelName || r.modelId, imgId: r.modelExternalHash }
                : { value: 0, label: '' };
            setModelOptions([m]);
            setModel(m);
            setModelReadOnly(true);

            //console.log('timeFrom', { timeFrom: r.timeFrom ? moment(r.timeFrom * 1000) : null });
            setFrom(r.timeFrom ? moment(r.timeFrom * 1000) : null);
            setTo(r.timeTo ? moment(r.timeTo * 1000) : null);
            setFromReadonly(true);
            setToReadonly(true);
            setDateFormat('dd D. M. YYYY');

            setPrice(getItemPrice(r));

            setShowCancel(true);
        }
        if (data.from) {
            let from = moment(data.from);
            let now = moment();
            setFrom(from.isBefore(now) ? now : from);
        }
        if (data.to) {
            let to = moment(data.to);
            let now = moment();
            setTo(to.isBefore(now) ? now : to);
        }
        return () => {
            ac.abort();
        };
    }, []);

    const onCancelReservation = useCallback(async () => {
        const { reservation } = data || {};

        if (canCancelReservation(reservation)) {
            var confirmation = await Swal.fire({
                title: t('Reservation.Cancel.Confirm.Title'),
                text: t('Reservation.Cancel.Confirm.Question'),
                confirmButtonText: t('Btn.Yes'),
                cancelButtonText: t('Btn.No'),
                showCancelButton: true
            });
            if (confirmation.isConfirmed) {
                try {
                    let { id, idCar, timeFrom, timeTo } = reservation;
                    let resp = await apiService.updateReservation(id, { idCar, timeFrom, timeTo, state: RS_CANCELED });
                    console.log('cancelReservation success', JSON.stringify(resp.data));
                    toastr.success(t('Reservation.Cancel.Success'));
                    invoke(onSubmit);
                } catch (error) {
                    console.error(_.get(error, 'response.data') ? JSON.stringify(_.get(error, 'response.data')) : error);
                    toastr.error(_.get(error, 'response.data.message', t('Reservation.Cancel.Error')));
                }
            }
        } else {
            console.log('Reservation cannot be canceled.');
        }
    }, [data]);

    const setFromToByParkingProvider = (parking, providers) => {
        if (providers) {
            let providerId = _.get(parking, 'idProvider', '');
            if (providerId || providerId === 0) {
                let provider = _.find(providers, { id: providerId });
                if (provider) {
                    let { from, to, duration } = provider;
                    if (from && to) {
                        //console.log('dev', 'apply from/to settings', from, to);
                        let now = moment();
                        let dFrom = moment().hours(0).minutes(0).seconds(0).milliseconds(0).add(from, 'seconds');
                        let dTo = moment().hours(0).minutes(0).seconds(0).milliseconds(0).add(to, 'seconds');
                        if (dFrom.isBefore(now)) {
                            dFrom = dFrom.add(1, 'days');
                            dTo = dTo.add(1, 'days');
                        }
                        //console.log('apply provider from');
                        setFrom(dFrom);
                        setTo(dTo);
                    } else if (duration) {
                        applyDuration(duration);
                    }
                }
            }
        }
    }

    const setFromToByCarProvider = (car, providers) => {
        if (providers) {
            let providerId = _.get(car, 'idProvider', '');
            if (providerId || providerId === 0) {
                let provider = _.find(providers, { id: providerId });
                if (provider) {
                    let { duration } = provider;
                    if (duration) {
                        applyDuration(duration);
                    }
                }
            }
        }
    }

    const onPayClick = reservation => {
        invokePayment(reservation, pathConfig, t);
    };

    const finishReservation = useCallback(async () => {
        const { reservation } = data || {};

        if (canFinishReservation(reservation)) {
            var confirmation = await Swal.fire({
                title: t('Reservation.Finish.Confirm.Title'),
                text: t('Reservation.Finish.Confirm.Question'),
                confirmButtonText: t('Btn.Yes'),
                cancelButtonText: t('Btn.No'),
                showCancelButton: true
            });
            if (confirmation.isConfirmed) {
                try {
                    let { id, idCar, timeFrom, timeTo } = data.reservation;
                    let resp = await apiService.updateReservation(id, { idCar, timeFrom, timeTo, state: RS_FINISHED });
                    console.log('finishReservation success', JSON.stringify(resp.data));
                    toastr.success(t('Reservation.Finish.Success'));
                    invoke(onSubmit);
                } catch (error) {
                    console.error(_.get(error, 'response.data') ? JSON.stringify(_.get(error, 'response.data')) : error);
                    toastr.error(_.get(error, 'response.data.message', t('Reservation.Finish.Error')));
                }
            }
        } else {
            console.log('Reservation cannot be finished.');
        }
    }, [data]);

    const loadAvaibleCars = async (from, to, parking, signal) => {
        try {
            if (from || to) {
                setLoadingAvaibleCars(true);
                let input = {
                    from: from ? from.toISOString() : undefined,
                    to: to ? to.toISOString() : undefined,
                    idParkingPlace: parking ? parking.value : undefined
                };
                let resp = await apiService.getCarsForReservation(input, signal);
                if (!signal || !signal.aborted) {
                    setAvaibleCars(resp.data || []);
                }
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.error(error);
                toastr.error(t('Reservation.LoadAvaibleCarsError'));
                setAvaibleCars(null);
            }
        } finally {
            if (!signal || !signal.aborted) {
                setLoadingAvaibleCars(false);
            }
        }
    }

    const validate = () => {
        if (!from) {
            return false;
        }
        if (!to) {
            return false;
        }
        if (!car) {
            return false;
        }
        if (!avaibleCars || !avaibleCars.length) {
            return false;
        }
        if (!_.some(avaibleCars, { id: car.value })) {
            return false;
        }

        return true;
    }

    const saveRepeatReservation = () => {
        if (repeat && data.parking) {
            let ctx = {
                action: LSK_REP_RESERV_CTX,
                user: _.get(user, 'email'),
                config: _.get(pathConfig, 'key'),
                data: {
                    ...data,
                    from: from.toISOString(),
                    to: to.toISOString()
                }
            };
            saveContext(ctx, LSK_REP_RESERV_CTX);
        }
    }

    const save = async () => {
        if (!validate()) {
            console.error('Invalid data.');
            return;
        }

        try {
            setSaving(true);
            let input = {
                idProvider: car.idProvider,
                idCar: car.value,
                from: from.toISOString(),
                to: to.toISOString()
            };
            let resp = await apiService.makeReservation(input, signal);
            if (!signal || !signal.aborted) {
                toastr.success(t('Reservation.SaveSuccess'));
                saveRepeatReservation();
                invoke(onSubmit);
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.error(error);
                toastr.error(_.get(error, 'response.data.message') || t('Reservation.SaveError'));
            }
        } finally {
            if (!signal || !signal.aborted) {
                setSaving(false);
            }
        }
    }

    const login = () => {
        let ctx = {
            action: 'reservation',
            data: {
                ...data,
                from: from.toISOString(),
                to: to.toISOString(),
                selectedModel: model,
                selectedCar: toCarOption(car)
            }
        };
        if (ctx.data.parking && parking) {
            ctx.data.parking = { ...parking, id: parking.value, name: parking.label };
        }
        let id = saveContext(ctx, LSK_RESERV_CTX);
        invokeLogin({ ctxId: id });
    }

    const onFromChange = value => {
        let now = moment();
        if (value && value.isBefore(now)) {
            value = now;
        }

        //console.log('change from');
        setFrom(from => {
            setTo(to => value && value.isAfter(to)
                ? moment(value).add(moment.duration(to.diff(from)))
                : to);
            return value;
        });
    }

    const onToChange = newTo => {
        let now = moment();
        if (newTo && newTo.isBefore(now)) {
            newTo = now;
        }

        setTo(to => {
            //console.log('change from within change to');
            setFrom(from => {
                let newFrom = newTo && newTo.isBefore(from)
                    ? moment(newTo).subtract(moment.duration(to.diff(from)))
                    : from;

                if (newFrom && newFrom.isBefore(now)) {
                    newFrom = now;
                }

                return newFrom;
            });
            return newTo;
        })
    }

    const checkPaymentConfig = async (idProvider, signal) => {
        try {
            let res = await apiService.checkPaymentConfig({ providerId: idProvider }, signal);
            if (!signal || !signal.aborted) {
                return res.data;
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.error('Error of payment configuration.');
                toastr.error(t('Reservation.CheckError'))
            }
        }
        return { success: false };
    }

    const pay = async () => {
        try {
            setSaving(true);

            let { idProvider, value: idCar } = car || {};

            let checkRes = await checkPaymentConfig(idProvider, signal);
            if (_.get(checkRes, 'success')) {

                let input = {
                    idProvider,
                    idCar,
                    from: from.toISOString(),
                    to: to.toISOString(),
                    paymentType: _.get(checkRes, 'defferedPaymentSupported') && deposit > 0 ? PT_BLOCKPAY : PT_PAYFIRST
                };
                let resp = await apiService.makeReservation(input, signal);
                if (!signal || !signal.aborted) {
                    let reservationId = _.get(resp, 'data.id');
                    if (reservationId) {
                        saveRepeatReservation();
                        invokePayment(resp.data, pathConfig, t);
                    } else {
                        console.error('No reservation id.');
                        toastr.error(t('Reservation.SaveError'))
                    }
                }
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.error(error);
                toastr.error(_.get(error, 'response.data.message') || t('Reservation.SaveError'));
            }
        } finally {
            if (!signal || !signal.aborted) {
                setSaving(false);
            }
        }
    }

    const getAvaibleCar = (car, avaibleCars) => {
        return car
            ? _.find(avaibleCars, { id: car.id })
            : null;
    }

    const shouldPayForCar = () => {
        let avCar = getAvaibleCar(car, avaibleCars);
        return _.get(avCar, 'isCharged') === true;
    }

    const onFinishSettingsClick = () => {
        openSettings();
    }

    const onParkingChange = value => {
        setParking(value);
        setFromToByParkingProvider(value, providers);
    }

    return (
        <Modal
            className='reservation-modal'
            isOpen={true}
            autoFocus={false}
            toggle={onCancel}
            backdrop='static'
        >
            <ModalHeader toggle={onCancel}>
                <span>{title}</span>
            </ModalHeader>
            <ModalBody>
                <Form>
                    {showParking &&
                        <FormGroup>
                            <Label>{t('Reservation.Parking')}</Label>
                            <Selectbox
                                className="cs-form-select"
                                options={parkingOptions}
                                value={parking}
                                readonly={parkingReadOnly}
                                onChange={onParkingChange}
                            />
                        </FormGroup>
                    }
                    <FormGroup>
                        <Label>{t('Reservation.Model')}</Label>
                        <Selectbox
                            className="cs-form-select"
                            options={modelOptions}
                            value={model}
                            readonly={modelReadOnly}
                            onChange={setModel}
                            placeholder={t('Const.AllModel')}
                            isClearable={canChoseCar}
                        />
                    </FormGroup>
                    <div className="form-group-row">
                        <FormGroup>
                            <Label>{t('Reservation.From')}</Label>
                            <DateTimeInput
                                value={from}
                                onChange={onFromChange}
                                readOnly={fromReadonly}
                                dateFormat={dateFormat}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('Reservation.To')}</Label>
                            <DateTimeInput
                                value={to}
                                onChange={onToChange}
                                readOnly={toReadonly}
                                dateFormat={dateFormat}
                            />
                        </FormGroup>
                    </div>
                    {periodInfo &&
                        <p className='consent-container'>
                            <span className={classNames({ 'text-red': periodInfoRed })}>{periodInfo}</span>
                        </p>
                    }
                    {(canChoseCar || showCar) &&
                        <FormGroup>
                            <Label>{t('Reservation.Car')}</Label>
                            <Selectbox
                                className="cs-form-select"
                                options={carOptions}
                                value={car}
                                readonly={carReadOnly}
                                onChange={setCar}
                                placeholder={t('Reservation.CarPlaceholder')}
                            />
                        </FormGroup>
                    }
                    {false &&
                        <FormGroup>
                            <Label>{t('Reservation.Equipment')}</Label>
                            <div className="custom-control-container readonly">
                                <Input
                                    className="note-text"
                                    value={car ? `${t('Reservation.EquipmentFor')} ${car.name}` : ''}
                                    onChange={() => { }}
                                    readOnly={true}
                                />
                            </div>
                        </FormGroup>
                    }
                    {showRepeat &&
                        <FormGroup switch>
                            <Label check>{t('Reservation.RepeteAfter')}</Label>
                            <Input
                                id="repeat1"
                                type="switch"
                                role="switch"
                                checked={repeat}
                                onChange={() => { }}
                                onClick={() => setRepeat(o => !o)}
                            />
                        </FormGroup>
                    }
                    {price &&
                        <FormGroup
                            className='ptt-parrent-container'
                            tabIndex='1'
                        >
                            <Label
                                className={classNames('ptt-label', { 'price-tooltip-anchor': priceTooltipEnable })}
                                data-tooltip-place="right"
                            >
                                <span>{t('Reservation.Price')}</span>
                                {priceTooltipEnable &&
                                    <span className='label-info-ico'><Fa5FadInfoCircle /></span>
                                }
                            </Label>
                            <ReadonlyInput
                                className={classNames({ 'price-tooltip-anchor': priceTooltipEnable })}
                                value={price}
                            />
                            <Tooltip
                                className='price-tooltip'
                                anchorSelect='.price-tooltip-anchor'
                                delayHide='300'
                                clickable
                                noArrow
                                variant='light'
                            >
                                {info && <div className='info-text'>{info}</div>}
                                {priceListLink &&
                                    <div>
                                        <a
                                            className='link-btn'
                                            href={priceListLink}
                                            target='_blank'
                                        >{t('Reservation.Pricelist')}</a>
                                    </div>
                                }
                            </Tooltip>
                        </FormGroup>
                    }
                    {depositInfo &&
                        <p className='consent-container'>
                            <span>{depositInfo}</span>
                        </p>
                    }
                    {showVopGdpr &&
                        <p className='consent-container'>
                            {vop &&
                                <>
                                    <span>{t('Reservation.VopPrefix')} </span>
                                    <a
                                        className='link-btn'
                                        href={vop}
                                        target='_blank'
                                    >{t('Reservation.VopLink')}</a>
                                    <span>{'.'} </span>
                                </>
                            }
                            {gdpr &&
                                <>
                                    <span>{t('Reservation.GdprPrefix')} </span>
                                    <a
                                        className='link-btn'
                                        href={gdpr}
                                        target='_blank'
                                    >{t('Reservation.GdprLink')}</a>
                                    <span>{'.'} </span>
                                </>
                            }
                        </p>
                    }
                </Form>
                {finishSettingsRequired &&
                    <FinishSettingsRequiredModal
                        textKey={finishSettingsRequired}
                        onClick={onFinishSettingsClick}
                        onClose={onCancel}
                    />
                }
            </ModalBody>
            <Delimiter />
            <ModalFooter>
                {isInFrame &&
                    < Button
                        color="carshare"
                        onClick={openApp}
                    >{t('Nav.App')}</Button>
                }
                {!isInFrame && canMakeReservation && !user &&
                    < Button
                        color="carshare"
                        onClick={login}
                    >{t('Nav.Login')}</Button>
                }
                {!isInFrame && canMakeReservation && user && !shouldPayForCar() &&
                    < Button
                        color="carshare"
                        onClick={save}
                        disabled={!isValid || saving}
                    >{t('Btn.Send')}</Button>
                }
                {!isInFrame && canMakeReservation && user && shouldPayForCar() &&
                    < Button
                        color="carshare"
                        onClick={pay}
                        disabled={!isValid || saving}
                    >{t('Reservation.PayAndSend')}</Button>
                }
                {canPay(_.get(data, 'reservation')) &&
                    < Button
                        color="carshare"
                        onClick={() => onPayClick(data.reservation)}
                        disabled={saving}
                    >{t('Btn.Pay')}</Button>
                }
                {showCancelReservation &&
                    <Button
                        color="outline-secondary"
                        onClick={onCancelReservation}
                    >{t('Reservation.Cancel.Btn')}</Button>
                }
                {showFinishReservation &&
                    <Button
                        color="outline-secondary"
                        onClick={finishReservation}
                    >{t('Reservation.Finish.Btn')}</Button>
                }
                {showCancel &&
                    <Button
                        color="carshare"
                        onClick={onCancel}
                    >{t('Btn.Close')}</Button>
                }
            </ModalFooter>
        </Modal>
    );
}

export default ReservationModal;