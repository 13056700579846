import React, { useContext } from 'react';
import classNames from 'classnames';
import { Navbar } from 'reactstrap';

import { LoginMenu } from './api-authorization/LoginMenu';
import { AppContext } from './AppContext';

import './NavMenu.scss';
import * as path from 'path';
import { Fa5FadInfoCircle } from './fontawesome5/fa5-custom';
import { useHistory } from 'react-router';

const NavMenu = ({ onLogout, onSettings }) => {

    const history = useHistory();
    const { pathConfig, pageTitle, showContacts, isSharingLayout } = useContext(AppContext);

    const onLogoClick = () => {
        if (isSharingLayout) {
            history.push(_.get(pathConfig, 'url', '/'));
        }
    }

    const getLogoSrc = logoPath => {
        let ext = _.get(pathConfig, 'logoType', 'svg');
        let res = path.join('/', _.get(pathConfig, 'key'), `${logoPath}.${ext}`);
        //console.log('dev', 'getLogoSrc', { ext, res, pathConfig });
        return res;
    }

    return (
        <header>
            <Navbar
                className="border-bottom"
                expand="sm"
                container={false}
                light={true}
            >
                <div
                    className={classNames('app-logo', { clicable: isSharingLayout })}
                    onClick={onLogoClick}
                >
                    <img className="brand-img" src={getLogoSrc('img/logo')} />
                    <img className="brand-img-narrow" src={getLogoSrc('img/logo_narrow')} />
                </div>
                <div className="navbar-title">{pageTitle}</div>
                {isSharingLayout &&
                    <div className='nav-contact-btn' onClick={showContacts}>
                        <Fa5FadInfoCircle />
                    </div>
                }
                <ul className="navbar-nav flex-grow d-sm-inline-flex flex-sm-row-reverse">
                    <LoginMenu
                        onLogout={onLogout}
                        onSettings={onSettings}
                    />
                </ul>
            </Navbar>
        </header>
    );
}

export default NavMenu;
