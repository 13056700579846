const en = {
    "Carshare": {
        "NoCar": "There is currently no free parking space to rent"
    },
    "FinishSettingsRequiredModal": {
        "ReqIdentity": "Please verify your identity before reserving a parking space.",
        "ReqPhone": "Please add a contact phone number before reserving a parking space.",
        "ReqPhoneAndIdentity": "Before reserving a parking space, please add a contact phone number and verify your identity."
    },
    "Nav": {
        "Title": "BikeParking"
    },
    "Reservation": {
        "Car": "Stand",
        "CarPlaceholder": "Select stand",
        "LastCarForPeriod": "In the selected period, there is a free last parking space",
        "NoCarForModelPeriod": "The given charger model is not available in the selected period",
        "NoCarForPeriod": "There is no free parking in the selected period",
        "PickUpTooltip": "Parking will only be unlocked at the time of reservation",
        "RepeteAfter": "After ordering, prepare the reservation of another parking space",
        "Title": "Parking reservation"
    },
    "ReservationState": {
        "ReadyToTake": "Parking rented",
        "Taken": "Parking rented"
    },
    "ReservList": {
        "Return": "Unlock parking",
        "Takeover": "Unlock parking"
    }
}

export default en;