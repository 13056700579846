import * as toastr from 'toastr';
import React, { useContext, useEffect, useState } from 'react';
import CarFilter from './CarFilter';
import CarshareMap, { getVisibleCarsAndParkings } from './CarshareMap';
import apiService from '../../util/api';
import * as _ from 'lodash';
import { AppContext } from '../AppContext';
import ReservationModal from './ReservationModal';
import { useHistory, useLocation } from 'react-router';
import SidePanelSelector, { PAN_FILTER, PAN_RESERV } from './SidePanelSelector';
import ReservList from './ReservList';
import { arraySort } from '../../util/sort';
import qs from 'qs';
import { loadContext, LSK_REP_RESERV_CTX, LSK_RESERV_CTX } from '../../util/context';
import { TT_RETURN, TT_TAKEOVER } from '../../constants/takeoverType';
import { InfoModal } from '../InfoModal';
import Swal from 'sweetalert2';
import authService from '../api-authorization/AuthorizeService';
import { RS_APPROVED, RS_CANCELED, RS_CREATED, RS_FINISHED, RS_REJECTED } from '../../constants/reservationState';


export const carToParking = o => {
    if (!o) {
        return null;
    }

    let res = { id: o.idParkingPlace, name: o.parkingPlaceName || o.idParkingPlace };
    return res;
}

export const carToModel = o => {
    if (!o) {
        return null;
    }

    let res = { id: o.idModel, name: o.modelName || o.idModel, imgId: o.modelImageId };
    return res;
}

const getContextFromUrl = (search, pathname, history) => {
    let params = qs.parse(search && search.substr(1));
    if (params && params.ctx) {
        let ctx = loadContext(params.ctx);
        history.replace({ pathname });
        return ctx;
    }
}

const getPaymentResult = (search, pathname, history) => {
    //console.log('dev', 'getPaymentResult', { pathname, search });
    let params = qs.parse(search && search.substr(1));
    //console.log('dev', 'params', { params });
    if (params && params.paymentResult) {
        history.replace({ pathname });
        return params.paymentResult;
    }
}

const rzEqual = (rz1, rz2) => {
    if (!rz1 || !rz2) {
        return false;
    }

    return rz1.replace(' ', '').toUpperCase() === rz2.replace(' ', '').toUpperCase();
}

const Carshare = () => {

    const { pathConfig, user, setPageTitle, t } = useContext(AppContext);
    const { pathname, search, state } = useLocation();
    const history = useHistory();

    const [filter, setFilter] = useState([]);
    const [cars, setCars] = useState([]);
    const [, setLoadingCars] = useState(false);
    const [carsLoaded, setCarsLoaded] = useState(false);
    const [parkings, setParkings] = useState([]);
    const [, setLoadingParkings] = useState(false);
    const [parkingsLoaded, setParkingsLoaded] = useState(false);
    const [reservModal, setReservModal] = useState({ key: null, data: {} });
    const [modelFilter, setModelFilter] = useState(null);
    const [filteredCars, setFilteredCars] = useState([]);
    const [selectedPanel, setSelectedPanel] = useState(PAN_FILTER);
    const [reservations, setReservations] = useState([]);
    const [, setReservationsLoading] = useState(false);
    const [curUser, setCurUser] = useState('');
    const [userEmail, setUserEmail] = useState(undefined);
    const [allReser, setAllReser] = useState(false);
    const [providers, setProviders] = useState([]);
    const [, setLoadingProviders] = useState(false);
    const [info, setInfo] = useState({ key: null, title: '', text: '' });
    const [openCarReservation, setOpenCarReservation] = useState(null);

    useEffect(() => {
        if (openCarReservation && carsLoaded && parkingsLoaded) {
            const carRef = _.find(cars, o => rzEqual(o.registrationNumber, openCarReservation));
            if (carRef) {
                const [visibleCars, visibleParkings] = getVisibleCarsAndParkings(cars, parkings);
                if (carRef.idParkingPlace) {
                    let parking = _.find(visibleParkings, { id: carRef.idParkingPlace });
                    if (parking) {
                        setReservModal({
                            key: new Date().getTime(),
                            data: {
                                parking,
                                selectedCar: {
                                    ...carRef,
                                    value: carRef.id,
                                    label: carRef.carName
                                }
                            }
                        });
                    }
                } else {
                    let car = _.find(visibleCars, { id: carRef.id });
                    if (car) {
                        setReservModal({ key: new Date().getTime(), data: { car } });
                    }
                }
            }
        }
    }, [openCarReservation, cars, carsLoaded, parkings, parkingsLoaded]);

    useEffect(() => {
        setPageTitle(t('Nav.Title'));

        let ac = new AbortController();
        loadCars(ac.signal);
        loadParkings(ac.signal);
        loadProviders(ac.signal);

        if (!checkRepeatReservation()) {
            let ctx = getContextFromUrl(search, pathname, history) || loadContext(LSK_RESERV_CTX);
            if (ctx) {
                switch (ctx.action) {
                    case LSK_RESERV_CTX:
                        setReservModal({ key: new Date().getTime(), data: ctx.data });
                        break;
                }
            }
        }

        let params = qs.parse(search && search.substr(1));
        if (params) {
            let pRes = getPaymentResult(search, pathname, history);
            if (pRes) {
                if (pRes === 'success') {
                    toastr.success(t('PaymentResult.Success'));
                } else {
                    toastr.error(t('PaymentResult.Fail'));
                }
            }

            if (params.car) {
                history.replace({ pathname });
                setOpenCarReservation(params.car);
            }
        }

        if (_.get(state, 'info')) {
            switch (state.info) {
                case 'handoverDone':
                    switch (state.type) {
                        case TT_TAKEOVER:
                            setInfo({
                                key: new Date().getTime(),
                                title: t('Handover.SuccessTakeoverTitle'),
                                text: t('Handover.SuccessTakeoverText')
                            });
                            break;
                        case TT_RETURN:
                            setInfo({
                                key: new Date().getTime(),
                                title: t('Handover.SuccessReturnTitle'),
                                text: t('Handover.SuccessReturnText')
                            });
                            break;
                    }
                    break;
            }
        }

        return () => {
            ac.abort();
        };
    }, []);

    useEffect(() => {
        let filter = _.map(_.mapKeys((cars || []).filter(o => !!o.idModel).map(o => ({ id: o.idModel, name: o.modelName, imgId: o.modelImageId })), 'id'), o => o);
        setFilter(filter);
    }, [cars]);

    useEffect(() => {
        let filteredCars = cars.filter(o => !modelFilter || modelFilter.includes(o.idModel));
        setFilteredCars(filteredCars);
    }, [cars, modelFilter]);

    useEffect(() => {
        console.log('carshare:', user ? 'logged' : 'logouted');

        if (user !== undefined) {
            setUserEmail(_.get(user, 'email', ''));
        }
    }, [user]);

    useEffect(() => {
        //console.log('dev', 'change', { userEmail, allReser });

        let ac = new AbortController();
        if (userEmail !== undefined) {
            loadReservations(ac.signal);
        }

        let interval = null;
        if (user) {
            interval = setInterval(() => {
                loadReservations(ac.signal);
            }, 60 * 1000)
        }

        return () => {
            ac.abort();
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [userEmail, allReser]);

    const loadReservations = async signal => {
        try {
            setReservationsLoading(true);
            let input = {};
            if (allReser) {
                input.reservationState = [
                    RS_CREATED, RS_APPROVED, RS_CANCELED, RS_REJECTED, RS_FINISHED
                ];
            }
            let resp = await apiService.getReservations(input, signal);
            if (!signal || !signal.aborted) {
                let reservations = resp.data || [];
                //reservations = reservations.map(o => ({ ...o, controlType: 4 })); // simulace dat
                arraySort(reservations, { prop: 'timeFrom', order: 'desc' })
                setReservations(reservations);
                setSelectedPanel(reservations.length ? PAN_RESERV : PAN_FILTER);
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
            }
        } finally {
            if (!signal || !signal.aborted) {
                setReservationsLoading(false);
            }
        }
    }

    const loadCars = async signal => {
        try {
            setLoadingCars(true);
            let resp = await apiService.getCars({ idProvider: _.get(pathConfig, 'providers', null) }, signal);
            if (!signal || !signal.aborted) {
                let cars = resp.data || [];
                setCars(cars);
                if (cars.length === 0 && (!_.get(pathConfig, 'forceLogin') || (await authService.isAuthenticated()))) {
                    await Swal.fire({
                        text: t('Carshare.NoCar'),
                        confirmButtonText: t('Btn.Close')
                    });
                }
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
            }
        } finally {
            if (!signal || !signal.aborted) {
                setLoadingCars(false);
                setCarsLoaded(true);
            }
        }
    }

    const loadParkings = async signal => {
        try {
            setLoadingParkings(true);
            let resp = await apiService.getParkings(null, signal);
            if (!signal || !signal.aborted) {
                let parkings = resp.data || [];
                setParkings(parkings);
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
            }
        } finally {
            if (!signal || !signal.aborted) {
                setLoadingParkings(false);
                setParkingsLoaded(true);
            }
        }
    }

    const loadProviders = async signal => {
        try {
            setLoadingProviders(true);
            let resp = await apiService.getProviders({ idProvider: _.get(pathConfig, 'providers', null) }, signal);
            if (!signal || !signal.aborted) {
                let providers = resp.data || [];
                setProviders(providers);
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
            }
        } finally {
            if (!signal || !signal.aborted) {
                setLoadingProviders(false);
            }
        }
    }

    const checkRepeatReservation = () => {
        let ctx = loadContext(LSK_REP_RESERV_CTX);
        if (ctx && ctx.action === LSK_REP_RESERV_CTX && ctx.user === user.email && ctx.config === _.get(pathConfig, 'key')) {
            setReservModal({ key: new Date().getTime(), data: ctx.data });
            return true;
        }

        return false;
    }

    const parkingClick = parking => {
        let data = { parking };
        setReservModal({ key: new Date().getTime(), data });
    }

    const carClick = car => {
        let data = { car };
        setReservModal({ key: new Date().getTime(), data });
    }

    const showCarDetail = data => {
        setReservModal({ key: new Date().getTime(), data, showCancel: true });
    }

    const closeReservModal = () => {
        setReservModal({ key: null, data: null });
    }

    const reservDone = () => {
        closeReservModal();
        loadReservations();
        checkRepeatReservation();
    }

    const changeFilter = (filter) => {
        setModelFilter(filter);
    }

    const onInfoClose = () => {
        setInfo({ key: null, title: '', text: '' });
        history.replace(pathname);
    }

    return (
        <div className="carshare-content-container">
            <CarshareMap
                parkings={parkings}
                cars={filteredCars}
                carFilter={filter}
                carFilterValue={modelFilter}
                reservations={reservations}
                onCarDetailClick={showCarDetail}
                onParkingClick={parkingClick}
                onCarClick={carClick}
                onFilterChange={changeFilter}
                allReservations={allReser}
                onAllReservationsClick={() => setAllReser(o => !o)}
                onReservationChanged={loadReservations}
            />
            {false &&
                <div className='desktop-filter hide-on-mobile flex'>
                    <SidePanelSelector
                        selected={selectedPanel}
                        onChange={setSelectedPanel}
                    />
                    {selectedPanel === PAN_FILTER &&
                        <CarFilter
                            cars={filter}
                            value={modelFilter}
                            onChange={changeFilter}
                        />
                    }
                    {selectedPanel === PAN_RESERV &&
                        <ReservList
                            items={reservations}
                            all={allReser}
                            onCarDetailClick={showCarDetail}
                            onAllClick={() => setAllReser(o => !o)}
                            onItemChanged={loadReservations}
                        />
                    }
                </div>
            }
            {reservModal && reservModal.key && parkingsLoaded && carsLoaded &&
                <ReservationModal
                    key={reservModal.key}
                    data={reservModal.data}
                    reservations={reservations}
                    cars={cars}
                    parkings={parkings}
                    showCancel={reservModal.showCancel}
                    onCancel={closeReservModal}
                    onSubmit={reservDone}
                    providers={providers}
                />
            }
            {info && info.key &&
                <InfoModal
                    key={info.key}
                    title={info.title}
                    text={info.text}
                    onClose={onInfoClose}
                />
            }
        </div>
    );
}

export default Carshare;