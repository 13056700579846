import * as _ from 'lodash';
import * as path from 'path';
import * as toastr from 'toastr';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import qs from 'qs';
import { GoogleMap, LoadScript, Marker, MarkerClusterer, InfoWindow, InfoWindowF } from '@react-google-maps/api';
import { googleMapStyle } from '../../style/google-map';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { useApiConfig } from '../../util/config';
import CarFilter from './CarFilter';
import GoogleMapCustomSimpleControl, { GoogleMapCustomContentControl } from './GoogleMapCustomSimpleControl';
import GoogleMapCustomZoomControl from './GoogleMapCustomZoomControl';
import { BOUND_CRSR } from '../../constants/Operators';
import classNames from 'classnames';
import { MT_DEFAULT } from '../../constants/Operators';
import ReservList from './ReservList';
import Error from '../Error';
import { PAN_FILTER, PAN_RESERV } from './SidePanelSelector';
import { invoke } from '../../util/helper';
import Loader from '../Loader';
import FindClose from './FindClose';

import './CarshareMap.js.scss';
import { Fa5FadCalendarAlt } from '../fontawesome5/fa5-custom';
import { useLocation } from 'react-router';
import apiService from '../../util/api';


export const invokePayment = async (reservation, pathConfig, t) => {
    try {
        let { id } = reservation || {};
        if (id) {
            let callback = window.location.origin + path.join(_.get(pathConfig, 'url', '/'), 'payment/callback');
            let resp = await apiService.invokePayment({ reservationId: id, callback });
            if (_.get(resp, 'data.code') === 0) {
                window.location.assign(resp.data.idText);
            } else {
                console.error(resp.data);
                toastr.error(t('Reservation.Pay.Error'));
            }
        } else {
            console.error('No reservation id.');
        }
    } catch (error) {
        console.error(error);
        toastr.error(t('Reservation.Pay.Error'), error);
    }
}

const defaultZoomLevel = 14;
const libraries = ["geometry"];

const defaultLocation = {
    center: {
        lat: 50.0837973,
        lng: 14.4471686
    },
    zoom: 12
};


function setActive(el, value) {
    //console.log('dev', 'setActive', { el, value });
    if (el) {
        if (value) {
            el.classList.add('active');
        } else {
            el.classList.remove('active');
        }
    }
}

export function getVisibleCarsAndParkings(cars, parkings) {
    let visibleCars = [];
    let parkingMap = _.mapKeys((parkings || []).map(o => ({ ...o, lat: o.latitude, lng: o.longitude, count: 0 })), 'id');

    (cars || []).forEach(car => {
        if (car) {
            if (car.idParkingPlace) {
                if (parkingMap[car.idParkingPlace]) {
                    parkingMap[car.idParkingPlace].count++;
                }
            } else if (car.latitude && car.longitude) {
                visibleCars.push({ ...car, lat: car.latitude, lng: car.longitude });
            }
        }
    });

    let visibleParkings = _.map(parkingMap).filter(o => o.lat && o.lng);
    return [visibleCars, visibleParkings];
}

const ParkingMarker = ({ name, onLoad, onClick, ...otherProps }) => {

    const [markerInstance, setMarkerInstance] = useState(null);

    return (
        <Marker
            onLoad={o => {
                setMarkerInstance(o);
                if (onload) {
                    onLoad(o);
                }
            }}
            onClick={e => {
                if (onClick) {
                    onClick(e);
                }
            }}
            {...otherProps}
        >
            {markerInstance &&
                <InfoWindow
                    anchor={markerInstance}
                    options={{ disableAutoPan: true }}
                >
                    <div className='parking-marker-label-container'>
                        <span className='parking-marker-label'>{name}</span>
                        <div
                            className='click-overlay'
                            onClick={e => {
                                if (onClick) {
                                    onClick(e);
                                }
                            }}
                        ></div>
                    </div>
                </InfoWindow>
            }
        </Marker>
    );
}

function CarshareMap({ parkings, cars, carFilter, carFilterValue, reservations, allReservations,
    onParkingClick, onCarClick, onFilterChange, onCarDetailClick, onAllReservationsClick, onReservationChanged }) {

    const { search } = useLocation();
    let queryParams = qs.parse(search && search.substr(1));

    const { pathConfig, t, config, isInFrame } = useContext(AppContext);

    const [map, setMap] = useState(null);
    const [loadMapError, setLoadMapError] = useState(null);
    const [visibleCars, setVisibleCars] = useState([]);
    const [visibleParkings, setVisibleParkings] = useState([]);
    const [center, setCenter] = useState(defaultLocation.center);
    const [zoom, setZoom] = useState(defaultLocation.zoom);
    const [selectedTool, setSelectedTool] = useState(window.innerWidth >= 1000 ? PAN_FILTER : '');
    const [, setToolMap] = useState({});
    const [, setLastZoomedParking] = useState(null);
    const [, setAutoOpenReservations] = useState(false);

    useEffect(() => {
        setAutoOpenReservations(curAutoOpen => {
            let newAutoOpen = curAutoOpen;
            setSelectedTool(curSelectedTool => {
                if (reservations.length) { // načetly se nějaké rezervace
                    newAutoOpen = true; // nastavení příznaku automatického otevření
                    return curAutoOpen
                        ? curSelectedTool // pokud už bylo automaticky otevřeno, nic nedělej
                        : PAN_RESERV; // automaticky otevři
                } else { // žádné rezervace -> došlo k odhlášení
                    newAutoOpen = false; // reset příznaku
                    return curAutoOpen && curSelectedTool === PAN_RESERV
                        ? window.innerWidth >= 1000
                            ? PAN_FILTER // u webu zobraz filtr
                            : '' // u mobilu schovej
                        : curSelectedTool
                }
            });
            return newAutoOpen;
        });
    }, [reservations]);

    useEffect(() => {
        if (map) {
            let bound = pathConfig ? pathConfig.bound : BOUND_CRSR;
            if (queryParams.devbounds) {
                try {
                    bound = JSON.parse(queryParams.devbounds);
                } catch { }
            }
            map.fitBounds(bound);

            if (queryParams.devshowbounds === '1') {
                const rectangle = new google.maps.Rectangle({
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FF0000",
                    fillOpacity: 0.1,
                    map,
                    bounds: bound,
                });
            }
        }
    }, [map]);

    // centrování na první rezervaci
    useEffect(() => {
        setLastZoomedParking(lzpVal => {
            if (reservations && parkings) {
                let res = _.find(reservations, r => !!r.idParkingPlace);
                if (res) {
                    if (res.idParkingPlace !== lzpVal) {
                        //console.log('firstReservationCenter', res.id, res.idParkingPlace, res.parkingPlaceName);
                        zoomToParking(res.idParkingPlace);
                        return res.idParkingPlace;
                    } else {
                        //console.log('firstReservationCenter', 'parking already centered', res.id, res.idParkingPlace, res.parkingPlaceName);
                        return lzpVal;
                    }
                } else {
                    //console.log('firstReservationCenter', 'no reservation with parking');
                    return null;
                }
            } else {
                //console.log('firstReservationCenter', 'no reservation or no parking');
                return null;
            }
        });
    }, [reservations, parkings]);

    useEffect(() => {
        const [visibleCars, visibleParkings] = getVisibleCarsAndParkings(cars, parkings);
        setVisibleCars(visibleCars);
        setVisibleParkings(visibleParkings);
    }, [cars, parkings]);

    const onLoad = useCallback(map => {
        addLayerButton(map);
        addZoomButtons(map);
        addLocationButton(map);
        addFilter(map);
        if (!isInFrame) {
            addReservations(map);
        }
        setMap(map);
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onCarNameClick = useCallback(id => {
        //console.log('dev', 'onCarNameClick', id);
        zoomToParking(id);
    }, [parkings]);

    const onReservationItemPay = reservation => {
        invokePayment(reservation, pathConfig, t);
    }

    function setSelectedCac(pan) {
        //console.log('dev', 'setSelectedCac', { pan });
        let tm = null;
        setToolMap(o => {
            tm = o;
            return o;
        });
        setSelectedTool(o => {
            let res = o === pan ? '' : pan;
            //console.log('dev', 'change', `${o || null} -> ${res || null}`);
            if (o) setActive(_.get(tm, o), false);
            if (res) setActive(_.get(tm, res), true);
            return res;
        });
    }

    const zoomToParking = id => {
        //console.log('dev', 'zoom parking');

        let park = _.find(parkings, { id });
        if (park) {
            console.log('zoom parking', park.name || park.id);
            setCenter({
                lat: park.latitude,
                lng: park.longitude
            });
            setZoom(defaultZoomLevel);
        } else {
            console.log('zoom parking', 'no parking with id', { id, parkings })
        }
    }

    const addFilter = map => {
        const divElement = document.createElement('div');
        divElement.className = 'custom-activity-control';
        divElement.index = 1;
        setToolMap(o => {
            let res = { ...o };
            res[PAN_FILTER] = divElement;
            return res;
        });
        ReactDOM.createRoot(divElement).render(
            <GoogleMapCustomSimpleControl
                className="no-shadow"
                title={t('Map.Filter')}
                icon='fa-solid fa-filter'
                onClick={() => setSelectedCac(PAN_FILTER)}
            />
        );
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(divElement);
    }

    const addReservations = map => {
        const divElement = document.createElement('div');
        divElement.className = 'custom-activity-control';
        divElement.index = 1;
        setToolMap(o => {
            let res = { ...o };
            res[PAN_RESERV] = divElement;
            return res;
        });
        ReactDOM.createRoot(divElement).render(
            <GoogleMapCustomSimpleControl
                className="reserv-map-btn no-shadow"
                title={t('Map.Reservations')}
                icon='fa-solid fa-calendar-days'
                onClick={() => setSelectedCac(PAN_RESERV)}
            />
        );
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(divElement);
    }

    const addZoomButtons = map => {
        const divElement = document.createElement('div');
        divElement.index = 1;
        ReactDOM.createRoot(divElement).render(
            <GoogleMapCustomZoomControl
                zoomInTitle={t('Map.ZoomInTitle')}
                zoomOutTitle={t('Map.ZoomOutTitle')}
                onZoomIn={() => map.setZoom(map.getZoom() + 1)}
                onZoomOut={() => map.setZoom(map.getZoom() - 1)}
            />
        );
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(divElement);
    }

    const addLayerButton = map => {
        const divElement = document.createElement('div');
        divElement.classList.add('custom-activity-control');
        divElement.index = 1;
        ReactDOM.createRoot(divElement).render(
            <GoogleMapCustomSimpleControl
                title={t('Map.Satellite')}
                icon='fa-solid fa-layer-group'
                onClick={() => {
                    if (map.getMapTypeId() === google.maps.MapTypeId.HYBRID) {
                        map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
                        divElement.classList.remove('active');
                    } else {
                        map.setMapTypeId(google.maps.MapTypeId.HYBRID);
                        divElement.classList.add('active');
                    }
                }}
            />
        );
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(divElement);
    }

    const addLocationButton = map => {
        const rootElement = document.createElement('div');
        rootElement.classList.add('custom-activity-control');
        rootElement.index = 1;
        ReactDOM.createRoot(rootElement).render(
            <GoogleMapCustomSimpleControl
                title={t('Map.Location')}
                icon='fa-solid fa-location-crosshairs'
                onClick={() => {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(position => {
                            setCenter({
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            });
                            setZoom(defaultZoomLevel);
                            //console.log('dev', 'curpos', 'active', position.coords.latitude, position.coords.longitude);
                            rootElement.classList.add('active');
                        }, error => {
                            if (error) {
                                if (error.code === 1) {
                                    alert(t('Map.EnableLocationMsg'));
                                } else {
                                    console.log(error);
                                }
                            } else {
                                console.log('Unknown error get location.');
                            }
                        });
                    } else {
                        clearInterval(animationInterval);
                        rootElement.classList.remove('active');
                    }
                }}
            />
        );

        google.maps.event.addListener(map, 'center_changed', function () {
            setCenter(c => {
                //console.log('dev', 'curpos', 'inactive', map.getCenter().lat(), map.getCenter().lng(), c, _.get(c, 'lat'), _.get(c, 'lng'));
                if (!c || (map.getCenter().lat() !== c.lat) || (map.getCenter().lng() !== c.lng)) {
                    rootElement.classList.remove('active');
                }
                return c;
            });
        });
        google.maps.event.addListener(map, 'zoom_changed', function () {
            let value = map.getZoom();
            setZoom(c => {
                //console.log('dev', 'zoom changed', c, value);
                if (c !== value) {
                    rootElement.classList.remove('active');
                    return value;
                }
                return c;
            });
        });

        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(rootElement);
    }

    const customCalculator = (markers, numStyles) => {
        let count = 0;
        let title = '';

        (markers || []).forEach(m => {
            if (m.type === 'car') {
                count++;
            } else if (m.type === 'parking') {
                count += m.carCount;
            }
        });

        let index = Math.min(count >= 100 ? 2 : 1, numStyles);

        //console.log('dev', 'customCalculator', { markers, count, index, numStyles });

        return { text: `${count}`, index, title };
    }

    const getClusterStyle = () => {
        return [
            {
                url: getImgPath() + '/cluster.svg',
                height: 46,
                lineHeight: 46,
                width: 46,
                textColor: '#ffffff',
                textSize: 16,
            },
            {
                url: getImgPath() + '/cluster.svg',
                height: 56,
                lineHeight: 56,
                width: 56,
                textColor: '#ffffff',
                textSize: 16,
            }
        ];
    }

    const parkingClick = (e, parking) => {
        //console.log('parkingClick', { e, parking });
        if (onParkingClick) {
            onParkingClick(parking);
        }
    }

    const carClick = (e, car) => {
        //console.log('carClick', { e, car });
        if (onCarClick) {
            onCarClick(car);
        }
    }

    const getImgPath = () => {
        let res = path.join('/', _.get(pathConfig, 'key'), 'img');
        return res;
    }

    const getGmapStyle = () => {
        // XSHARE-125
        return undefined;

        //let res = googleMapStyle;
        //if (pathConfig && pathConfig.map === MT_DEFAULT) {
        //    res = undefined;
        //}
        //return res;
    }

    const findClosest = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                let curPos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                let minDist = null;
                let minPos = null;
                (visibleParkings || []).map(p => {
                    let parkingPos = new google.maps.LatLng(p.lat, p.lng);
                    let dist = google.maps.geometry.spherical.computeDistanceBetween(curPos, parkingPos)
                    if (!minDist || dist < minDist) {
                        minDist = dist;
                        minPos = parkingPos;
                    }
                });
                (visibleCars || []).filter(c => !!c.lat && !!c.lng).map(c => {
                    let carPos = new google.maps.LatLng(c.lat, c.lng);
                    let dist = google.maps.geometry.spherical.computeDistanceBetween(curPos, carPos)
                    if (!minDist || dist < minDist) {
                        minDist = dist;
                        minPos = carPos;
                    }
                });
                if (minPos) {
                    //console.log('dev', 'min pos', { lat: minPos.lat(), lng: minPos.lng() });
                    setCenter({ lat: minPos.lat(), lng: minPos.lng() });
                    setZoom(defaultZoomLevel);
                }
            }, error => {
                if (error) {
                    if (error.code === 1) {
                        alert(t('Map.EnableLocationMsg'));
                    } else {
                        console.log(error);
                    }
                } else {
                    console.log('Unknown error get location.');
                }
            });
        } else {
            console.log('Get location is not supported.');
        }
    }

    //console.log('dev', 'CarshareMap.render', { carFilter });
    return (
        <div className="carshare-map-container">
            {config && config.googleMapApiKey && !loadMapError &&
                <LoadScript
                    googleMapsApiKey={config.googleMapApiKey}
                    onError={error => {
                        console.log('csmap', error);
                        setLoadMapError(error);
                    }}
                    loadingElement={
                        <Loader text={t('Map.Loading')} />
                    }
                    libraries={libraries}
                >
                    {/* <FontAwesomeIcon icon="fa-solid fa-minus" style={{ fontSize: '16px' }} /> */}
                    <GoogleMap
                        mapContainerClassName='csmap'
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={center}
                        zoom={zoom}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{
                            styles: getGmapStyle(),
                            streetViewControl: false,
                            scaleControl: true,
                            fullscreenControl: false,
                            controlSize: 28,
                            mapTypeControl: false,
                            zoomControl: false
                        }}
                    >
                        <MarkerClusterer
                            calculator={customCalculator}
                            styles={getClusterStyle()}
                            gridSize={30}
                        >
                            {clusterer => {
                                let parkingMarkers = (visibleParkings || []).map(p => {
                                    //console.log('dev', 'parking', p.name, p.lat, p.lng);
                                    return (
                                        <ParkingMarker
                                            key={`${p.id}_${p.count}_${p.lat}_${p.lng}`}
                                            position={{ lat: p.lat, lng: p.lng }}
                                            icon={{
                                                url: getImgPath() + '/parking.svg',
                                                scaledSize: new google.maps.Size(30, 40), // scaled size
                                                labelOrigin: new google.maps.Point(15, 15), // origin
                                                anchor: new google.maps.Point(15, 40) // anchor
                                            }}
                                            label={{
                                                text: `${p.count}`,
                                                fontFamily: 'Arial',
                                                color: '#fff',
                                                fontSize: '15px',
                                                fontWeight: 'bold'
                                            }}
                                            title={p.name}
                                            clusterer={clusterer}
                                            options={{
                                                type: 'parking',
                                                carCount: p.count
                                            }}
                                            onClick={e => parkingClick(e, p)}
                                            name={p.name}
                                        >
                                        </ParkingMarker>
                                    );
                                });
                                let carMarkers = (visibleCars || []).filter(c => !!c.lat && !!c.lng).map(c => {
                                    //console.log('dev', 'car', c.name, c.lat, c.lng);
                                    return (
                                        <Marker
                                            key={c.id}
                                            position={{ lat: c.lat, lng: c.lng }}
                                            icon={{
                                                url: getImgPath() + '/car.svg',
                                                scaledSize: new google.maps.Size(30, 40), // scaled size
                                                origin: new google.maps.Point(0, 0), // origin
                                                anchor: new google.maps.Point(15, 40) // anchor
                                            }}
                                            title={c.name}
                                            clusterer={clusterer}
                                            options={{
                                                type: 'car'
                                            }}
                                            onClick={e => carClick(e, c)}
                                        ></Marker>
                                    );
                                });
                                //console.log('dev', 'getting markers', { parkingMarkers, carMarkers });
                                return [...parkingMarkers, ...carMarkers];
                            }}
                        </MarkerClusterer>
                    </GoogleMap>
                </LoadScript>
            }
            {(!config || !config.googleMapApiKey) &&
                <div className="carshare-map-container">
                    <Error text={t('Map.ApiKeyError')} />
                </div>
            }
            {loadMapError &&
                <div className="carshare-map-container">
                    <Error text={t('Map.LoadError')} />
                </div>
            }
            <div className="panel-buttons-overlay-container">
                <div className={classNames('custom-activity-control', { active: selectedTool === PAN_FILTER })}>
                    <GoogleMapCustomSimpleControl
                        title={t('Map.Filter')}
                        icon='fa-solid fa-filter'
                        onClick={() => setSelectedTool(o => o === PAN_FILTER ? '' : PAN_FILTER)}
                    />
                </div>
                {!isInFrame &&
                    <div className={classNames('custom-activity-control', { active: selectedTool === PAN_RESERV })}>
                        <GoogleMapCustomContentControl
                            className="reserv-map-btn"
                            title={t('Map.Reservations')}
                            onClick={() => setSelectedTool(o => o === PAN_RESERV ? '' : PAN_RESERV)}
                        >
                            <Fa5FadCalendarAlt />
                        </GoogleMapCustomContentControl>
                    </div>
                }
            </div>
            {selectedTool === PAN_FILTER &&
                <div id='mobileFilter' className={classNames('map-panel-container mobile-filter', { touch: true })}>
                    <CarFilter
                        className="map-panel"
                        cars={carFilter}
                        value={carFilterValue}
                        onChange={onFilterChange}
                    />
                </div>
            }
            {!isInFrame && selectedTool === PAN_RESERV &&
                <div id='mobileReservations' className={classNames('map-panel-container mobile-reservations', { touch: true })}>
                    <div className="map-panel mobile-panel-container">
                        <ReservList
                            items={reservations}
                            all={allReservations}
                            onCarDetailClick={onCarDetailClick}
                            onCarNameClick={onCarNameClick}
                            onAllClick={() => {
                                setAutoOpenReservations(false);
                                invoke(onAllReservationsClick);
                            }}
                            onItemChanged={onReservationChanged}
                            onPay={onReservationItemPay}
                        />
                    </div>
                </div>
            }
            {!selectedTool &&
                <FindClose onClick={findClosest} />
            }
        </div>
    );
}

export default React.memo(CarshareMap);