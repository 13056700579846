const en = {
    "App": {
        "AuthBoundMsg": "Please contact the administrator and describe how the error occurred.",
        "AuthBoundTit": "Authentication error",
        "ConfigError": "Error loading configuration. Please try again or contact your system administrator.",
        "ConfigLoadign": "Loading configuration...",
        "Init": "Initializing application...",
        "ProcLogin": "Login",
        "ProcLoginClbck": "Login",
        "ProcLoginClbckTxt": "Processing login response...",
        "ProcLoginTxt": "Sending a login request...",
        "ProcLogout": "Log out",
        "ProcLogoutClbck": "Log out",
        "ProcLogoutClbckTxt": "Processing logout response...",
        "ProcLogoutTxt": "Sending a logout request...",
        "ProcPay": "Payment",
        "ProcPayCallback": "Payment processing..."
    },
    "Btn": {
        "Accept": "Confirm",
        "Cancel": "Cancel",
        "Capture": "Take a picture",
        "Clear": "Delete",
        "Close": "Close",
        "Delete": "Delete",
        "Login": "Log in",
        "Logout": "Logout",
        "No": "No",
        "Pay": "Pay",
        "Save": "Save",
        "Send": "Send",
        "TakePhoto": "Take a photo...",
        "Yes": "Yes"
    },
    "Carshare": {
        "NoCar": "There is currently no available vehicle to rent"
    },
    "Const": {
        "AllModel": "All models",
        "NoModel": "No model"
    },
    "Contacts": {
        "InfoSupport": "Information support",
        "Manual": "Manual",
        "Manuals": {
            "Main": "Carsharing",
            "Main_auto-lion": "Carsharing AUTO-LION",
            "Main_carsharingkacmacek": "CARsharing Kačmáček",
            "Main_kb": "KB - Bikesharing",
            "Main_malekarpaty": "Malé Karpaty - BikeSharing",
            "Main_mkparking": "Malé Karpaty - BikeParking",
            "MainFile": "KB_BikeSharing_Manual_cs.pdf",
            "MainFile_auto-lion": "carsharing_auto-lion_manual_cs.pdf",
            "MainFile_carsharingkacmacek": "carsharing_kacmacek_manual_cs.pdf",
            "MainFile_kb": "kb_bikesharing_manual_cs.pdf",
            "MainFile_malekarpaty": "male_karpaty_bikesharing_manual_cs.pdf",
            "MainFile_mkparking": "male_karpaty_bikeparking_manual_cs.pdf",
            "MainName": "Carsharing - Manual.pdf",
            "MainName_auto-lion": "Carsharing AUTO-LION - Návod.pdf",
            "MainName_carsharingkacmacek": "CARsharing Kačmáček - Návod.pdf",
            "MainName_kb": "KB BikeSharing - Návod.pdf",
            "MainName_malekarpaty": "Malé Karpaty BikeSharing - Návod.pdf",
            "MainName_mkparking": "Malé Karpaty BikeParking - Návod.pdf"
        },
        "ServisSupport": "Bicycle service support",
        "Support": "Technical support",
        "Title": "Manual and contacts"
    },
    "Damage": {
        "Data": "Data",
        "DataNotLoadedErr": "Failed to load vehicle data.",
        "Email": "Driver's email",
        "EmailPlaceholder": "Enter the driver's email",
        "FindCar": "Find a vehicle",
        "Name": "Driver Name",
        "NamePlaceholder": "Enter driver's name",
        "NoDataErr": "Non-existent or unauthorized vehicle.",
        "RegNum": "Registration plate",
        "RegNumPlaceholder": "Enter registration plate",
        "Title": "VEHICLE DEFICIENCIES"
    },
    "Damages": {
        "NewBtn": "Report New",
        "NoDamages": "There are no defects reported on the vehicle.",
        "NoNameEmail": "Please fill in name and email first.",
        "NoRegNum": "Please fill in the registration number first.",
        "Title": "Deficiencies and damage"
    },
    "Filter": {
        "All": "all",
        "Title": "Models"
    },
    "FinishSettingsRequiredModal": {
        "Btn": "User settings",
        "ReqIdentity": "Please verify your identity before reserving a vehicle.",
        "ReqPhone": "Please fill in the contact phone number before booking the vehicle.",
        "ReqPhoneAndIdentity": "Before reserving a vehicle, please fill in your contact phone number and verify your identity.",
        "Title": "Finishing the setup"
    },
    "Handover": {
        "Code": "Collection code",
        "DataLoading": "Loading data...",
        "Name": "Vehicle",
        "NoCarId": "No vehicle id.",
        "Nodata": "No data was loaded.",
        "NoType": "No handover type.",
        "ParkingNo": "Stall number",
        "Place": "Parking",
        "ReturnAndLock": "Return and lock",
        "ReturnTitle": "RETURN OF THE VEHICLE",
        "SuccessReturnText": "Thank you and we look forward to your next booking.",
        "SuccessReturnTitle": "The vehicle has been successfully returned",
        "SuccessTakeoverText": "The wheel chain will now unlock for 30 seconds, during which time remove the chain. Unlock the wheel and return the chain to the lock.",
        "SuccessTakeoverTitle": "The vehicle was successfully taken over",
        "TakeAndUnlock": "Download and unlock",
        "TakeoverTitle": "TAKEOVER THE VEHICLE",
        "UnlockBike": "Unlock the bike",
        "UnlockError": "Error sending command to unlock stand.",
        "UnlockParking": "Unlock parking",
        "UnlockSuccess": "The request to unlock the stall has been sent."
    },
    "Check": {
        "BeforeLeave": "Are you sure you want to leave the page and discard the form in progress?",
        "Consents": "Agreements",
        "Data": "Data",
        "DataNotLoadedErr": "Failed to load vehicle data.",
        "DataSaved": "Data has been sent successfully.",
        "DataSaveErr": "An error occurred while sending data.",
        "Email": "Driver's email",
        "EmailPlaceholder": "Enter the driver's email",
        "EmailRequired": "Please fill in the driver's name and email.",
        "FindCar": "Find a vehicle",
        "Name": "Driver Name",
        "NamePlaceholder": "Enter driver's name",
        "NameRequired": "Please fill in the name and email of the driver.",
        "NoDataErr": "Non-existent or unauthorized vehicle.",
        "NotAllConsentsChecked": "All agreements need to be confirmed.",
        "NotAllPhotosLoaded": "Please add photos.",
        "Photos": "Photography",
        "RegNum": "Registration plate",
        "RegNumPlaceholder": "Enter registration plate",
        "ReturnBtn": "Return Vehicle",
        "ReturnDone": "The vehicle has been successfully returned.",
        "Sig": "Signature",
        "SigRequired": "Please add a signature.",
        "TakeDone": "The vehicle has been successfully picked up.",
        "TakeoverBtn": "Pick up Vehicle",
        "Title": "VEHICLE HANDOVER",
        "Type": "Check at",
        "TypePlaceholder": "Select type of check",
        "TypeRequired": "Please select type of check."
    },
    "Identity": {
        "Unverified": "Unverified"
    },
    "Lang": {
        "cs": "Čeština",
        "de": "Deutsch",
        "en": "English",
        "pl": "Polski",
        "sk": "Slovenčina"
    },
    "Login": {
        "BadLogin": "Invalid credentials",
        "ByGoogle": "Sign in with Google",
        "ByXmarton": "Log in via Xmarton",
        "ConsentLink": "https://www.xmarton.com/data/Informace_o_zpracovani_osobnich_udaju_pro_zakazniky.pdf",
        "ConsentLinkText": "the processing of personal data",
        "ConsentTextPostfix": ".",
        "ConsentTextPrefix": "By continuing, you agree to ",
        "ErrTitle": "Login error",
        "Password": "Password",
        "Title": "Login",
        "Username": "Email"
    },
    "Logout": {
        "ErrTitle": "Logout error",
        "NotLocalLogin": "Logout was not initiated in the application."
    },
    "Map": {
        "ApiKeyError": "An application configuration error occurred. Please try again or contact your system administrator.",
        "EnableLocationMsg": "Please enable location access permission.",
        "Filter": "Models",
        "FindClose": "Find the closest one",
        "LoadError": "Failed to load map, please try again.",
        "Loading": "Loading map",
        "Location": "Your location",
        "Reservations": "Reservations",
        "Satellite": "Satellite Map",
        "ZoomInTitle": "Zoom In",
        "ZoomOutTitle": "Zoom Out"
    },
    "Nav": {
        "App": "Open the application",
        "Login": "Log in",
        "Logouted": "You are logged out",
        "Title": "VEHICLES FOR RENTAL"
    },
    "NewDamage": {
        "Category": "Category",
        "CategoryRequired": "Category is required.",
        "DataSaved": "Deficiency has been successfully reported.",
        "DataSaveErr": "An error occurred while sending data.",
        "Description": "Description",
        "DescriptionRequired": "Please add a description.",
        "Event": "Insurance event",
        "Photo": "Photo",
        "PhotoRequired": "Please add a photo.",
        "RegNum": "Registration plate",
        "Title": "Deficiency Report"
    },
    "PaymentResult": {
        "Error": "An error occurred while processing the payment result. Please contact your system administrator.",
        "Fail": "The payment was not made successfully",
        "Success": "The payment was successfully made",
        "Title": "Payment"
    },
    "PhoneConfirmModal": {
        "Code": "Verification code",
        "Text": "To verify the correctness of the phone number, we sent you an SMS with a verification code. Please enter it to complete.",
        "Title": "Phone number verification"
    },
    "PhotoCapture": {
        "Title": "Taking a photo"
    },
    "PhotoInput": {
        "MaxFileSize": "Size limit exceeded.",
        "Photo": "Photo",
        "UnsupportedFileType": "Unsupported file type."
    },
    "Rent": {
        "Title": "Vehicle rental"
    },
    "Reservation": {
        "Cancel": {
            "Btn": "Cancel reservation",
            "Confirm": {
                "Question": "Are you sure you want to cancel the reservation?",
                "Title": "Cancel reservation"
            },
            "Error": "The reservation has not been cancelled.",
            "Success": "The reservation has been successfully cancelled."
        },
        "Car": "Vehicle",
        "CarNotAvaibleForPeriod": "The vehicle is not available until the selected period",
        "CarPlaceholder": "Chose vehicle",
        "DepositInfo": "A refundable deposit {deposit} will also be included in the rental price.",
        "Equipment": "Equipment",
        "EquipmentFor": "Equipment for",
        "Finish": {
            "Btn": "Finish reservation",
            "Confirm": {
                "Question": "Are you sure you want to finish the reservation?",
                "Title": "Finish reservation"
            },
            "Error": "The reservation has not been finished.",
            "Success": "The reservation was successfully finished."
        },
        "From": "From",
        "GdprLink": "personal data processing",
        "GdprPrefix": "Principles of",
        "CheckError": "The payment gateway could not be opened, please contact technical support.",
        "LastCarForPeriod": "There is the last vehicle available in the selected period",
        "Model": "Model",
        "NoCarForModelPeriod": "The given vehicle model is not available in the selected period",
        "NoCarForPeriod": "There is no available vehicle in the selected period",
        "Parking": "Parking lot",
        "Pay": {
            "Error": "An error occurred, the payment cannot be paid now. Please try again later or contact your system administrator."
        },
        "PayAndSend": "Send and pay",
        "PickUpTooltip": "The vehicle can only be picked up at the time of booking.",
        "Price": "Price",
        "PriceFor": "Price for",
        "Pricelist": "Complete price list",
        "RepeteAfter": "After ordering, prepare the reservation of another vehicle",
        "SaveError": "The reservation could not be created.",
        "SaveSuccess": "Reservation successfully created.",
        "Title": "Vehicle Reservation",
        "To": "To",
        "VopLink": "terms and conditions",
        "VopPrefix": "By submitting an order, you agree to the"
    },
    "ReservationState": {
        "Approved": "Approved",
        "Canceled": "Cancelled",
        "Finished": "Completed",
        "New": "Waiting for approval",
        "Paid": "Ordered",
        "ReadyToTake": "You can take over the vehicle",
        "Rejected": "Rejected",
        "Taken": "Vehicle rented",
        "Unknown": "Unknown",
        "WaitForPay": "Awaiting payment"
    },
    "ReservList": {
        "Detail": "Reservation detail",
        "LoadCarDetailError": "Failed to load vehicle detail.",
        "LoginText": "Please login to view your reservations.",
        "NoParking": "No parking",
        "NoReserv": "No reservations",
        "Return": "Return the vehicle",
        "Takeover": "Pick up the vehicle",
        "Title": "My reservations",
        "UnknownControl": "Unknown control type",
        "Unlock": "Unlock"
    },
    "Select": {
        "NoOptions": "No options"
    },
    "Settings": {
        "ConfirmPhoneError": "The phone number has not been verified.",
        "ConfirmPhoneSuccess": "The phone number has been successfully verified and set up.",
        "Email": "E-mail",
        "Identity": "Identity",
        "Lang": "Language",
        "Link": "Settings",
        "Phone": "Telephone number",
        "SetPhoneError": "There was an error setting the phone number.",
        "Title": "User settings",
        "VerifyIdentity": "Verify identity",
        "VerifyPhone": "Verify and save"
    },
    "SidePanSel": {
        "Filter": "Filter",
        "Reserv": "Reservation"
    },
    "Sig": {
        "Title": "Signature"
    },
    "TakeoverInfoModal": {
        "Open": "Open the application",
        "Text": "To pick up and return the vehicle, please install the XFLEET by Xmarton mobile application, with which you will also unlock the vehicle at the time of reservation and enable start.",
        "Title": "Takeover the vehicle"
    },
    "TakeoverTypes": {
        "return": "Return",
        "takeover": "Pick up",
        "takeover_and_return": "Pick up and return"
    },
    "WebTitle": "Carsharing"
}

export default en;