const pl = {
    "App": {
        "AuthBoundMsg": "Skontaktuj się z administratorem i opisz, jak wystąpił błąd.",
        "AuthBoundTit": "Błąd autoryzacji",
        "ConfigError": "Błąd ładowania konfiguracji. Spróbuj ponownie lub skontaktuj się z administratorem systemu.",
        "ConfigLoadign": "Ładowanie konfiguracji...",
        "Init": "Inicjowanie aplikacji...",
        "ProcLogin": "Zaloguj sie",
        "ProcLoginClbck": "Zaloguj sie",
        "ProcLoginClbckTxt": "Przetwarzam informacje o użytkownikach...",
        "ProcLoginTxt": "Wysyłam prośbę o zalogowanie...",
        "ProcLogout": "Wyloguj",
        "ProcLogoutClbck": "Wyloguj",
        "ProcLogoutClbckTxt": "Przetwarzam odpowiedź...",
        "ProcLogoutTxt": "Wysyłam prośbę o anulowanie subskrypcji...",
        "ProcPay": "Zapłata",
        "ProcPayCallback": "Przetwarzanie płatności..."
    },
    "Btn": {
        "Accept": "Potwierdzać",
        "Cancel": "Anulować",
        "Capture": "Zrobić zdjęcie",
        "Clear": "Usuwać",
        "Close": "Zamknąć",
        "Delete": "Usuwać",
        "Login": "Zaloguj sie",
        "Logout": "Wyloguj",
        "No": "NIE",
        "Pay": "Płacić",
        "Save": "Narzucać",
        "Send": "Wysłać",
        "TakePhoto": "Robić zdjęcie...",
        "Yes": "Tak"
    },
    "Carshare": {
        "NoCar": "Obecnie nie ma wolnego pojazdu do wynajęcia"
    },
    "Const": {
        "AllModel": "Wszystkie modele",
        "NoModel": "Brak modelu"
    },
    "Contacts": {
        "InfoSupport": "Wsparcie informacyjne",
        "Manual": "Podręcznik",
        "Manuals": {
            "Main": "Carsharing",
            "Main_auto-lion": "Carsharing AUTO-LION",
            "Main_carsharingkacmacek": "CARsharing Kačmáček",
            "Main_kb": "KB - Bikesharing",
            "Main_malekarpaty": "Malé Karpaty - BikeSharing",
            "Main_mkparking": "Malé Karpaty - BikeParking",
            "MainFile": "KB_BikeSharing_Manual_cs.pdf",
            "MainFile_auto-lion": "carsharing_auto-lion_manual_cs.pdf",
            "MainFile_carsharingkacmacek": "carsharing_kacmacek_manual_cs.pdf",
            "MainFile_kb": "kb_bikesharing_manual_cs.pdf",
            "MainFile_malekarpaty": "male_karpaty_bikesharing_manual_cs.pdf",
            "MainFile_mkparking": "male_karpaty_bikeparking_manual_cs.pdf",
            "MainName": "Carsharing - Instrukcje.pdf",
            "MainName_auto-lion": "Carsharing AUTO-LION - Návod.pdf",
            "MainName_carsharingkacmacek": "CARsharing Kačmáček - Návod.pdf",
            "MainName_kb": "KB BikeSharing - Návod.pdf",
            "MainName_malekarpaty": "Malé Karpaty BikeSharing - Návod.pdf",
            "MainName_mkparking": "Malé Karpaty BikeParking - Návod.pdf"
        },
        "ServisSupport": "Wsparcie serwisu rowerowego",
        "Support": "Pomoc techniczna",
        "Title": "Podręcznik i kontakty"
    },
    "Damage": {
        "Data": "Dane",
        "DataNotLoadedErr": "Nie udało się załadować danych pojazdu.",
        "Email": "E-mail kierowcy",
        "EmailPlaceholder": "Podaj adres e-mail kierowcy",
        "FindCar": "Wyszukaj pojazd",
        "Name": "Nazwisko kierowcy",
        "NamePlaceholder": "Wprowadź nazwisko kierowcy",
        "NoDataErr": "Nieistniejący lub nieautoryzowany pojazd.",
        "RegNum": "Znak rejestracyjny",
        "RegNumPlaceholder": "Wprowadź numer rejestracyjny",
        "Title": "WADY POJAZDU"
    },
    "Damages": {
        "NewBtn": "Zgłoś nowy",
        "NoDamages": "W pojeździe nie ma zgłoszonych usterek.",
        "NoNameEmail": "Najpierw wpisz swoje imię i adres e-mail.",
        "NoRegNum": "Najpierw wpisz numer rejestracyjny.",
        "Title": "Wady i uszkodzenia"
    },
    "Filter": {
        "All": "Wszystko",
        "Title": "modele"
    },
    "FinishSettingsRequiredModal": {
        "Btn": "Ustawienia użytkownika",
        "ReqIdentity": "Przed zarezerwowaniem pojazdu zweryfikuj swoją tożsamość.",
        "ReqPhone": "Przed dokonaniem rezerwacji pojazdu prosimy o podanie numeru telefonu kontaktowego.",
        "ReqPhoneAndIdentity": "Przed dokonaniem rezerwacji pojazdu prosimy o podanie numeru telefonu kontaktowego i zweryfikowanie swojej tożsamości.",
        "Title": "Zakończenie konfiguracji"
    },
    "Handover": {
        "Code": "Kod kolekcji",
        "DataLoading": "Ładowanie danych...",
        "Name": "Pojazd",
        "NoCarId": "Brak identyfikatora pojazdu.",
        "Nodata": "Żadne dane nie zostały załadowane.",
        "NoType": "Brak typu przekazania.",
        "ParkingNo": "Numer stoiska",
        "Place": "Parking",
        "ReturnAndLock": "Wróć i zamknij",
        "ReturnTitle": "ZWROT POJAZDU",
        "SuccessReturnText": "Dziękujemy i czekamy na Twoją kolejną rezerwację.",
        "SuccessReturnTitle": "Pojazd został pomyślnie zwrócony",
        "SuccessTakeoverText": "Łańcuch koła odblokuje się teraz na 30 sekund, w tym czasie zdejmij łańcuch. Odblokuj koło i umieść łańcuch z powrotem w zamku.",
        "SuccessTakeoverTitle": "Pojazd został pomyślnie przejęty",
        "TakeAndUnlock": "Pobierz i odblokuj",
        "TakeoverTitle": "ODZYSKIWANIE POJAZDU",
        "UnlockBike": "Odblokuj rower",
        "UnlockError": "Błąd wysyłania polecenia odblokowania podstawki.",
        "UnlockParking": "Odblokuj parking",
        "UnlockSuccess": "Prośba o odblokowanie stoiska została wysłana."
    },
    "Check": {
        "BeforeLeave": "Czy na pewno chcesz opuścić stronę i odrzucić formularz w toku?",
        "Consents": "Umowy",
        "Data": "Dane",
        "DataNotLoadedErr": "Nie udało się załadować danych pojazdu.",
        "DataSaved": "Dane wysłane pomyślnie.",
        "DataSaveErr": "Wystąpił błąd podczas wysyłania danych.",
        "Email": "E-mail kierowcy",
        "EmailPlaceholder": "Podaj adres e-mail kierowcy",
        "EmailRequired": "Podaj imię i nazwisko oraz adres e-mail kierowcy.",
        "FindCar": "Wyszukaj pojazd",
        "Name": "Nazwisko kierowcy",
        "NamePlaceholder": "Wprowadź nazwisko kierowcy",
        "NameRequired": "Podaj imię i nazwisko oraz adres e-mail kierowcy.",
        "NoDataErr": "Nieistniejący lub nieautoryzowany pojazd.",
        "NotAllConsentsChecked": "Wszystkie zgody wymagają potwierdzenia.",
        "NotAllPhotosLoaded": "Proszę dodać zdjęcia.",
        "Photos": "Robienie zdjęć",
        "RegNum": "Znak rejestracyjny",
        "RegNumPlaceholder": "Wprowadź numer rejestracyjny",
        "ReturnBtn": "Zwróć pojazd",
        "ReturnDone": "Pojazd został pomyślnie zwrócony.",
        "Sig": "Podpis",
        "SigRequired": "Proszę uzupełnić podpis.",
        "TakeDone": "Pojazd został pomyślnie przejęty.",
        "TakeoverBtn": "Przejmij pojazd",
        "Title": "DOSTAWA POJAZDU",
        "Type": "Sprawdź o godz",
        "TypePlaceholder": "Wybierz typ inspekcji",
        "TypeRequired": "Wybierz typ czeku."
    },
    "Identity": {
        "Unverified": "Niesprawdzony"
    },
    "Lang": {
        "cs": "Čeština",
        "de": "Deutsch",
        "en": "English",
        "pl": "Polski",
        "sk": "Slovenčina"
    },
    "Login": {
        "BadLogin": "Nieprawidłowe poświadczenia",
        "ByGoogle": "Zaloguj się przez Google",
        "ByXmarton": "Zaloguj się przez Xmarton",
        "ConsentLink": "https://www.xmarton.com/data/Informace_o_zpracovani_osobnich_udaju_pro_zakazniky.pdf",
        "ConsentLinkText": "przetwarzanie danych osobowych",
        "ConsentTextPostfix": ".",
        "ConsentTextPrefix": "Kontynuując wyrażasz zgodę na ",
        "ErrTitle": "Błąd logowania",
        "Password": "Hasło",
        "Title": "Zaloguj sie",
        "Username": "E-mail"
    },
    "Logout": {
        "ErrTitle": "Błąd wylogowania",
        "NotLocalLogin": "Wylogowanie nie zostało zainicjowane w aplikacji."
    },
    "Map": {
        "ApiKeyError": "Wystąpił błąd konfiguracji aplikacji. Spróbuj ponownie lub skontaktuj się z administratorem systemu.",
        "EnableLocationMsg": "Włącz uprawnienia dostępu do lokalizacji.",
        "Filter": "modele",
        "FindClose": "Znajdź najbliższy",
        "LoadError": "Nie udało się załadować mapy, spróbuj ponownie.",
        "Loading": "Ładowanie mapy",
        "Location": "Twoja lokalizacja",
        "Reservations": "Rezerwacja",
        "Satellite": "Mapa satelitarna",
        "ZoomInTitle": "Zbliżenie",
        "ZoomOutTitle": "Opóźnienie"
    },
    "Nav": {
        "App": "Otwórz aplikację",
        "Login": "Zaloguj sie",
        "Logouted": "Jesteś wylogowany",
        "Title": "POJAZDY DO WYNAJĘCIA"
    },
    "NewDamage": {
        "Category": "Kategoria",
        "CategoryRequired": "Kategoria jest wymagana.",
        "DataSaved": "Niedobór został pomyślnie zgłoszony.",
        "DataSaveErr": "Wystąpił błąd podczas wysyłania danych.",
        "Description": "Opis",
        "DescriptionRequired": "Proszę uzupełnić opis.",
        "Event": "Zdarzenie ubezpieczone",
        "Photo": "Zdjęcie",
        "PhotoRequired": "Proszę dodać zdjęcie.",
        "RegNum": "Znak rejestracyjny",
        "Title": "Zgłaszanie braków"
    },
    "PaymentResult": {
        "Error": "Wystąpił błąd podczas przetwarzania wyniku płatności. Skontaktuj się z administratorem systemu.",
        "Fail": "Płatność nie została pomyślnie dokonana",
        "Success": "Płatność została pomyślnie dokonana",
        "Title": "Zapłata"
    },
    "PhoneConfirmModal": {
        "Code": "Kod weryfikacyjny",
        "Text": "Aby zweryfikować poprawność numeru telefonu, wysłaliśmy do Ciebie SMS z kodem weryfikacyjnym. Wprowadź go, aby zakończyć.",
        "Title": "Weryfikacja numeru telefonu"
    },
    "PhotoCapture": {
        "Title": "Robić zdjęcie"
    },
    "PhotoInput": {
        "MaxFileSize": "Przekroczono limit rozmiaru.",
        "Photo": "Zdjęcie",
        "UnsupportedFileType": "Nieobsługiwany typ pliku."
    },
    "Rent": {
        "Title": "Wynajem pojazdu"
    },
    "Reservation": {
        "Cancel": {
            "Btn": "Anulowanie rezerwacji",
            "Confirm": {
                "Question": "Czy na pewno chcesz anulować rezerwację?",
                "Title": "Anulowanie rezerwacji"
            },
            "Error": "Rezerwacja nie została anulowana.",
            "Success": "Rezerwacja została pomyślnie anulowana."
        },
        "Car": "Pojazd",
        "CarNotAvaibleForPeriod": "Pojazd niedostępny do wybranego okresu",
        "CarPlaceholder": "Wybierz pojazd",
        "DepositInfo": "W cenę wynajmu wliczona jest także kaucja zwrotna w wysokości {deposit}.",
        "Equipment": "Bieg",
        "EquipmentFor": "Sprzęt do",
        "Finish": {
            "Btn": "Zakończ rezerwację",
            "Confirm": {
                "Question": "Czy na pewno chcesz anulować rezerwację?",
                "Title": "Zakończ rezerwację"
            },
            "Error": "Rezerwacja nie została zakończona.",
            "Success": "Rezerwacja została pomyślnie zakończona."
        },
        "From": "Z",
        "GdprLink": "przetwarzania danych osobowych",
        "GdprPrefix": "Zasady",
        "CheckError": "Nie można otworzyć bramki płatniczej. Skontaktuj się z pomocą techniczną.",
        "LastCarForPeriod": "There is the last vehicle available in the selected period",
        "Model": "Model",
        "NoCarForModelPeriod": "The given vehicle model is not available in the selected period",
        "NoCarForPeriod": "There is no available vehicle in the selected period",
        "Parking": "Parking",
        "Pay": {
            "Error": "Wystąpił błąd, płatność nie może zostać teraz dokonana. Spróbuj ponownie później lub skontaktuj się z administratorem systemu."
        },
        "PayAndSend": "Wyślij i zapłać",
        "PickUpTooltip": "Odbiór pojazdu będzie możliwy tylko w momencie dokonywania rezerwacji.",
        "Price": "Cena",
        "PriceFor": "Cena za",
        "Pricelist": "Kompletny cennik",
        "RepeteAfter": "Po złożeniu zamówienia przygotuj rezerwację kolejnego pojazdu",
        "SaveError": "Nie można utworzyć rezerwacji.",
        "SaveSuccess": "Rezerwacja została pomyślnie utworzona.",
        "Title": "Rezerwacja pojazdu",
        "To": "Do",
        "VopLink": "warunki",
        "VopPrefix": "Składając zamówienie, wyrażasz zgodę na"
    },
    "ReservationState": {
        "Approved": "Zatwierdzony",
        "Canceled": "Odwołany",
        "Finished": "Zakończony",
        "New": "Czeka na zatwierdzenie",
        "Paid": "Zamówione",
        "ReadyToTake": "Możesz przejąć pojazd",
        "Rejected": "Odrzucony",
        "Taken": "Wynajęty pojazd",
        "Unknown": "Nieznany",
        "WaitForPay": "Oczekiwanie na zapłatę"
    },
    "ReservList": {
        "Detail": "Szczegóły rezerwacji",
        "LoadCarDetailError": "Nie udało się załadować szczegółów pojazdu.",
        "LoginText": "Zaloguj się, aby zobaczyć swoje rezerwacje.",
        "NoParking": "Nie parkować.",
        "NoReserv": "Brak rezerwacji",
        "Return": "Zwróć pojazd",
        "Takeover": "Przejmij pojazd",
        "Title": "Moje rezerwacje",
        "UnknownControl": "Nieznany typ kontroli",
        "Unlock": "Odblokować"
    },
    "Select": {
        "NoOptions": "Brak opcji"
    },
    "Settings": {
        "ConfirmPhoneError": "Numer telefonu nie został zweryfikowany.",
        "ConfirmPhoneSuccess": "Numer telefonu został pomyślnie zweryfikowany i skonfigurowany.",
        "Email": "E-mail",
        "Identity": "Tożsamość",
        "Lang": "Język",
        "Link": "Ustawienia",
        "Phone": "Numer telefonu",
        "SetPhoneError": "Wystąpił błąd podczas ustawiania numeru telefonu.",
        "Title": "Ustawienia użytkownika",
        "VerifyIdentity": "Zweryfikuj tożsamość",
        "VerifyPhone": "Sprawdź i zapisz"
    },
    "SidePanSel": {
        "Filter": "Filtr",
        "Reserv": "Rezerwacja"
    },
    "Sig": {
        "Title": "Podpis"
    },
    "TakeoverInfoModal": {
        "Open": "Otwórz aplikację",
        "Text": "Aby odebrać i zwrócić pojazd, zainstaluj aplikację mobilną XFLEET by Xmarton, za pomocą której odblokujesz również pojazd w momencie rezerwacji i umożliwisz start.",
        "Title": "Przejęcie pojazdu"
    },
    "TakeoverTypes": {
        "return": "Powrót",
        "takeover": "Przejąć",
        "takeover_and_return": "Odbiór i zwrot"
    },
    "WebTitle": "Udostępnianie samochodu"
}

export default pl;