import React from 'react'
import { Component } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { QueryParameterNames, LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';
import Loader from '../Loader';
import { AppContext } from '../AppContext';
import { useContext } from 'react';
import ErrorPage from './ErrorPage';
import { stringify } from '../../util/helper';


function LogoutError({ message }) {
    const { t } = useContext(AppContext);

    return (
        <ErrorPage
            title={t('Logout.ErrTitle')}
            message={message}
            resetErrorBoundary={() => window.location.assign('/')}
        />
    );
}

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export class Logout extends Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            message: undefined,
            isReady: false,
            authenticated: false,
            error: null
        };
    }

    componentDidMount() {
        const { t } = this.context;
        const { action } = this.props;
        
        try {
            switch (action) {
                case LogoutActions.Logout:
                    if (!!_.get(window, 'history.state.state.local')) {
                        this.logout(this.getReturnUrl());
                    } else {
                        // This prevents regular links to <app>/authentication/logout from triggering a logout
                        this.setState({ error: { message: t("Logout.NotLocalLogin") } });
                    }
                    break;
                case LogoutActions.LogoutCallback:
                    this.processLogoutCallback();
                    break;
                case LogoutActions.LoggedOut:
                    this.setState({ isReady: true, message: "You successfully logged out!" });
                    break;
                default:
                    this.setState({ error: { message: `Invalid action '${action}'` } });
            }

            this.populateAuthenticationState();
        } catch (error) {
            console.error('Error componentDidMount:', error);
            this.setState({ error: { message: stringify(error) } });
        }
    }

    render() {
        const { isReady, message, error } = this.state;
        const { t } = this.context;

        if (error) {
            return <LogoutError {...error} />;
        }
        if (!isReady) {
            return <div></div>
        }
        if (!!message) {
            return (<div>{message}</div>);
        } else {
            const action = this.props.action;
            switch (action) {
                case LogoutActions.Logout:
                    return (
                        <Loader
                            text={t('App.ProcLogout')}
                            subtext={t('App.ProcLogoutTxt')}
                        />
                    );
                case LogoutActions.LogoutCallback:
                    return (
                        <Loader
                            text={t('App.ProcLogoutClbck')}
                            subtext={t('App.ProcLogoutClbckTxt')}
                        />
                    );
                case LogoutActions.LoggedOut:
                    return (<div>{message}</div>);
                default:
                    return <LogoutError message={`Invalid action '${action}'`} />;
            }
        }
    }

    async logout(returnUrl) {
        try {
            const state = { returnUrl };
            const isauthenticated = await authService.isAuthenticated();
            if (isauthenticated) {
                const result = await authService.signOut(state);
                switch (result.status) {
                    case AuthenticationResultStatus.Redirect:
                        break;
                    case AuthenticationResultStatus.Success:
                        await this.navigateToReturnUrl(result.state && result.state.returnUrl || returnUrl);
                        break;
                    case AuthenticationResultStatus.Fail:
                        this.setState({ error: { message: stringify(result.message) } });
                        break;
                    default:
                        throw new Error("Invalid authentication result status.");
                }
            } else {
                this.setState({ message: "You successfully logged out!" });
            }
        } catch (error) {
            console.error('Error logout:', error);
            this.setState({ error: { message: stringify(error) } });
        }
    }

    async processLogoutCallback() {
        try {
            const url = window.location.href;
            const result = await authService.completeSignOut(url);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    // There should not be any redirects as the only time completeAuthentication finishes
                    // is when we are doing a redirect sign in flow.
                    throw new Error('Should not redirect.');
                case AuthenticationResultStatus.Success:
                    await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                    break;
                case AuthenticationResultStatus.Fail:
                    this.setState({ error: { message: stringify(result.message) } });
                    break;
                default:
                    throw new Error("Invalid authentication result status.");
            }
        } catch (error) {
            console.error('Error processLogoutCallback:', error);
            this.setState({ error: { message: stringify(error) } });
        }
    }

    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        this.setState({ isReady: true, authenticated });
    }

    getReturnUrl(state) {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) ||
            fromQuery ||
            `${window.location.origin}${ApplicationPaths.LoggedOut}`;
    }

    navigateToReturnUrl(returnUrl) {
        return window.location.replace(returnUrl);
    }
}
