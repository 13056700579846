import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        const { onLogout, onSettings } = this.props;

        return (
            <div className={this.props.className}>
                <NavMenu
                    onLogout={onLogout}
                    onSettings={onSettings}
                />
                <div className="app-container">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
