import * as _ from 'lodash';
import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import classNames from 'classnames';
import apiService from '../../util/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { invoke } from '../../util/helper';
import { AppContext } from '../AppContext';

export class LoginMenu extends Component {

    static contextType = AppContext;

    static propTypes = {
        onLogout: PropTypes.func,
        onSettings: PropTypes.func
    }

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            isOpen: false
        };

        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        //console.log('dev', 'populateState', { user });
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    async logout() {
        const { onLogout } = this.props;

        invoke(onLogout);
    }

    render() {
        const { pathConfig, user, isInFrame, t, openApp } = this.context;
        const { isAuthenticated, userName } = this.state;
        //console.log('dev', 'loginmenu', { isAuthenticated, userName });

        if (isInFrame) {
            return (
                <NavItem
                    className='cs-login-link'
                    onClick={openApp}
                >{t('Nav.App')}</NavItem>
            );
        } else if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${_.get(pathConfig, 'url', '')}/login`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        const { t, userLoading } = this.context;
        const { onSettings } = this.props;
        let { isOpen } = this.state;

        return (
            <Dropdown
                nav
                isOpen={isOpen}
                toggle={() => this.setState({ isOpen: !isOpen })}
                className='user-menu'
            >
                <DropdownToggle
                    className="user-nav text-dark no-padding"
                    color="link"
                >
                    <span className="login-ico"><FontAwesomeIcon icon="fa-regular fa-user-circle" /></span>
                    <span className="hide-on-mobile">{userName}</span>
                </DropdownToggle>
                <DropdownMenu end>
                    <DropdownItem
                        onClick={() => invoke(onSettings)}
                    >{t('Settings.Link')}</DropdownItem>
                    <DropdownItem
                        onClick={this.logout}
                    >{t('Btn.Logout')}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }

    anonymousView(registerPath, loginPath) {
        const { t, userLoading, invokeLogin } = this.context;

        return (<Fragment>
            <NavItem
                className='cs-login-link'
                onClick={() => invokeLogin()}
            >
                <span className="login-ico"><FontAwesomeIcon icon={classNames('fa-regular fa-user-circle ', { 'fa-spin': userLoading })} /></span>
                <span className="hide-on-mobile">{t('Nav.Login')}</span>
            </NavItem>
        </Fragment>);
    }
}
