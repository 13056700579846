import { getLocStorItem, remLocStorItem, setLocStorItem } from "./store";

const LSK_CTX = 'ctx';
export const LSK_RESERV_CTX = 'reservation';
export const LSK_REP_RESERV_CTX = 'repeat_reservation';

function getKey(id) {
    return `${LSK_CTX}_${id}`;
}

function getId() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function saveContext(data, key) {
    if (!key) {
        key = getId();
    }
    setLocStorItem(getKey(key), JSON.stringify(data));
    return key;
}

export function loadContext(id) {
    let key = getKey(id);
    let item = getLocStorItem(key);
    if (item) {
        remLocStorItem(key);
        return JSON.parse(item);
    }
    return null;
}
