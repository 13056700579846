import authService, { AuthenticationResultStatus } from '../components/api-authorization/AuthorizeService'
import axios from 'axios';
import { logErrors } from './error';
import { sleep } from './async';
import * as _ from 'lodash';
import { currentLanguage } from './lang';
import { parse, stringify } from 'qs';


export class ApiService {

    waiting = {};

    async login(params, signal) {
        return await this.callAnonymous('post', `api/login`, params, { signal });
    }

    async logout(params, signal) {
        return await this.callAnonymous('post', `api/logout`, params, { signal });
    }

    async getUserProfile(signal) {
        return await this.callAnonymous('get', `api/profile`, null, { signal });
    }

    // #region /api/car

    async getCars(params, signal) {
        return await this.callAnonymous('get', `api/csma/car`, params, { signal });
    }

    async getCheckDataById(id, signal) {
        return await this.callAnonymous('get', `api/car/${id}/check`, null, { signal });
    }

    async getCheckDataByRegNum(params, signal) {
        return await this.callAnonymous('get', `api/csma/car/checkInfo`, params, { signal });
    }

    async sendCheck(id, data, signal) {
        return await this.callAnonymous('post', `api/car/${id}/check`, data, { signal });
    }

    async takeover(id, data, signal) {
        return await this.callAnonymous('post', `api/car/${id}/takeover`, data, { signal });
    }

    async unlockStand(id, data, signal) {
        return await this.callAnonymous('post', `api/csma/car/${id}/unlockStand`, data, { signal });
    }

    async addDamage(id, data, signal) {
        return await this.callAnonymous('post', `api/car/${id}/damage`, data, { signal });
    }

    async getCarsForCheck(signal) {
        return await this.callAnonymous('get', `api/csma/car/check`, null, { signal });
    }

    async getAvaibleCars(params, signal) {
        return await this.callAnonymous('get', `api/car/reservation`, params, { signal });
    }

    async getCarsForReservation(params, signal) {
        return await this.callAnonymous('get', `api/csma/car/reservation`, params, { signal });
    }

    async getCarDetail(id, params, signal) {
        return await this.callAnonymous('get', `api/csma/car/${id}`, params, { signal });
    }

    async getHandoverData(carId, params, signal) {
        return await this.callAnonymous('get', `api/csma/car/${carId}/checkInfo`, params, { signal });
    }

    // #endregion

    // #region /api/cdl

    async getDamageCategoryCodelist(signal) {
        return await this.callAnonymous('get', `api/cdl/damage_category`, null, { signal });
    }

    // #endregion

    // #region /api/config

    async getClientConfig(signal) {
        return await this.callAnonymous('get', `api/config`, null, { signal });
    }

    // #endregion

    // #region /api/parking

    async getParkings(params, signal) {
        return await this.callAnonymous('get', `api/csma/parking`, params, { signal });
    }

    // #endregion

    // #region /provider

    async getProviders(params, signal) {
        return await this.callAnonymous('get', `api/csma/provider`, params, { signal });
    }

    // #endregion

    // #region /api/reservation

    async getReservations(params, signal) {
        return await this.callAnonymous('get', `api/csma/reservation`, params, { signal });
    }

    async makeReservation(input, signal) {
        return await this.callAnonymous('put', `api/csma/reservation`, input, { signal });
    }

    async updateReservation(id, data, signal) {
        return await this.callAnonymous('post', `api/csma/reservation/${id}`, data, { signal });
    }

    // #endregion

    // #region /api/user

    async setPhone(data, signal) {
        return await this.callAnonymous('post', `api/user/phone`, data, { signal });
    }

    async confirmPhone(data, signal) {
        return await this.callAnonymous('post', `api/user/phone/confirm`, data, { signal });
    }

    // #endregion

    // #region /api/payment

    async checkPaymentConfig(params, signal) {
        return await this.callAnonymous('get', `api/payment/check`, params, { signal });
    }

    async handlePaymentCallback(definitionId, params, signal) {
        return await this.callAnonymous('post', `api/payment/callback/${definitionId}`, params, { signal });
    }

    async invokePayment(input, signal) {
        return await this.callAnonymous('post', `api/payment`, input, { signal });
    }
        
    // #endregion

    // #region Async

    async getActionInfo(id, signal) {
        return await this.callAnonymous('get', `api/data/async/${id}`, null, { signal });
    }

    async getFinishedActions(signal) {
        return await this.callAnonymous('get', `api/data/async/finished`, null, { signal });
    }

    async waitForActionFinished(info, signal) {
        //console.log('waitForActionFinished', { info: { ...info }, waiting: { ...this.waiting } });
        //console.log('start waiting...', info.id);
        if (Object.keys(this.waiting).length) {
            this.waiting[info.id] = true;
        } else {
            this.waiting[info.id] = true;
            this.checkingFinishedActions();
        }
        do {
            await sleep(100);
        } while (this.waiting[info.id] && (!signal || !signal.aborted));
        //console.log('waiting finished', info.id);
        delete this.waiting[info.id];
    }

    async waitForAsyncAction(info, signal) {
        //console.log('waitForAsyncAction', { info });
        await this.waitForActionFinished(info, signal);
        if (!signal || !signal.aborted) {
            let resp = await this.getActionInfo(info.id, signal);
            info = resp.data;
        }
        return info;
    }

    async checkingFinishedActions() {
        //console.log('checkingFinishedActions');
        while (Object.keys(this.waiting).filter(k => this.waiting[k]).length) {
            let resp = await this.getFinishedActions();
            (resp.data || []).forEach(o => { if (this.waiting[o]) this.waiting[o] = false; });
            await sleep(333);
        }
    }

    // #endregion

    async callAuthorized(method, path, data, options) {
        let reqId = new Date().getTime();
        let token = await authService.getAccessToken();
        //console.log(`call ${method} ${path}`, reqId);
        return this._callApi(token, method, path, data, options).catch(async error => {
            if (!options || !options.signal || !options.signal.aborted) {
                if (!logErrors(error, method, path, reqId)) {
                    console.log('chyba api', reqId, error);
                }
                if (error.response && error.response.status === 401) {
                    //return this.authService.renewToken().then(renewedUser => {
                    //    return this._callApi(renewedUser.access_token, method, path, data, options);
                    //});
                    console.log('401', reqId);
                    let signInResult = await authService.signIn();
                    if (signInResult.status === AuthenticationResultStatus.Success) {
                        token = await authService.getAccessToken();
                        console.log('znovu volám api', reqId);
                        return this._callApi(token, method, path, data, options);
                    } else {
                        console.log('přihlášení nedopadlo dobře', reqId);
                    }
                }
                throw error;
            }
        });;
    }

    async callAnonymous(method, path, data, options) {
        //console.log(`call ${method} ${path}`);
        let token = await authService.getAccessToken();
        //console.log(`call ${method} ${path} - token loaded`);
        return this._callApi(token, method, path, data, options);
    }

    _callApi(token, method, path, data, options) {
        const headers = {
            Accept: 'application/json',
            'User-Language': currentLanguage
        };
        //console.log('dev', 'calling ', path, { idProvider: this.idProvider });
        if (this.idProvider || this.idProvider === 0)
            headers.idProvider = this.idProvider;
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        options = { ...(options || {}) };
        options.headers = { ...(options.headers || {}), ...headers };
        switch (method) {
            case 'put':
                return axios.put(path, data, options);
            case 'post':
                return axios.post(path, data, options);
            case 'delete':
                if (data) {
                    return axios.delete(path, { ...options, data });
                } else {
                    return axios.delete(path, options);
                }
            default:
                if (data) {
                    options.params = {
                        ...(options.params || {}),
                        ...data
                    };
                    options.paramsSerializer = {
                        encode: parse,
                        serialize: stringify,
                    }
                }
                return axios.get(path, options);
        }
    }
}

const apiService = new ApiService();

export default apiService;
