export const invoke = (fn, ...args) => {
    if (fn) {
        fn(...args);
    }
}

export const isNullOrUndef = value => value === null || value === undefined;

export const joinNullable = (delimiter, ...values) => {
    return values.filter(o => !!o).join(delimiter || ', ');
}

export function stringify(value) {
    if (typeof value === 'object') {
        return value.toString();
    } else if (typeof value === 'string') {
        return value;
    } else {
        return JSON.stringify(value);
    }
}