import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classNames from 'classnames';
import * as moment from 'moment';
import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import { invoke } from '../../util/helper';

import './FleetTimePicker.js.scss';


const HourItem = ({ data, onChose }) => {

    return (
        <tr>
            {data.map(o => <MinuteItem key={o.minute} value={o} onChose={onChose} />)}
        </tr>
    );
}

const MinuteItem = ({ value, onChose }) => {

    let val = moment(value);

    return (
        <td
            className="ftp-item-container"
            onClick={() => invoke(onChose, val)}
        >
            <div
                className={classNames('ftp-item', { chosen: value.isChosen })}
            >{val.format('HH:mm')}</div>
        </td>
    );
}

const FleetTimePicker = ({ value, onChange, className, disabled, minutes }) => {

    const [isOpen, setIsOpen] = useState(false);

    const close = () => {
        setIsOpen(false);
    }

    const handleChose = val => {
        if (!moment.isMoment(value) || val.hour() !== value.hour() || val.minute() !== value.minute()) {
            invoke(onChange, val);
        }
        close();
    }

    const handleClick = e => {
        if (!disabled) {
            setIsOpen(true);
        }
    }

    const getData = () => {
        let min = minutes > 0 && minutes < 60  ? minutes : 60;
        let minc = Math.floor(60 / min);
        let res = [];

        for (var h = 0; h < 24; h++) {
            let items = [];
            for (var m = 0; m < minc; m++) {
                let val = { hour: h, minute: m * min };
                val.isChosen = moment.isMoment(value) && value.hour() === val.hour && value.minute() === val.minute;
                items.push(val);
            }
            res.push(items);
        }
        return res;
    }

    return (
        <div className={classNames('ftp-container', className)}>
            <div
                className="ftp-btn"
                onClick={handleClick}
            >
                <FontAwesomeIcon icon='fa-regular fa-clock' />
            </div>

            <Modal
                className='ftp-modal'
                isOpen={isOpen}
                toggle={close}
            >
                <div className="ftp-values">
                    <table>
                        <tbody>
                            {getData().map(o => <HourItem key={o[0].hour} data={o} onChose={handleChose} />)}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </div>
    );
}

export default FleetTimePicker;