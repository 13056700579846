export const ERR_SYSTEM = "err_system";

export function logErrors(error, method, path, id) {
    let result = isApiError(error);
    if (result) {
        console.log(`error ${method} ${path}`, id, error.response.data.errors);
    }
    return result;
}

export function isError(error, code) {
    let result = isApiError(error) &&
        error.response.data.errors.includes(code);
    return result;
}

export function isApiError(error) {
    let result = error &&
        error.response &&
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length;
    return result;
}
