import * as _ from 'lodash';
import * as moment from 'moment';
import classnames from 'classnames';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Input } from 'reactstrap';

import i18n from '../../i18n/i18n';
import FleetTimePicker from './FleetTimePicker';
import { Fa5FadCalendarAlt } from '../fontawesome5/fa5-custom';

import './DateTimeInput.scss';


export const DefaultDateTimeInputDateFormat = 'D. M. YYYY';
export const DefaultDateTimeInputTimeFormat = 'HH:mm';

const DateTimeInput = ({ value, autoFocus, readOnly, onChange, dateFormat = DefaultDateTimeInputDateFormat, timeFormat = DefaultDateTimeInputTimeFormat }) => {

    //console.log('dev', 'tdi', { dateFormat });

    const [date, setDate] = useState(value && value.isValid() ? moment(value) : null);
    const [dateError, setDateError] = useState(false);
    const [dateInput, setDateInput] = useState('');
    const [datePicker, setDatePicker] = useState(new Date());
    const [timeInput, setTimeInput] = useState('');
    const [timeError, setTimeError] = useState(false);
    const [time, setTime] = useState(value && value.isValid() ? moment(value) : null);
    const [datetime, setDatetime] = useState(value && value.isValid() ? moment(value) : null);
    const [timePicker, setTimePicker] = useState(null);

    useEffect(() => {
        //console.log('date change', date);
        setDateInput(toStr(date, dateFormat));
        setDateError(false);
        setDatePicker(date ? date.toDate() : new Date());
    }, [date, dateFormat]);

    useEffect(() => {
        //console.log('time change', time);
        setTimeInput(toStr(time, timeFormat));
        setTimeError(false);
        setTimePicker(time ? time : null);
    }, [time]);

    useEffect(() => {
        if (dtStr(date, time, dateFormat, timeFormat) !== dtStr(datetime, datetime, dateFormat, timeFormat)) {
            let dt = date ? moment(dtStr(date, time, dateFormat, timeFormat), `${dateFormat} ${timeFormat}`) : null;
            setDatetime(dt);
            if (onChange) {
                onChange(dt);
            }
        }
    }, [date, time]);

    useEffect(() => {
        let sd = isSame(value, datetime, dateFormat);
        let st = isSame(value, datetime, timeFormat);
        if (!sd || !st) {
            setDatetime(value ? moment(value) : null);
            if (!sd) {
                setDate(value ? moment(value) : null);
            }
            if (!st) {
                setTime(value ? moment(value) : null);
            }
        }
    }, [value]);

    const parseDateInput = e => {
        //console.log('date input parse', e);
        if (dateInput) {
            let d = moment(dateInput, dateFormat);
            if (d.isValid()) {
                if (!isSame(d, date, dateFormat)) {
                    setDate(d);
                }
            } else {
                setDateError(true);
            }
        } else {
            setDate(null);
        }
    }

    const changeDateInput = e => {
        setDateInput(e.target.value);
    }

    const changeDatePicker = d => {
        //console.log('date picker change', d);
        let dt = moment(d);
        if (!isSame(dt, date, dateFormat)) {
            setDate(dt);
            if (!time) {
                setTime(moment('00:00', 'HH:mm'));
            }
        }
    }

    const changeTimeInput = e => {
        setTimeInput(e.target.value);
    }

    const parseTimeInput = e => {
        if (timeInput) {
            let t = moment(timeInput, timeFormat);
            if (t.isValid()) {
                if (!isSame(t, time, timeFormat)) {
                    setTime(t);
                }
            } else {
                setTimeError(true);
            }
        } else {
            setTime(null);
        }
    }

    const changeTimePicker = m => {
        if (!isSame(m, time, timeFormat)) {
            setTime(m);
        }
    }

    const cleanupCompoennt = e => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            if (!isSame(datetime, date, dateFormat)) {
                setDate(datetime ? moment(datetime) : null);
            }
            if (!isSame(datetime, time, timeFormat)) {
                setTime(datetime ? moment(datetime) : null);
            }
        }
    }

    const isSame = (m1, m2, f) => toStr(m1, f) === toStr(m2, f);
    const toStr = (m, f) => m ? m.format(f) : '';
    const dtStr = (d, t, df, tf) => d ? `${d.format(df)} ${(t || moment('00:00', 'HH:mm')).format(tf)}` : '';

    const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
        <Fa5FadCalendarAlt
            className='picker-btn'
            onClick={onClick}
        />
    ))

    return (
        <div
            className={classnames('datetime-input-container', { readonly: readOnly })}
            onBlur={cleanupCompoennt}
        >
            <div className={classnames('custom-control-container date-input-container', { readonly: readOnly })}>
                <Input
                    className={classnames('date-input', { empty: !dateInput, error: dateError })}
                    value={dateInput}
                    onChange={changeDateInput}
                    placeholder={dateFormat}
                    onBlur={parseDateInput}
                    autoFocus={autoFocus}
                    readOnly={readOnly}
                />
            </div>
            <ReactDatePicker
                selected={datePicker}
                onChange={changeDatePicker}
                customInput={<CustomDateInput />}
                locale={i18n.language}
                disabled={readOnly}
                minDate={moment().startOf('day').toDate()}
            />
            <div className={classnames('custom-control-container time-input-container', { readonly: readOnly })}>
                <Input
                    className={classnames('time-input', { empty: !timeInput, error: timeError })}
                    value={timeInput}
                    onChange={changeTimeInput}
                    placeholder={timeFormat}
                    onBlur={parseTimeInput}
                    readOnly={readOnly}
                />
            </div>
            <FleetTimePicker
                className="picker-btn"
                value={timePicker}
                onChange={changeTimePicker}
                disabled={readOnly}
                minutes={15}
            />
        </div>
    );
}

export default DateTimeInput;