import * as toastr from 'toastr';
import React, { useState } from 'react';
import { useContext } from 'react';
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import { AppContext } from '../components/AppContext';
import apiService from '../util/api';
import { invoke } from '../util/helper';

import './PhoneConfirmModal.js.scss';

function PhoneConfirmModal({ phone, onClose }) {

    const { t } = useContext(AppContext);
    const [code, setCode] = useState('');

    const close = () => {
        invoke(onClose);
    }

    const save = async e => {
        e.preventDefault();

        if (!code) {
            console.log('No code.');
            return;
        }

        try {
            let resp = await apiService.confirmPhone({ phone, token: code });
            if (_.get(resp, 'data.code') === 0) {
                toastr.success(t('Settings.ConfirmPhoneSuccess'))
                close();
            } else {
                toastr.error(t('Settings.ConfirmPhoneError'));
            }
        } catch (error) {
            toastr.error(_.get(error, 'response.data.message', t('Settings.ConfirmPhoneError')));
        }
    }

    return (
        <Modal
            className="phone-confirm-modal"
            isOpen={true}
            autoFocus={false}
            centered
            size="sm"
            toggle={close}
            backdrop='static'
        >
            <ModalHeader>
                <span>{t('PhoneConfirmModal.Title')}</span>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={save}>
                    <p>{t('PhoneConfirmModal.Text')}</p>
                    <FormGroup>
                        <Label>{t('PhoneConfirmModal.Code')}</Label>
                        <div className="custom-control-container readonly">
                            <Input
                                value={code}
                                onChange={e => setCode(e.target.value)}
                                autoFocus={true}
                            />
                        </div>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="carshare"
                    onClick={save}
                    disabled={!code}
                >{t('Btn.Save')}</Button>
                <Button
                    color="outline-secondary"
                    onClick={close}
                >{t('Btn.Close')}</Button>
            </ModalFooter>
        </Modal>
    );
}

export default PhoneConfirmModal;