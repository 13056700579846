import React, { useContext } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { AppContext } from './AppContext';
import FormSection from './FormSection';

import './ConsentListComponent.js.scss';


const ConsentListComponent = ({ consents, type }) => {

    const { t } = useContext(AppContext);

    return (
        <>
            {type && consents && consents.length > 0 &&
                <FormSection title={t('Check.Consents')}>
                    <div className='consent-list'>
                        {consents.map(item => {
                            return (
                                <div key={`${item.key}_${item.id}_${_.get(type, 'value', '')}`} className='consent-item'>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                value={item.checked || false}
                                                onChange={o => { item.checked = o.target.checked; }}
                                            />
                                            {` ${item.text}`}
                                        </Label>
                                    </FormGroup>
                                </div>
                            );
                        })}
                    </div>
                </FormSection>
            }
        </>
    );
}

export default ConsentListComponent;
