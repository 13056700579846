import _ from 'lodash';
import * as toastr from 'toastr';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownMenu, Input } from 'reactstrap';

import DamageListComponent from '../check/DamageListComponent';
import { useDebounce } from '../../util/hooks';
import { AppContext } from '../AppContext';

import './DamagePage.scss';
import apiService from '../../util/api';
import DropdownInput from './DropdownInput';
import { distinct } from '../../util/array';
import PageForm from '../PageForm';


const MODE_ID = 'id';
const MODE_USER = 'user';

const DamagePage = () => {
    const location = useLocation();
    let { car: carId, driver: qName, email: qEmail } = parse(location.search.substring(1));

    const { t, user, setPageTitle } = useContext(AppContext);

    const regnumRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();

    const [regNumber, setRegNumber] = useState('');
    const [name, setName] = useState(qName || '');
    const [email, setEmail] = useState(qEmail || '');
    const [data, setData] = useState(null);
    const [mode, setMode] = useState(MODE_ID);
    const [dataLoading, setDataLoading] = useState(false);
    const [signal, setSignal] = useState(null);
    const [regNumOptions, setRegNumOptions] = useState([]);

    const debRegNum = useDebounce(regNumber, 500);

    useEffect(() => {
        setPageTitle(t('Damage.Title'));
        let ac = new AbortController();
        setSignal(ac.signal);
        if (carId) {
            loadDataFromId(carId, ac.signal);
        } else {
            setMode(MODE_USER);
        }
        return () => {
            ac.abort();
        };
    }, []);

    useEffect(() => {
        let ac = new AbortController();
        if (debRegNum && isUserMode()) {
            loadData(debRegNum, ac.signal);
        }
        return () => {
            ac.abort();
        };
    }, [debRegNum]);

    useEffect(() => {
        if (mode === MODE_USER) {
            regnumRef.current.focus();
        }
    }, [mode]);

    useEffect(() => {
        if (mode === MODE_USER && !!user) {
            let ac = new AbortController();
            loadRegNumOptions(ac.signal);
            return () => {
                ac.abort();
            };
        } else {
            setRegNumOptions([]);
        }
    }, [mode, user]);

    const loadRegNumOptions = async signal => {
        try {
            let resp = await apiService.getCarsForCheck(signal);
            if (!signal || !signal.aborted) {
                let regnums = distinct((resp.data || []).map(o => o.registrationNumber).filter(o => !!o)).sort();
                setRegNumOptions(regnums);
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
                setRegNumOptions([]);
            }
        } finally {
            if (!signal || !signal.aborted) {
            }
        }
    }

    const loadData = async (rz, signal) => {
        try {
            setDataLoading(true);
            let resp = await apiService.getCheckDataByRegNum({ rz }, signal);
            if (!signal || !signal.aborted) {
                setData(resp.data || null);
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
            }
        } finally {
            if (!signal || !signal.aborted) {
                setDataLoading(false);
            }
        }
    }

    const loadDataFromId = async (id, signal) => {
        try {
            setDataLoading(true);
            let resp = await apiService.getCheckDataById(id, signal);
            if (!signal || !signal.aborted) {
                setData(resp.data || null);
                if (!resp.data) {
                    setMode(MODE_USER);
                }
                setRegNumber(_.get(resp.data, 'registrationNumber', ''));
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
                toastr.error(t('Damage.DataNotLoadedErr'));
                setMode(MODE_USER);
            }
        } finally {
            if (!signal || !signal.aborted) {
                setDataLoading(false);
            }
        }
    }

    const reloadData = () => {
        if (data && data.id) {
            loadDataFromId(data.id, signal);
        }
    }

    const isUserMode = () => {
        let res = mode === MODE_USER;
        // console.log('isUserMode', res);
        return res;
    }

    return (
        <PageForm>
            <div className='section-container'>
                <div className='title'>
                    <span className="title-text">{t('Damage.Data')}</span>
                    {data && data.providerLogo &&
                        <div className="title-logo">
                            <img src={`api/img/${data.providerLogo}`} />
                        </div>
                    }
                </div>
                <div className='prop-list'>
                    <div className='labval-item'>
                        <label>
                            <span>{t('Damage.RegNum')}</span>
                            {dataLoading &&
                                <FontAwesomeIcon className='after-label-ico' icon='fa-solid fa-spinner' spinPulse={true} />
                            }
                        </label>
                        <DropdownInput
                            className='value custom-control-container x'
                            value={regNumber}
                            onChange={setRegNumber}
                            readOnly={!isUserMode()}
                            placeholder={isUserMode() ? t('Damage.RegNumPlaceholder') : ' '}
                            autoFocus={isUserMode()}
                            innerRef={regnumRef}
                            options={regNumOptions}
                        />
                    </div>
                </div>
                <div className='prop-list'>
                    <div className='labval-item'>
                        <label>{t('Damage.Name')}</label>
                        <div className='value custom-control-container'>
                            <Input
                                innerRef={nameRef}
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder={t('Damage.NamePlaceholder')}
                                autoComplete='name'
                            />
                        </div>
                    </div>
                    <div className='labval-item'>
                        <label>{t('Damage.Email')}</label>
                        <div className='value custom-control-container'>
                            <Input
                                innerRef={emailRef}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder={t('Damage.EmailPlaceholder')}
                                autoComplete='email'
                            />
                        </div>
                    </div>
                </div>
            </div>
            {data &&
                <DamageListComponent
                    damages={data.damages}
                    regNum={data.registrationNumber}
                    name={name}
                    email={email}
                    carId={data.id}
                    onChange={reloadData}
                    showDelimiter={false}
                    onNoName={() => nameRef.current.focus()}
                    onNoEmail={() => emailRef.current.focus()}
                />
            }
        </PageForm>
    );
}

export default DamagePage;