import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";


const GoogleMapCustomSimpleControl = ({ title, icon, className, onClick }) => {
    return (
        <button
            className={classNames('gmap-btn', className, { touch: true })}
            onClick={onClick}
            title={title}
        >
            <FontAwesomeIcon icon={icon} />
        </button>
    );
}

export default GoogleMapCustomSimpleControl;

export const GoogleMapCustomContentControl = ({ title, className, onClick, children }) => {
    return (
        <button
            className={classNames('gmap-btn', className, { touch: true })}
            onClick={onClick}
            title={title}
        >
            {children}
        </button>
    );
}