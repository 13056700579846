export const ApplicationName = 'Xmarton.Carsharing';

export const QueryParameterNames = {
  ReturnUrl: 'returnUrl',
  Message: 'message'
};

export const LogoutActions = {
  LogoutCallback: 'logout-callback',
  Logout: 'logout',
  LoggedOut: 'logged-out'
};

export const LoginActions = {
  Login: 'login',
  LoginCallback: 'login-callback',
  LoginFailed: 'login-failed',
  Profile: 'profile',
  Register: 'register'
};

const prefix = '/authentication';

export const ApplicationPaths = {
  DefaultLoginRedirectPath: '/',
  ApiAuthorizationClientConfigurationUrl: `_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: prefix,
  Login: `${prefix}/${LoginActions.Login}`,
  LoginFailed: `${prefix}/${LoginActions.LoginFailed}`,
  LoginCallback: `${prefix}/${LoginActions.LoginCallback}`,
  Register: `${prefix}/${LoginActions.Register}`,
  Profile: `${prefix}/${LoginActions.Profile}`,
  LogOut: `${prefix}/${LogoutActions.Logout}`,
  LoggedOut: `${prefix}/${LogoutActions.LoggedOut}`,
  LogOutCallback: `${prefix}/${LogoutActions.LogoutCallback}`,
  IdentityRegisterPath: 'Identity/Account/Register',
  IdentityManagePath: 'Identity/Account/Manage'
};

export const getRedirectUrl = (actionPath, returnPath) => {
    var link = document.createElement("a");
    link.href = returnPath || '/';
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${actionPath}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    //console.log('dev', 'redirectUrl', redirectUrl);
    return redirectUrl;
}

export const getLoginUrl = path => {
    return getRedirectUrl(ApplicationPaths.Login, path || `${window.location.pathname}${window.location.search}`)
}

export const getLogoutUrl = path => {
    return getRedirectUrl(ApplicationPaths.LogOut, path || `${window.location.pathname}${window.location.search}`)
}