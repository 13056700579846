import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useRef } from 'react';


const PhotoListItem = ({ value, uploadOnClick, onClick, onUpload }) => {

    const inputRef = useRef(null);

    const invokeClick = () => {
        if (onClick) {
            onClick(value);
        }
    }

    const invokeUpload = file => {
        if (onUpload) {
            onUpload(value, file);
        }
    }

    const click = () => {
        if (uploadOnClick) {
            if (inputRef.current) {
                inputRef.current.click();
                inputRef.current.value = null;
            }
        } else {
            invokeClick();
        }
    }

    const loadFile = e => {
        let files = e.target.files || e.dataTransfer.files;
        if (files && files[0]) {
            invokeUpload(files[0]);
        }
    }

    const stopPropagation = e => {
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
    }

    const getSrc = () => {
        return value.imgData || value.imgDataUrl;
    }

    return (
        <div
            className='photo-item'
            onClick={click}
        >
            <div className='photo-item-img-container'>
                {getSrc() ? (
                    <img src={getSrc()} />
                ) : (
                    <FontAwesomeIcon className='no-img' icon='fa-solid fa-image' />
                )}
            </div>
            <div className='info-container'>
                <div className='info-title'>{value.name}</div>
                <div className='info-description'>{value.description}</div>
            </div>
            <div className="plus-container">
                <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
            </div>
            <input
                type='file'
                ref={inputRef}
                accept='image/*'
                capture='environment'
                onChange={loadFile}
                onClick={stopPropagation}
            />
        </div>
    );
}

export default PhotoListItem;