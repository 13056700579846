import React, { useContext, useEffect } from 'react';
import { AppContext } from './AppContext';


const AuthView = ({ children }) => {

    const { t, user, invokeLogin } = useContext(AppContext);

    useEffect(() => {
        if (user === null) {
            //console.log('dev', 'jupi');
            invokeLogin();
        }
    }, [user]);

    return (
        <>
            {!!user && children || ''}
        </>
    );
}

export default AuthView;