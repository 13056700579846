import Intl from 'intl';
import { isNullOrUndef } from './helper';

import 'intl/locale-data/jsonp/cs';

// tenhle p�evod je tak mysl�m naschv�l: Radek cht�l, aby se i ciz� mutace zobrazovaly v �esk�m form�tu
export const formatPrice = (price, currency) => {
    if (isNullOrUndef(price))
        return '';

    let opt = {
        minimumFractionDigits: 0,
        maximumFractionDigits: currency === 'CZK' ? 2 : 3
    };
    if (currency) {
        opt.style = 'currency';
        opt.currency = currency;
    }
    let intl = new Intl.NumberFormat('cs', opt);
    let res = intl.format(price);
    return res;
}