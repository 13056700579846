import * as _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { updatei18nResources } from "../i18n/i18n";
import apiService from "./api";

export function useApiConfig() {

    let { pathname } = useLocation();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadConfig = async signal => {
            try {
                let resp = await apiService.getClientConfig(signal);
                if (!signal || !signal.aborted) {
                    let config = resp.data || {};
                    let pathConfig = getPathConfig(config, pathname);
                    if (pathConfig) {
                        apiService.idProvider = _.get(pathConfig, 'providers', undefined);
                        updatei18nResources([pathConfig.key, pathConfig.vehicle]);
                    }
                    setData(config);
                }
            } catch (error) {
                console.error(error);
                if (!signal || !signal.aborted) {
                    setError(error);
                }
            } finally {
                if (!signal || !signal.aborted) {
                    setLoading(false);
                }
            }
        }

        let ac = new AbortController();
        loadConfig(ac.signal);
        return () => {
            ac.abort();
        };
    }, []);


    return { config: data, configLoading: loading, configError: error };
}

export function getPathConfig(config, pathname) {
    let res = null;
    if (config && config.pathMaps) {
        res = _.find(config.pathMaps, o => (pathname + '/').startsWith(o.url + '/'));
        if (!res) {
            res = _.find(config.pathMaps, o => o.url === '/');
        }
    }    
    return res;
}