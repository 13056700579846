import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import './Loader.scss';


const Loader = ({ text, subtext, delay }) => {

    const [show, setShow] = useState(!delay);

    useEffect(() => {
        if (delay) {
            let t = window.setTimeout(() => {
                setShow(true);
            }, delay);
            return () => window.clearTimeout(t);
        }
    }, []);

    return (
        <div className="loader-container">
            {show &&
                <div className="loader">
                    <div className="loader-img">
                        <FontAwesomeIcon icon="fa-solid fa-spinner" spin />
                    </div>
                    <div className="loader-texts">
                        <div className="primary-text">{text || ''}</div>
                        {subtext &&
                            <div className="secondary-text">{subtext || ''}</div>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default Loader;