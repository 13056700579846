import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './Error.scss';


const Error = ({ text }) => {

    return (
        <div className="error-container">
            <div className="error">
                <div className="error-img">
                    <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />
                </div>
                <div className="error-text">{text || ''}</div>
            </div>
        </div>
    );
}

export default Error;