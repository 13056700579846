const en = {
    "Carshare": {
        "NoCar": "There is currently no free bike to rent"
    },
    "Contacts": {
        "Manuals": {
            "Main": "Bikesharig",
            "MainFile": "xmarton_bikesharing_manual_cs.pdf",
            "MainName": "Xmarton Bikesharing - Návod.pdf.pdf"
        }
    },
    "Damage": {
        "DataNotLoadedErr": "Failed to load bike data.",
        "FindCar": "Find a bike",
        "NoDataErr": "Non-existent or unauthorized bike.",
        "Title": "BIKE SHORTCOMINGS"
    },
    "Damages": {
        "NoDamages": "There are no defects reported on the bike."
    },
    "FinishSettingsRequiredModal": {
        "ReqIdentity": "Please verify your identity before reserving a bicycle.",
        "ReqPhone": "Please add a contact phone number before reserving a bicycle.",
        "ReqPhoneAndIdentity": "Before reserving a bicycle, please add a contact phone number and verify your identity."
    },
    "Handover": {
        "Name": "Bike",
        "Place": "Place",
        "ReturnTitle": "BIKE RETURN",
        "SuccessReturnTitle": "The bike has been successfully returned",
        "SuccessTakeoverTitle": "The bike has been successfully collected",
        "TakeoverTitle": "TAKEOVER THE BIKE"
    },
    "Check": {
        "DataNotLoadedErr": "Failed to load bike data.",
        "FindCar": "Find a bike",
        "NoDataErr": "Non-existent or unauthorized bike.",
        "ReturnBtn": "Return bike",
        "ReturnDone": "Bike has been successfully returned.",
        "TakeDone": "Bike has been successfully taken.",
        "TakeoverBtn": "Pick up Bike",
        "Title": "BIKE HANDOVER"
    },
    "Nav": {
        "Title": "BIKES FOR RENTAL"
    },
    "Rent": {
        "Title": "Bike rental"
    },
    "Reservation": {
        "Car": "Bike",
        "CarNotAvaibleForPeriod": "The bike is not free until the selected period",
        "CarPlaceholder": "Chose bike",
        "LastCarForPeriod": "The last round is free in the selected period",
        "NoCarForModelPeriod": "The given bike model is not available in the selected period",
        "NoCarForPeriod": "There is no free round in the selected period",
        "Parking": "Place",
        "PickUpTooltip": "It will be possible to take over the bicycle only at the time of booking.",
        "RepeteAfter": "After ordering, prepare the reservation of another bicycle",
        "Title": "Bicycle Reservation"
    },
    "ReservationState": {
        "ReadyToTake": "You can take over the bike",
        "Taken": "Bike borrowed"
    },
    "ReservList": {
        "LoadCarDetailError": "Failed to load bike detail.",
        "Return": "Return the bike",
        "Takeover": "Pick up the bike"
    }
}

export default en;