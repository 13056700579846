import * as _ from "lodash";
import { RS_APPROVED, RS_CANCELED, RS_CREATED, RS_FINISHED, RS_REJECTED } from "../constants/reservationState";
import { TS_READY_TO_RETURN, TS_READY_TO_TAKE, TS_RETURNED } from "../constants/takeoverStatus";
import { isNullOrUndef } from "./helper";

export const RSK_WAITFORPAY = 'WaitForPay';

export function getStateTranslationKey(reservation) {
    let state = _.get(reservation, 'state', '');
    let takeoverStatus = _.get(reservation, 'takeoverStatus', '');
    let payment = !isNullOrUndef(_.get(reservation, 'price'));

    switch (state) {
        case RS_CREATED:
            return payment ? RSK_WAITFORPAY : 'New';
        case RS_APPROVED:
            switch (takeoverStatus) {
                case '':
                    return payment ? 'Paid' : 'Approved';
                case TS_READY_TO_TAKE:
                    return 'ReadyToTake';
                case TS_READY_TO_RETURN:
                    return 'Taken'
                case TS_RETURNED:
                    return 'Returned';
                default:
                    return 'Unknown';
            }
        case RS_FINISHED:
            return 'Finished';
        case RS_REJECTED:
            return 'Rejected';
        case RS_CANCELED:
            return 'Canceled';
        default:
            return 'Unknown';
    }
}

export function canPay(reservation) {
    //console.log('dev', 'canPay', reservation);
    return getStateTranslationKey(reservation) === RSK_WAITFORPAY;
}