import * as toastr from 'toastr';
import React from 'react';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import DamageListItem from './DamageListItem';

import './DamageListComponent.scss';
import { useState } from 'react';
import NewDamageModal from './NewDamageModal';
import { invoke } from '../../util/helper';


const DamageListComponent = ({ damages, regNum, name, email, carId, onChange, onNoName, onNoEmail }) => {

    const { t } = useContext(AppContext);

    const [newOpen, setNewOpen] = useState(null);
    const [newData, setNewData] = useState(null);

    const add = () => {
        if (!regNum) {
            toastr.error(t('Damages.NoRegNum'));
            return;
        }
        if (!name) {
            toastr.error(t('Damages.NoNameEmail'));
            invoke(onNoName);
            return;
        }
        if (!email) {
            toastr.error(t('Damages.NoNameEmail'));
            invoke(onNoEmail);
            return;
        }
        
        openNewDamage();
    }

    const openNewDamage = () => {
        setNewData({ regNum, name, email, carId });
        setNewOpen(new Date().getTime());
    }

    const closeNewDamage = () => {
        setNewOpen(null);
        setNewData(null);
    }

    const newDamageSubmit = () => {
        closeNewDamage();
        invoke(onChange);
    }

    return (
        <>
            <div className='section-container with-delimiter'>
                <div className='title'>{t('Damages.Title')}</div>
                {damages && damages.length ? (
                    <div className='prop-list'>
                        {damages.map(item => (
                            <DamageListItem
                                key={item.id}
                                data={item}
                            />
                        ))}
                    </div>
                ) : (
                    <div className='info-msg'>{t('Damages.NoDamages')}</div>
                )}
                <div className='btn-part'>
                    <button
                        className={'damage-btn'}
                        onClick={add}
                    >{t('Damages.NewBtn')}</button>
                </div>
            </div>
            {newOpen &&
                <NewDamageModal
                    key={newOpen}
                    data={newData}
                    onCancel={closeNewDamage}
                    onSubmit={newDamageSubmit}
                />
            }
        </>
    );
}

export default DamageListComponent;