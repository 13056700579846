import * as _ from "lodash";

export const OP_TEST = 0;
export const OP_DEMO = 1;
export const OP_XMARTON = 2;
export const OP_SKODA = 3;
export const OP_BIKE = 4;
export const OP_BIKESK = 5;

export const BOUND_PRAHA = { east: 14.706727, north: 50.177352, south: 49.941969, west: 14.224600 };
export const BOUND_CR = { east: 18.855530, north: 51.052919, south: 48.553687, west: 12.091635 };
export const BOUND_SR = { east: 22.566216, north: 49.613926, south: 47.729864, west: 16.833290 };
export const BOUND_CRSR = { east: 22.566216, north: 51.052919, south: 47.729864, west: 12.091635 };
export const BOUND_WESTSR = { east: 19.922334, north: 49.613926, south: 47.729864, west: 16.833290 };

export const VEH_CAR = 'car';
export const VEH_BIKE = 'bike';

export const MT_DEFAULT = 'default';
export const MT_XMARTON = 'xmarton';

export const Operators = [
    {
        type: OP_TEST,
        key: 'test',
        url: '/test',
        pageName: 'Carsharing test',
        filterName: '',
        bound: BOUND_PRAHA,
        vehicle: VEH_CAR,
        map: MT_XMARTON
    },
    {
        type: OP_DEMO,
        key: 'demo',
        url: '/demo',
        pageName: 'Carsharing',
        filterName: '',
        bound: BOUND_CR,
        vehicle: VEH_CAR,
        map: MT_XMARTON
    },
    {
        type: OP_XMARTON,
        key: 'xmarton',
        url: '/xmarton',
        pageName: 'Xmarton Carsharing',
        filterName: '',
        bound: BOUND_PRAHA,
        vehicle: VEH_CAR,
        map: MT_XMARTON
    },
    {
        type: OP_SKODA,
        key: 'skoda',
        url: '/skoda',
        pageName: 'ŠKODA AUTO CarShare',
        filterName: 'ŠKODA',
        bound: BOUND_SR,
        vehicle: VEH_CAR,
        map: MT_XMARTON
    },
    {
        type: OP_SKODA,
        key: 'sk',
        url: '/sk',
        pageName: 'Carsharing',
        filterName: '',
        bound: BOUND_WESTSR,
        vehicle: VEH_CAR,
        map: MT_XMARTON
    },
    {
        type: OP_BIKE,
        key: 'bike',
        url: '/bike',
        pageName: 'Bikesharing',
        filterName: '',
        bound: BOUND_CR,
        vehicle: VEH_BIKE,
        map: MT_DEFAULT
    }
];

export const getOperator = type => {
    return _.find(Operators, { type });
}

export const getOperatorByPathname = pathname => {
    return _.find(Operators, o => (pathname + '/').startsWith(o.url + '/'));;
}
