import _ from 'lodash';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Select from 'react-select';
import * as toastr from 'toastr';

import { AppContext } from '../AppContext';
import { selectStyle } from '../../style/react-select';
import apiService from '../../util/api';
import Delimiter from '../Delimiter';
import PhotoInput from './PhotosInput';
import { useRef } from 'react';
import Selectbox from '../selectbox/Selectbox';


const NewDamageModal = ({ data, onCancel, onSubmit }) => {

    const { t } = useContext(AppContext);

    const categoryRef = useRef();
    const descRef = useRef();

    const [regNum, setRegNum] = useState(_.get(data, 'regNum', ''));
    const [category, setCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [signal, setSignal] = useState(null);
    const [description, setDescription] = useState('');
    const [insEvent, setInsEvent] = useState('');
    const [photos, setPhotos] = useState([]);
    const [dataSending, setDataSending] = useState(false);

    useEffect(() => {
        let ac = new AbortController();
        setSignal(ac.signal);
        loadCategories(ac.signal);
        return () => {
            ac.abort();
        };
    }, []);

    const loadCategories = async signal => {
        try {
            setDataLoading(true);
            let resp = await apiService.getDamageCategoryCodelist(signal);
            if (!signal || !signal.aborted) {
                let categories = (resp.data || []).map(o => ({ ...o, label: o.desc }));
                setCategories(categories);
                if (!category && categories.length) {
                    setCategory(categories[0]);
                }
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
            }
        } finally {
            if (!signal || !signal.aborted) {
                setDataLoading(false);
            }
        }
    }

    const cancel = () => {
        invokeCancel();
    }

    const invokeCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const invokeSubmit = () => {
        if (onSubmit) {
            onSubmit();
        }
    }

    const save = async () => {
        try {
            setDataSending(true);
            let input = await validateAndGetDataToSend();
            if (input) {
                let resp = await apiService.addDamage(data.carId, input, signal);
                if (!signal || !signal.aborted) {
                    toastr.success(t('NewDamage.DataSaved'));
                    invokeSubmit();
                }
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
                toastr.error(_.get(error, 'response.data.message') || t('NewDamage.DataSaveErr'));
            }
        } finally {
            if (!signal || !signal.aborted) {
                setDataSending(false);
            }
        }
    }

    const validateAndGetDataToSend = async () => {
        let res = new FormData();

        if (!description) {
            toastr.error(t('NewDamage.DescriptionRequired'));
            descRef.current.focus();
            return null;
        }

        res.append('email', _.get(data, 'email', ''));
        res.append('name', _.get(data, 'name', ''));
        res.append('insEvent', insEvent);
        res.append('description', description);
        res.append('category', _.get(category, 'value', ''));

        let p = photos || [];
        if (photos.length) {
            p.forEach(o => {
                res.append('photos', o.file);
            });
        } else {
            toastr.error(t('NewDamage.PhotoRequired'));
            return null;
        }

        return res;
    }

    return (
        <>
            <Modal
                className="new-damage-modal"
                isOpen={true}
                autoFocus={false}
                toggle={cancel}
                backdrop='static'
                >
                <ModalHeader toggle={cancel}>
                    <span>{t('NewDamage.Title')}</span>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>{t('NewDamage.RegNum')}</Label>
                            <div className="custom-control-container readonly">
                                <Input
                                    // className="note-text"
                                    value={regNum}
                                    onChange={() => { }}
                                    readOnly={true}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('NewDamage.Category')}</Label>
                            <Selectbox
                                className="cs-form-select"
                                options={categories}
                                value={category}
                                onChange={setCategory}
                                ref={categoryRef}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('NewDamage.Description')}</Label>
                            <div className="custom-control-container">
                                <Input
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                    autoFocus={true}
                                    innerRef={descRef}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('NewDamage.Event')}</Label>
                            <div className="custom-control-container">
                                <Input
                                    value={insEvent}
                                    onChange={e => setInsEvent(e.target.value)}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>{t('NewDamage.Photo')}</Label>
                            <PhotoInput
                                value={photos}
                                onChange={setPhotos}
                                maxSize={15}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <Delimiter />
                <ModalFooter>
                    <Button
                        color="carshare"
                        onClick={save}
                    // disabled={!isValid}
                    >{t('Btn.Send')}</Button>
                    <Button
                        color="outline-secondary"
                        onClick={cancel}
                    >{t('Btn.Cancel')}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default NewDamageModal;
