import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { AppContext } from '../AppContext';
import { compressImg } from '../../util/resizer';
import PhotoListItem from './PhotoListItem';


const PhotoListComponent = ({ photos, onChange, useCustomCapture, showDelimiter }) => {

    const { t } = useContext(AppContext);

    const [showTitleLoading, setShowTitleLoading] = useState(false);
    const [photoCapture, setPhotoCapture] = useState({ key: '', photo: null });

    const invokeChange = value => {
        if (onChange) {
            onChange(value);
        }
    }

    const itemClick = item => {
        if (useCustomCapture) {
            setPhotoCapture({ key: new Date().getTime(), photo: item });
        } else {

        }
    }

    const closePhotoCapture = () => {
        setPhotoCapture({ key: '', photo: null });
    }

    const savePhoto = photo => {
        let newPhotos = photos.map(o => o.id === photo.id ? { ...photo } : o);
        invokeChange(newPhotos);
        closePhotoCapture();
    }

    const upload = async (photo, file) => {
        let compressedFile = await compressImg(file);
        let newPhotos = photos.map(o => o.id === photo.id
            ? { ...photo, imgDataUrl: URL.createObjectURL(compressedFile), imgFile: compressedFile }
            : o);
        invokeChange(newPhotos);
    }

    return (
        <>
            {photos && photos.length > 0 &&
                <div className={classNames('section-container', { 'with-delimiter': showDelimiter !== false })}>
                    <div className='title'>
                        <span>{t('Check.Photos')}</span>
                        {showTitleLoading &&
                            <FontAwesomeIcon className='after-title-ico' icon='fa-solid fa-spinner' spinPulse={true} />
                        }
                    </div>
                    <div className='prop-list'>
                        {photos.map(item => (
                            <PhotoListItem
                                key={item.id}
                                value={item}
                                onClick={itemClick}
                                uploadOnClick={true}
                                onUpload={upload}
                            />
                        ))}
                    </div>
                </div>
            }
            {photoCapture && photoCapture.key &&
                <PhotoCaptureModal
                    {...photoCapture}
                    onCancel={closePhotoCapture}
                    onSubmit={savePhoto}
                />
            }
        </>
    );
}

export default PhotoListComponent;