import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';

import './ContextMenu.scss';


const ContextMenu = ({ children, mousePosition, onClose }) => {

    const wrapperRef = useRef();

    const [wrapperStyle, setWrapperStyle] = useState({ top: 0, left: 0 })

    useEffect(() => {
        document.addEventListener('keydown', onEscClose, false);
        document.addEventListener('mousedown', handleClickOutside);
        setBoxPosition();
        return () => {
            document.removeEventListener('keydown', onEscClose);
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, []);

    const handleClickOutside = e => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
            invodeClose();
        }
    }

    const invodeClose = () => {
        if (onClose) {
            onClose();
        }
    }

    const onEscClose = e => {
        e = e || window.event;

        if (e.keyCode == 27) {
            invokeClose();
        }
    }

    const setBoxPosition = () => {
        let contextBox = wrapperRef.current;
        let maxWidth = window.innerWidth;
        let maxHeight = window.innerHeight;
        let offset = 20;

        let width = contextBox.offsetWidth;
        let height = contextBox.offsetHeight;

        let mousePos = {
            x: 0,
            y: 0
        };

        mousePos = mousePosition;

        if (mousePos.x + width > maxWidth) {
            mousePos.x = maxWidth - width - offset;
        }

        if (mousePos.y + height > maxHeight) {
            mousePos.y = maxHeight - height - offset;
        }

        contextBox.style.left = `${mousePos.x + 5}px`;
        contextBox.style.top = `${mousePos.y + 5}px`;
    }

    return (
        <div
            className="contextmenu-box"
            onContextMenu={e => e.preventDefault()}
            style={wrapperStyle}
            ref={wrapperRef}
        >
            {children}
        </div>
    );
}

export default ContextMenu;