import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './CheckInput.scss';

const CheckInput = ({ className, value, onClick }) => {

    const [checked, setChecked] = useState(value || false);

    useEffect(() => {
        setChecked(value);
    }, [value]);

    return (
        <div
            className={classnames('check-input', className, { checked })}
            onClick={e => {
                if (onClick) {
                    onClick(e, checked);
                }
            }}
        >
            <div className="circle"></div>
            <div className="check">
                <FontAwesomeIcon icon="fa-solid fa-check" color='#469408' />
            </div>
        </div>
    );
}

export default CheckInput;