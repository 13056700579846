import * as _ from 'lodash';
import * as toastr from 'toastr';
import * as moment from 'moment';
import * as path from 'path';
import * as classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import apiService from '../../util/api';
import Delimiter from './Delimiter';
import { invoke, joinNullable } from '../../util/helper';
import { AppContext } from '../AppContext';
import { TS_READY_TO_RETURN, TS_READY_TO_TAKE } from '../../constants/takeoverStatus';
import { RS_APPROVED, RS_CANCELED, RS_CREATED, RS_FINISHED } from '../../constants/reservationState';
import CheckInput from './CheckInput';
import { canPay, getStateTranslationKey, RSK_WAITFORPAY } from '../../util/reservation';
import { CT_1, CT_2, CT_3, CT_4, CT_5 } from '../../constants/controlType';
import { Fa5FadCalendarAlt } from '../fontawesome5/fa5-custom';

import './ReservList.scss';
import { VT_PARKING } from '../../constants/vehicleType';
import { canCancelReservation, canFinishReservation } from './ReservationModal';
import { TakeoverInfoModal } from './TakeoverInfoModal';


const Item = ({ data, /*time, */onDetailClick, onNameClick, onChanged, onPay }) => {

    const { t, pathConfig, lang } = useContext(AppContext);
    const { push } = useHistory();
    const [takeMod, setTakeMod] = useState(false);

    const { vehicle: brandConfigVehicle } = pathConfig || {};
    const { takeoverStatus } = data || {};

    const handleDetailClick = useCallback(async () => {
        let ac = new AbortController();
        let { signal } = ac;
        try {
            let resp = await apiService.getCarDetail(data.idCar, { idProvider: data.idProvider }, signal);
            if (!signal || !signal.aborted) {
                if (resp.data) {
                    let res = {
                        parking: {
                            id: resp.data.idParkingPlace,
                            name: resp.data.parkingPlaceName
                        },
                        car: {
                            id: resp.data.id,
                            name: resp.data.carName,
                            model: {
                                id: resp.data.idModel,
                                name: resp.data.modelName
                            }
                        }
                    };
                    invoke(onDetailClick, res);
                } else {
                    console.error('No car detail data.');
                    toastr.error(t('ReservList.LoadCarDetailError'));
                }
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.error(error);
                toastr.error(t('ReservList.LoadCarDetailError'));
            }
        } finally {
            if (!signal || !signal.aborted) {
            }
        }
        return () => {
            ac.abort();
        }
    }, [data, onDetailClick]);

    const handleReservNameClick = useCallback(async () => {
        let parking = _.get(data, 'idParkingPlace');
        if (!!parking) {
            invoke(onNameClick, parking);
        }
    }, [data]);

    const unlockStand = useCallback(async () => {
        if (isUnlockStandBtnVisible()) {
            try {
                let type = takeoverStatus === TS_READY_TO_RETURN ? 'return' : 'takeover';
                let hdResp = await apiService.getHandoverData(data.carGuid, { type });
                let idStand = _.get(hdResp, 'data.stands[0].id');
                if (idStand) {
                    try {
                        await apiService.unlockStand(data.carGuid, { idStand });
                        toastr.success(t('Handover.UnlockSuccess'));
                    } catch (error) {
                        console.error('Error unlock stand:', error);
                        toastr.error(_.get(error, 'response.data.message', t('Handover.UnlockError')));
                    }
                } else {
                    console.error('No idStand.', JSON.stringify(hdResp));
                    toastr.error(t('Handover.UnlockError'));
                }
            } catch (error) {
                console.log('Error get handover data:', error);
                toastr.error(t('Handover.UnlockError'));
            }
        }
    });

    const takeCar = useCallback(async () => {
        if (isTakeoverBtnVisible()) {
            switch (data.controlType) {
                case CT_1:
                    setTakeMod(true);
                    break;
                case CT_2:
                case CT_3:
                case CT_4:
                case CT_5:
                    switch (takeoverStatus) {
                        case TS_READY_TO_TAKE:
                        case TS_READY_TO_RETURN:
                            let type = takeoverStatus === TS_READY_TO_RETURN ? 'return' : 'takeover';
                            push(path.join(_.get(pathConfig, 'url', '/'), `handover?car=${data.carGuid}&type=${type}`));
                            break;
                        default:
                            toastr.warning(t('Reservation.PickUpTooltip'));
                            break;
                    }
                    break;
                default:
                    console.log(t('ReservList.UnknownControl'), data.controlType);
                    toastr.warning(t('ReservList.UnknownControl'));
                    break;
            }
        }
    }, [data]);

    const onCancelReservation = async () => {
        if (canCancelReservation(data)) {
            var confirmation = await Swal.fire({
                title: t('Reservation.Cancel.Confirm.Title'),
                text: t('Reservation.Cancel.Confirm.Question'),
                confirmButtonText: t('Btn.Yes'),
                cancelButtonText: t('Btn.No'),
                showCancelButton: true
            });
            if (confirmation.isConfirmed) {
                try {
                    let { id, idCar, timeFrom, timeTo } = data;
                    let resp = await apiService.updateReservation(id, { idCar, timeFrom, timeTo, state: RS_CANCELED });
                    console.log('cancelReservation success', JSON.stringify(resp.data));
                    toastr.success(t('Reservation.Cancel.Success'));
                    invoke(onChanged);
                } catch (error) {
                    console.error(_.get(error, 'response.data') ? JSON.stringify(_.get(error, 'response.data')) : error);
                    toastr.error(_.get(error, 'response.data.message', t('Reservation.Cancel.Error')));
                }
            }
        } else {
            console.log('Reservation cannot be canceled.');
        }
    };

    const onFinishReservation = async () => {
        if (canFinishReservation(data)) {
            var confirmation = await Swal.fire({
                title: t('Reservation.Finish.Confirm.Title'),
                text: t('Reservation.Finish.Confirm.Question'),
                confirmButtonText: t('Btn.Yes'),
                cancelButtonText: t('Btn.No'),
                showCancelButton: true
            });
            if (confirmation.isConfirmed) {
                try {
                    let { id, idCar, timeFrom, timeTo } = data;
                    let resp = await apiService.updateReservation(id, { idCar, timeFrom, timeTo, state: RS_FINISHED });
                    console.log('finishReservation success', JSON.stringify(resp.data));
                    toastr.success(t('Reservation.Finish.Success'));
                    invoke(onChanged);
                } catch (error) {
                    console.error(_.get(error, 'response.data') ? JSON.stringify(_.get(error, 'response.data')) : error);
                    toastr.error(_.get(error, 'response.data.message', t('Reservation.Finish.Error')));
                }
            }
        } else {
            console.log('Reservation cannot be finished.');
        }
    }

    const onPayClick = () => {
        invoke(onPay, data);
    }

    //const isTakeoverDisabled = () => {
    //    if (!data || !data.timeFrom) {
    //        return false;
    //    }

    //    return time.isBefore(moment(data.timeFrom * 1000));
    //}
    //const takeoverDisabled = isTakeoverDisabled();

    const getVehicleName = () => {
        let carName = _.get(data, 'carName', '');
        let regNum = _.get(data, 'registrationNumber', '');
        let res = joinNullable(' - ', carName, regNum) || '<noname>';
        return res;
    }

    const getState = data => {
        let key = getStateTranslationKey(data);
        return key && t(`ReservationState.${key}`);
    }

    const getTakeoverBtnText = () => {
        let key = 'ReservList.Takeover';
        if (takeoverStatus === TS_READY_TO_RETURN) {
            key = 'ReservList.Return';
            if (_.get(data, 'controlType') === CT_3) {
                key = 'ReservList.Unlock';
            }
        }
        return t(key);
    }

    const isTakeoverBtnVisible = () => {
        return data
            && data.state === RS_APPROVED
            && (!takeoverStatus || takeoverStatus === TS_READY_TO_TAKE || takeoverStatus === TS_READY_TO_RETURN)
            && brandConfigVehicle !== VT_PARKING
    }

    const isUnlockStandBtnVisible = () => {
        return data
            && data.state === RS_APPROVED
            && ([CT_2, CT_3, CT_4, CT_5].includes(data.controlType))
            && brandConfigVehicle === VT_PARKING;
    }

    const showCancelReservationBtn = brandConfigVehicle === VT_PARKING && canCancelReservation(data);
    const showFinishReservationBtn = brandConfigVehicle === VT_PARKING && canFinishReservation(data);

    //console.log('dev', 'ReservationItem', { showFinishReservationBtn });

    return (
        <div className="reserv-list-item">
            {data && !!data.idParkingPlace &&
                <div
                    className={classNames('name', { active: !!_.get(data, 'idParkingPlace') })}
                    onClick={handleReservNameClick}
                >
                    <span><FontAwesomeIcon icon="fa-solid fa-location-dot" /></span>
                    <span>{_.get(data, 'parkingPlaceName', t('ReservList.NoParking'))}</span>
                </div>
            }
            <div className="description">
                {data && data.modelExternalHash &&
                    <div className="img-container"><img src={`/api/img/${data.modelExternalHash}`} /></div>
                }
                <div className="text">{getVehicleName()}</div>
            </div>
            <div className="time-container">
                <div className="ico-container"><Fa5FadCalendarAlt /></div>
                <div className="table-container">
                    <table>
                        <tbody>
                            <tr>
                                <td>{data && data.timeFrom ? moment(data.timeFrom * 1000).format('dd D. M. YYYY') : ''}</td>
                                <td>{data && data.timeFrom ? moment(data.timeFrom * 1000).format('HH:mm') : ''}</td>
                            </tr>
                            <tr>
                                <td>{data && data.timeTo ? moment(data.timeTo * 1000).format('dd D. M. YYYY') : ''}</td>
                                <td>{data && data.timeTo ? moment(data.timeTo * 1000).format('HH:mm') : ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={classNames('state-container', { ready: data.state === RS_CREATED, good: data.state === RS_APPROVED })}>
                <div className="state-ico">
                    <FontAwesomeIcon icon="fa-solid fa-circle" />
                </div>
                <div className="state-text">{getState(data)}</div>
            </div>
            <div className="buttons">
                {canPay(data) &&
                    <Button
                        color="carshare"
                        onClick={onPayClick}
                    >{t('Btn.Pay')}</Button>
                }
                <Button
                    color="carshare"
                    onClick={() => invoke(onDetailClick, { reservation: data })}
                >{t('ReservList.Detail')}</Button>
                {isTakeoverBtnVisible() &&
                    <Button
                        color="carshare"
                        onClick={takeCar}
                    >{getTakeoverBtnText()}</Button>
                }
                {isUnlockStandBtnVisible() &&
                    <Button
                        color="carshare"
                        onClick={unlockStand}
                    >{t('ReservList.Takeover')}</Button>
                }
                {showCancelReservationBtn &&
                    <Button
                        color="carshare"
                        onClick={onCancelReservation}
                    >{t('Reservation.Cancel.Btn')}</Button>
                }
                {showFinishReservationBtn &&
                    <Button
                        color="outline-secondary"
                        onClick={onFinishReservation}
                    >{t('Reservation.Finish.Btn')}</Button>
                }
            </div>
            <Delimiter />
            {takeMod &&
                <TakeoverInfoModal onClose={() => setTakeMod(false)} />
            }
        </div>
    );
}

const ReservList = ({ items, all, onCarDetailClick, onCarNameClick, onAllClick, onItemChanged, onPay }) => {

    const { t, user, invokeLogin } = useContext(AppContext);

    const [time, setTime] = useState(moment());

    useEffect(() => {
        let int = setInterval(() => {
            //setTime(moment());
        }, 1000);
        return () => { clearInterval(int); };
    }, []);

    // pokus o inteligentní řazení, ale Radek rozhodl, že se bude řadit jen sestupně (sežazeno již při načtení dat)
    //let first = (items || []).filter(o => time.isSameOrBefore(moment(o.timeTo * 1000)));
    //let second = (items || []).filter(o => time.isAfter(moment(o.timeTo * 1000)));
    //let concat = [...first, ...second];
    let concat = [...(items || [])];

    //console.log('dev', 'render ReservList');

    return (
        <div className="reserv-list-container">
            <div className="reserv-list-header">
                <div className="title">{t('ReservList.Title')}</div>
                <div
                    className="check-all"
                    onClick={() => invoke(onAllClick, all)}
                >
                    <span className="all-text">{t('Filter.All')}</span>
                    <CheckInput value={all} />
                </div>
            </div>
            <Delimiter />
            {user && !!concat.length &&
                <div className="reserv-list-list">
                    {concat.map((o, i) => (
                        <Item
                            key={i}
                            data={o}
                            //time={time}
                            onDetailClick={c => invoke(onCarDetailClick, c)}
                            onNameClick={(...args) => invoke(onCarNameClick, ...args)}
                            onChanged={onItemChanged}
                            onPay={onPay}
                        />
                    ))}
                </div>
            }
            {user && !concat.length &&
                <div className="reserv-list-list some-info">
                    <div className="text-container">{t('ReservList.NoReserv')}</div>
                </div>
            }
            {!user &&
                <div className="reserv-list-list some-info">
                    <div className="text-container">{t('ReservList.LoginText')}</div>
                    <Button
                        color="carshare"
                        onClick={() => invokeLogin()}
                    >{t('Btn.Login')}</Button>
                </div>
            }
        </div>
    );
}

export default ReservList;