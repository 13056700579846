import _ from 'lodash';
import * as toastr from 'toastr';
import React, { useContext, useEffect, useState, useRef } from 'react';
import Select from 'react-select';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parse } from 'qs';
import { useLocation } from 'react-router';

import apiService from '../../util/api';
import SigInput from './SigInput';
import PhotoListComponent from './PhotoListComponent';
import DamageListComponent from './DamageListComponent';
import { AppContext } from '../AppContext';
import { selectStyle } from '../../style/react-select';
import { useDebounce } from '../../util/hooks';
import { getEnumOptions, getOption } from '../../util/options';
import { TT_RETURN, TT_TAKEOVER, TT_TAKEOVERANDRETURN } from '../../constants/takeoverType';
import { IUT_NONE, IUT_SIGN } from '../../constants/identifyUserType';

import './CheckVehicle.scss';
import DropdownInput from '../damage/DropdownInput';
import { distinct } from '../../util/array';
import Selectbox from '../selectbox/Selectbox';
import PageForm from '../PageForm';
import ConsentListComponent from '../ConsentListComponent';


const MODE_ID = 'id';
const MODE_USER = 'user';

const CheckVehicle = () => {
    const location = useLocation();
    let { car: carId, type: tType, proc, driver: qName, email: qEmail } = parse(location.search.substring(1));
    //console.log('tType', tType);
    const { t, user, lang, setPageTitle } = useContext(AppContext);
    const typeOptions = getEnumOptions(t, 'TakeoverTypes', [TT_TAKEOVER, TT_RETURN]);

    const typeRef = useRef();
    const regnumRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();

    const [regNumber, setRegNumber] = useState('');
    const [type, setType] = useState([TT_TAKEOVER, TT_RETURN].includes(tType) ? getOption(typeOptions, tType) : null);
    const [name, setName] = useState(qName || '');
    const [email, setEmail] = useState(qEmail || '');
    const [sig, setSig] = useState(null);
    const [data, setData] = useState(null);
    const [mode, setMode] = useState(MODE_ID);
    const [dataLoading, setDataLoading] = useState(false);
    const [dataSending, setDataSending] = useState(false);
    const [consents, setConsents] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [signal, setSignal] = useState(null);
    const [showSig, setShowSig] = useState(false);
    const [regNumOptions, setRegNumOptions] = useState([]);
    const [done, setDone] = useState(false);
    const [isTakeover,] = useState(proc === 'rsv');

    const debRegNum = useDebounce(regNumber, 500);
    // console.log('CheckVehicle', { location, carId });

    useEffect(() => {
        setPageTitle(t('Check.Title'));
        let ac = new AbortController();
        setSignal(ac.signal);
        if (carId) {
            loadDataFromId(carId, ac.signal);
        } else {
            setMode(MODE_USER);
        }
        return () => {
            ac.abort();
        };
    }, []);

    useEffect(() => {
        if (!done) {
            window.addEventListener('beforeunload', beforeLeave);
            return () => {
                window.removeEventListener('beforeunload', beforeLeave);
            }
        }
    }, [done]);

    useEffect(() => {
        let ac = new AbortController();
        if (data) {
            if (regNumber && isUserMode()) {
                loadData(regNumber, ac.signal);
            } else if (carId) {
                loadDataFromId(carId, ac.signal);
            }
        }
        return () => {
            ac.abort();
        };
    }, [lang]);

    useEffect(() => {
        let ac = new AbortController();
        if (debRegNum && isUserMode()) {
            loadData(debRegNum, ac.signal);
        }
        return () => {
            ac.abort();
        };
    }, [debRegNum]);

    useEffect(() => {
        let key = new Date().getTime();
        setConsents(((data || {}).agreements || []).filter(o => type ? (o.type === TT_TAKEOVERANDRETURN || type.value === o.type) : true).map(o => ({ ...o, key })));
        setPhotos(((data || {}).photos || []).filter(o => type ? (o.type === TT_TAKEOVERANDRETURN || type.value === o.type) : true).map(o => ({ ...o, key })));
    }, [data, type]);

    useEffect(() => {
        if (mode === MODE_USER) {
            regnumRef.current.focus();
        }
    }, [mode]);

    useEffect(() => {
        let show = _.get(data, 'identifyUser', IUT_NONE) === IUT_SIGN;
        setShowSig(show);
    }, [data]);

    useEffect(() => {
        if (mode === MODE_USER && !!user) {
            let ac = new AbortController();
            loadRegNumOptions(ac.signal);
            return () => {
                ac.abort();
            };
        } else {
            setRegNumOptions([]);
        }
    }, [mode, user]);

    const beforeLeave = e => {
        // console.log('beforeLeave');
        const msg = t('Check.BeforeLeave');
        (e || window.event).returnValue = msg;
        return msg;
    }

    const loadRegNumOptions = async signal => {
        try {
            let resp = await apiService.getCarsForCheck(signal);
            if (!signal || !signal.aborted) {
                let regnums = distinct((resp.data || []).map(o => o.registrationNumber).filter(o => !!o)).sort();
                setRegNumOptions(regnums);
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
                setRegNumOptions([]);
            }
        } finally {
            if (!signal || !signal.aborted) {
            }
        }
    }

    const loadData = async (rz, signal) => {
        try {
            setDataLoading(true);
            let resp = await apiService.getCheckDataByRegNum({ rz }, signal);
            if (!signal || !signal.aborted) {
                setData(resp.data || null);
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
            }
        } finally {
            if (!signal || !signal.aborted) {
                setDataLoading(false);
            }
        }
    }

    const loadDataFromId = async (id, signal) => {
        try {
            setDataLoading(true);
            let resp = await apiService.getCheckDataById(id, signal);
            if (!signal || !signal.aborted) {
                setData(resp.data || null);
                if (!resp.data) {
                    setMode(MODE_USER);
                }
                setRegNumber(_.get(resp.data, 'registrationNumber', ''));
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
                toastr.error(t('Check.DataNotLoadedErr'));
                setMode(MODE_USER);
            }
        } finally {
            if (!signal || !signal.aborted) {
                setDataLoading(false);
            }
        }
    }

    const save = async () => {
        // console.log('CheckVehicle.save', { data });
        if (data) {
            try {
                setDataSending(true);
                let input = await validateAndGetDataToSend();
                if (input) {
                    let resp = await apiService.sendCheck(data.id, input, signal);
                    if (!signal || !signal.aborted) {
                        toastr.success(t('Check.DataSaved'));
                        setDone(true);
                    }
                }
            } catch (error) {
                if (!signal || !signal.aborted) {
                    console.log(error);
                    toastr.error(_.get(error, 'response.data.message') || t('Check.DataSaveErr'));
                }
            } finally {
                if (!signal || !signal.aborted) {
                    setDataSending(false);
                }
            }
        } else {
            toastr.error(t('Check.NoDataErr'));
        }
    }

    const validateAndGetDataToSend = async () => {
        if (done) {
            return null;
        }

        let res = new FormData();

        if (!type) {
            toastr.error(t('Check.TypeRequired'));
            typeRef.current.focus();
            return null;
        }
        res.append('type', type.value);

        if (!name) {
            toastr.error(t('Check.NameRequired'));
            nameRef.current.focus();
            return null;
        }
        res.append('name', name);

        if (!email) {
            toastr.error(t('Check.EmailRequired'));
            emailRef.current.focus();
            return null;
        }
        res.append('email', email);

        let c = consents || [];
        if (_.some(c, o => !o.checked)) {
            toastr.error(t('Check.NotAllConsentsChecked'));
            return null;
        }
        c.forEach(o => res.append('agreements', o.id));

        let p = photos || [];
        if (_.some(p, o => !o.imgFile)) {
            toastr.error(t('Check.NotAllPhotosLoaded'));
            return null;
        }
        p.forEach(o => {
            res.append('photoIds', o.id);
            res.append('photos', o.imgFile);
        });

        if (sig) {
            const blob = await (await fetch(sig)).blob();
            res.append('signature', blob);
        } else if (showSig) {
            toastr.error(t('Check.SigRequired'));
            // emailRef.current.focus();
            return null;
        }

        if (isTakeover) {
            res.append('isTakeover', true);
        }

        return res;
    }

    const reloadData = () => {
        if (data && data.id) {
            loadDataFromId(data.id, signal);
        }
    }

    const isUserMode = () => {
        let res = mode === MODE_USER;
        // console.log('isUserMode', res);
        return res;
    }

    const getBtnText = () => {
        if (data && type)
            switch (_.get(type, 'value')) {
                case TT_RETURN:
                    return t('Check.ReturnBtn');
                case TT_TAKEOVER:
                    return t('Check.TakeoverBtn');
                default:
                    return t('Btn.Send');
            }
        else
            return t('Check.FindCar');
    }

    return done ? (
        <div className='page-from-container' >
            <div>{t(_.get(type, 'value') === TT_RETURN ? 'Check.ReturnDone' : 'Check.TakeDone')}</div>
        </div >
    ) : (
        <PageForm>
            <div className='section-container'>
                <div className='title'>
                    <span className="title-text">{t('Check.Data')}</span>
                    {data && data.providerLogo &&
                        <div className="title-logo">
                            <img src={`api/img/${data.providerLogo}`} />
                        </div>
                    }
                </div>
                <div className='prop-list'>
                    <div className='labval-item'>
                        <label>
                            <span>{t('Check.RegNum')}</span>
                            {dataLoading &&
                                <FontAwesomeIcon className='after-label-ico' icon='fa-solid fa-spinner' spinPulse={true} />
                            }
                        </label>
                        <DropdownInput
                            className='value custom-control-container x'
                            value={regNumber}
                            onChange={setRegNumber}
                            readOnly={!isUserMode()}
                            placeholder={isUserMode() ? t('Check.RegNumPlaceholder') : ' '}
                            autoFocus={isUserMode()}
                            innerRef={regnumRef}
                            options={regNumOptions}
                        />
                    </div>
                    <div className='labval-item'>
                        <label>{t('Check.Type')}</label>
                        <div className='value'>
                            <Selectbox
                                className="cs-form-select"
                                options={typeOptions}
                                value={type}
                                onChange={setType}
                                placeholder={t('Check.TypePlaceholder')}
                                ref={typeRef}
                                autoFocus={!isUserMode()}
                            />
                        </div>
                    </div>
                    <div className='labval-item'>
                        <label>{t('Check.Name')}</label>
                        <div className='value custom-control-container'>
                            <Input
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder={t('Check.NamePlaceholder')}
                                innerRef={nameRef}
                                autoComplete='name'
                            />
                        </div>
                    </div>
                    <div className='labval-item'>
                        <label>{t('Check.Email')}</label>
                        <div className='value custom-control-container'>
                            <Input
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder={t('Check.EmailPlaceholder')}
                                innerRef={emailRef}
                                autoComplete='email'
                            />
                        </div>
                    </div>
                </div>
            </div>
            {type && data &&
                <DamageListComponent
                    damages={data.damages}
                    regNum={data.registrationNumber}
                    name={name}
                    email={email}
                    carId={data.id}
                    onChange={reloadData}
                    onNoName={() => nameRef.current.focus()}
                    onNoEmail={() => emailRef.current.focus()}
                />
            }
            {type && consents && consents.length > 0 &&
                <ConsentListComponent
                    consents={consents}
                    type={type}
                ></ConsentListComponent>
            }
            {type &&
                <PhotoListComponent
                    photos={photos}
                    onChange={setPhotos}
                    showDelimiter={showSig}
                />
            }
            {type && showSig &&
                <div className='section-container'>
                    <div className='sig-item'>
                        <label>{t('Check.Sig')}</label>
                        <SigInput
                            value={sig}
                            onChange={setSig}
                            reason={t('Check.Title')}
                        />
                    </div>
                </div>
            }
            <div className='section-container'>
                <div className='buttons'>
                    <Button
                        color="carshare"
                        onClick={save}
                    >{getBtnText()}</Button>
                </div>
            </div>
        </PageForm>
    );
}

export default CheckVehicle;
