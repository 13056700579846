import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';


const GoogleMapCustomZoomControl = ({ zoomInTitle, zoomOutTitle, onZoomIn, onZoomOut }) => {
    return (
        <div className='custom-zoom-control'>
            <button
                className={classNames('gmap-btn', { touch: true })}
                onClick={onZoomIn}
                title={zoomInTitle}
            >
                <FontAwesomeIcon icon='fa-solid fa-plus' />
            </button>
            <div className='custom-zoom-control-delimiter' />
            <button
                className={classNames('gmap-btn', { touch: true })}
                onClick={onZoomOut}
                title={zoomOutTitle}
            >
                <FontAwesomeIcon icon='fa-solid fa-minus' />
            </button>
        </div>
    );
}

export default GoogleMapCustomZoomControl;
