import _ from 'lodash';
import * as toastr from 'toastr';
import React, { useContext } from 'react';
import { useState } from 'react';
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Input } from 'reactstrap';
import PhoneConfirmModal from '../../containers/PhoneConfirmModal';
import i18n from '../../i18n/i18n';
import apiService from '../../util/api';

import { invoke } from '../../util/helper';
import { setCurrentLanguage, supportedLanguages } from '../../util/lang';
import { getEnumOptions } from '../../util/options';
import { AppContext } from '../AppContext';
import PhoneInput from '../PhoneInput';
import ReadonlyInput from '../ReadonlyInput';
import Selectbox from '../selectbox/Selectbox';
import { useEffect } from 'react';

const Settings = ({ onClose }) => {

    const { t, user, loadUser } = useContext(AppContext);

    const languages = getEnumOptions(t, 'Lang', supportedLanguages);

    const [language, setLanguage] = useState(_.find(languages, { value: i18n.language }));
    const [verifyPhoneModal, setVerifyPhoneModal] = useState({});

    useEffect(() => {
        loadUser();
    }, [])

    const close = () => {
        invoke(onClose);
    }

    const onLangChange = value => {
        i18n.changeLanguage(value.value);
        setCurrentLanguage(value.value);
        setLanguage(value);
    }

    const onVerifyClick = async (e, phone) => {
        e.preventDefault();

        try {
            let resp = await apiService.setPhone({ phone });
            if (_.get(resp, 'data.code') === 0) {
                setVerifyPhoneModal({ key: new Date().getTime(), phone });
            } else {
                toastr.error(t('Settings.SetPhoneError'));
            }
        } catch (error) {
            toastr.error(_.get(error, 'response.data.message', t('Settings.SetPhoneError')));
        }
    }

    const onConfirmPhoneClosed = () => {
        loadUser();
        setVerifyPhoneModal({});
    }

    return (
        <Modal
            className="settings-modal"
            isOpen={true}
            autoFocus={false}
            centered
            size="sm"
            toggle={close}
            backdrop='static'
        >
            <ModalHeader toggle={close}>
                <span>{t('Settings.Title')}</span>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label>{t('Settings.Email')}</Label>
                        <ReadonlyInput value={_.get(user, 'email')} />
                    </FormGroup>
                    {/*<FormGroup>*/}
                    {/*    <Label>{t('Settings.Identity')}</Label>*/}
                    {/*    <ReadonlyInput*/}
                    {/*        value={t('Identity.Unverified')}*/}
                    {/*        linkBtn={t('Settings.VerifyIdentity')}*/}
                    {/*        onLinkBtn={e => e.preventDefault()}*/}
                    {/*    />*/}
                    {/*</FormGroup>*/}
                    <FormGroup>
                        <Label>{t('Settings.Phone')}</Label>
                        <PhoneInput
                            phone={_.get(user, 'phone', '') }
                            verifyBtn={t('Settings.VerifyPhone')}
                            onVerifyClick={onVerifyClick}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{t('Settings.Lang')}</Label>
                        <Selectbox
                            className="cs-form-select"
                            options={languages}
                            value={language}
                            onChange={onLangChange}
                        />
                    </FormGroup>
                </Form>
                {verifyPhoneModal && verifyPhoneModal.key &&
                    <PhoneConfirmModal
                        key={verifyPhoneModal.key}
                        phone={verifyPhoneModal.phone}
                        onClose={onConfirmPhoneClosed}
                    />
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    color="carshare"
                    onClick={close}
                >{t('Btn.Close')}</Button>
            </ModalFooter>
        </Modal>
    );
}

export default Settings;
