import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import './DamagePhotoModal.scss';


const DamagePhotoModal = ({ data, onClose }) => {

    const [pos, setPos] = useState(0);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [pos]);

    const cancel = () => {
        invokeClose();
    }

    const invokeClose = () => {
        if (onClose) {
            onClose();
        }
    }

    const getSrc = () => {
        if (data && data.imageHash && data.imageHash[pos]) {
            return `/api/image/${data.imageHash[pos]}?maxSize=true`;
        }

        return '';
    }

    const isPrevActicve = () => {
        return data && data.imageHash && pos > 0;
    }

    const isNextActicve = () => {
        return data && data.imageHash && pos + 1 < data.imageHash.length;
    }

    const prev = () => {
        if (isPrevActicve()) {
            setPos(pos - 1);
        } else {
            console.log('Prev not active.');
        }
    }

    const next = () => {
        if (isNextActicve()) {
            setPos(pos + 1);
        } else {
            console.log('Next not active.');
        }
    }

    const handleKeyDown = e => {
        let code = _.get(e, 'keyCode');
        // console.log('key down', code);
        switch (code) {
            case 33: // pgup
            case 37: // left
                prev();
                break;
            case 34: // pgdown
            case 39: // right
                next();
                break;
            case 27: // esc
                cancel();
                break;
        }
    }

    return (
        <Modal
            className="damage-photo-modal"
            isOpen={true}
            autoFocus={false}
            toggle={cancel}
            backdrop='static'
        >
            <ModalHeader toggle={cancel}>
                <span>{data && data.categoryName || t('Damages.Title')}</span>
            </ModalHeader>
            <ModalBody>
                {data && data.description &&
                    <div className='description'>{data && data.description}</div>
                }
                <div
                    className='photo-part'
                >
                    <div className='img-container'>
                        <img src={getSrc()} />
                    </div>
                    <div
                        className={classNames('prev', { active: isPrevActicve() })}
                        onClick={prev}
                    >
                        <FontAwesomeIcon icon='fa-solid fa-chevron-left' />
                    </div>
                    <div
                        className={classNames('next', { active: isNextActicve() })}
                        onClick={next}
                    >
                        <FontAwesomeIcon icon='fa-solid fa-chevron-right' />
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default DamagePhotoModal;