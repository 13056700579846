import React from 'react';
import { Input } from 'reactstrap';

import './TextItem.js.scss';


const TextItem = ({ label, ...props }) => {

    return (
        <div className='labval-item'>
            <label>{label}</label>
            <div className='value custom-control-container'>
                <Input {...props} />
            </div>
        </div>
    );
}

export default TextItem;