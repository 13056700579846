import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { joinNullable } from '../util/helper';

import { defaultLanguage, currentLanguage, supportedLanguages } from '../util/lang';


function merge(original, extension) {
	if (original && extension && typeof original == 'object' && typeof extension == 'object') {
		return _.merge(original, extension);
	}

	return original;
}

function applyFlags(resources, flags) {
    supportedLanguages.map(lang => {
        applyLangFlags(resources, lang, '', flags);
    });
}

function concat(...items) {
    return joinNullable('_', ...items);
}

function applyLangFlags(resources, lang, prefix, flags) {
    if (flags && flags.length) {
        flags.map(flag => {
            applyFlag(resources, lang, concat(prefix, flag));
        })
        flags.map(flag => {
            applyLangFlags(resources, lang, concat(prefix, flag), flags.filter(o => o !== flag));
        })
    }
}

function applyFlag(resources, lang, flag) {
    try {
        let dict = require(`./${concat(lang, flag)}`).default;
        //console.log(`applying dictionary ${concat(lang, flag)} ...`);
        resources[lang].translation = merge(resources[lang].translation, dict);
    } catch { }
}

function getResources(flags) {
    let resources = supportedLanguages.reduce((r, c) => {
        r[c] = { translation: require(`./${c}`).default };
        return r;
    }, {})

    if (flags && flags.length) {
        applyFlags(resources, flags);
    }

    return resources;
}

export function initi18n(flags) {
    //console.log('dev', 'i18n', 'init', { flags });
    i18n
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        // .use(LanguageDetector)

        // pass the i18n instance to react-i18next.
        .use(initReactI18next)

        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            debug: true,
            fallbackLng: defaultLanguage,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            resources: getResources(flags),
            lng: currentLanguage
        });
}

export function updatei18nResources(flags) {
    //console.log('dev', 'i18n', 'update', { flags });
    let resources = getResources(flags);
    //console.log('resources', resources);
    supportedLanguages.forEach(lang => {
        //console.log('lang', lang);
        i18n.addResources(lang, 'translation', resources[lang].translation);
    });
}

export default i18n;
