import React from 'react';
import classNames from 'classnames';
import LinkBtn from './LinkBtn';

import './ReadonlyInput.js.scss';

function ReadonlyInput({ value, className, linkBtn, onLinkBtn }) {

    return (
        <div className={classNames('custom-control-container readonly readonly-input-container', className)}>
            <div className="readonly-div-control">{value}</div>
            {linkBtn &&
                <LinkBtn text={linkBtn} onClick={onLinkBtn} />
            }
        </div>
    );
}

export default ReadonlyInput;