import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ErrorPage.jsx.scss';


function ErrorPage({ title, message, error, resetErrorBoundary }) {
    return (
        <div className="error-page-container">
            <div className="error-page-content">
                <div className='icon-part'>
                    <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" color='red' size='3x' />
                </div>
                <div className='text-part'>
                    <div className='title'>{title || 'Something went wrong'}</div>
                    {message &&
                        <div className='message'>{message}</div>
                    }
                    {resetErrorBoundary &&
                        <div
                            className='link'
                            onClick={() => resetErrorBoundary()}
                        >{'reset'}</div>
                    }
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;