const cs = {
    "Carshare": {
        "NoCar": "Aktuálně není žádné volné kolo k zapůjčení"
    },
    "Contacts": {
        "Manuals": {
            "Main": "Bikesharig",
            "MainFile": "xmarton_bikesharing_manual_cs.pdf",
            "MainName": "Xmarton Bikesharing - Návod.pdf.pdf"
        }
    },
    "Damage": {
        "DataNotLoadedErr": "Nepovedlo se načíst data kola.",
        "FindCar": "Vyhledat kolo",
        "NoDataErr": "Neexistující nebo neautorizované kolo.",
        "Title": "NEDOSTATKY KOLA"
    },
    "Damages": {
        "NoDamages": "Na kole nejsou nahlášeny žádné nedostatky."
    },
    "FinishSettingsRequiredModal": {
        "ReqIdentity": "Před rezervací kola prosím ověřte Vaši totožnost.",
        "ReqPhone": "Před rezervací kola prosím doplňte kontaktní telefon.",
        "ReqPhoneAndIdentity": "Před rezervací kola prosím doplňte kontaktní telefon a ověřte Vaši totožnost."
    },
    "Handover": {
        "Name": "Kolo",
        "Place": "Místo",
        "ReturnTitle": "VRÁCENÍ KOLA",
        "SuccessReturnTitle": "Kolo bylo úspěšně vráceno",
        "SuccessTakeoverTitle": "Kolo bylo úspěšně převzato",
        "TakeoverTitle": "PŘEVZETÍ KOLA"
    },
    "Check": {
        "DataNotLoadedErr": "Nepovedlo se načíst data kola.",
        "FindCar": "Vyhledat kolo",
        "NoDataErr": "Neexistující nebo neautorizované kolo.",
        "ReturnBtn": "Vrátit kolo",
        "ReturnDone": "Kolo bylo úspěšně vráceno.",
        "TakeDone": "Kolo bylo úspěšně převzato.",
        "TakeoverBtn": "Převzít kolo",
        "Title": "PŘEDÁNÍ KOLA"
    },
    "Nav": {
        "Title": "KOLA K ZAPŮJČENÍ"
    },
    "Rent": {
        "Title": "Zapůjčení kola"
    },
    "Reservation": {
        "Car": "Kolo",
        "CarNotAvaibleForPeriod": "Do zvoleného období není kolo volné",
        "CarPlaceholder": "Vyberte si kolo",
        "LastCarForPeriod": "Ve zvoleném období je zde volné poslední kolo",
        "NoCarForModelPeriod": "Ve zvoleném období zde není volný daný model kola",
        "NoCarForPeriod": "Ve zvoleném období zde není volné kolo",
        "Parking": "Místo",
        "PickUpTooltip": "Kolo bude možné převzít až v čase rezervace.",
        "RepeteAfter": "Po objednání přichystat rezervaci dalšího kola",
        "Title": "Rezervace kola"
    },
    "ReservationState": {
        "ReadyToTake": "Kolo si můžete převzít",
        "Taken": "Kolo zapůjčeno"
    },
    "ReservList": {
        "LoadCarDetailError": "Detail kola se nepodařilo načíst.",
        "Return": "Vrátit kolo",
        "Takeover": "Převzít kolo"
    }
}

export default cs;