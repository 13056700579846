const de = {
    "Carshare": {
        "NoCar": "Derzeit gibt es keine freie Fläche zur Anmietung"
    },
    "FinishSettingsRequiredModal": {
        "ReqIdentity": "Bitte überprüfen Sie Ihre Identität, bevor Sie einen Parkplatz reservieren.",
        "ReqPhone": "Bitte geben Sie eine Kontakttelefonnummer an, bevor Sie einen Parkplatz reservieren.",
        "ReqPhoneAndIdentity": "Bitte geben Sie vor der Parkplatzreservierung eine Kontakttelefonnummer an und verifizieren Sie Ihre Identität."
    },
    "Nav": {
        "Title": "BikeParking"
    },
    "Reservation": {
        "Car": "Stehen",
        "CarPlaceholder": "Wählen Sie einen Stand aus",
        "LastCarForPeriod": "Im gewählten Zeitraum gibt es einen freien letzten Parkplatz",
        "NoCarForModelPeriod": "Das angegebene Ladegerätmodell ist im ausgewählten Zeitraum nicht verfügbar",
        "NoCarForPeriod": "Im ausgewählten Zeitraum gibt es keine kostenlosen Parkplätze",
        "PickUpTooltip": "Der Parkplatz wird erst zum Zeitpunkt der Reservierung freigeschaltet",
        "RepeteAfter": "Bereiten Sie nach der Bestellung die Reservierung eines weiteren Parkplatzes vor",
        "Title": "Parkplatzreservierung"
    },
    "ReservationState": {
        "ReadyToTake": "Parkplatz gemietet",
        "Taken": "Parkplatz gemietet"
    },
    "ReservList": {
        "Return": "Parken entsperren",
        "Takeover": "Parken entsperren"
    }
}

export default de;