const sk = {
    "App": {
        "AuthBoundMsg": "Kontaktujte prosím správcu a popíšte mu, ako k chybe došlo.",
        "AuthBoundTit": "Chyba autentikácie",
        "ConfigError": "Chyba načítania konfigurácie. Skúste to znova alebo kontaktujte správcu systému.",
        "ConfigLoadign": "Načítanie konfigurácie...",
        "Init": "Inicializácia aplikácie...",
        "ProcLogin": "Prihlásenie",
        "ProcLoginClbck": "Prihlásenie",
        "ProcLoginClbckTxt": "Spracovávam informácie o užívateľovi...",
        "ProcLoginTxt": "Odosielam požiadavku na prihlásenie...",
        "ProcLogout": "Odhlásenie",
        "ProcLogoutClbck": "Odhlásenie",
        "ProcLogoutClbckTxt": "Spracovávam odpoveď...",
        "ProcLogoutTxt": "Odosielam požiadavku na odhlásenie...",
        "ProcPay": "Platba",
        "ProcPayCallback": "Spracovanie platby..."
    },
    "Btn": {
        "Accept": "Potvrdiť",
        "Cancel": "Zrušiť",
        "Capture": "Vyfotiť",
        "Clear": "Zmazať",
        "Close": "Zavrieť",
        "Delete": "Zmazať",
        "Login": "Prihlásiť sa",
        "Logout": "Odhlásiť sa",
        "No": "Nie",
        "Pay": "Zaplatiť",
        "Save": "Uložiť",
        "Send": "Odoslať",
        "TakePhoto": "Zaobstarať fotku...",
        "Yes": "Áno"
    },
    "Carshare": {
        "NoCar": "Aktuálne nie je žiadne voľné vozidlo na zapožičanie"
    },
    "Const": {
        "AllModel": "Všetky modely",
        "NoModel": "Bez modelu"
    },
    "Contacts": {
        "InfoSupport": "Informačná podpora",
        "Manual": "Príručka",
        "Manuals": {
            "Main": "Carsharing",
            "Main_auto-lion": "Carsharing AUTO-LION",
            "Main_carsharingkacmacek": "CARsharing Kačmáček",
            "Main_kb": "KB - Bikesharing",
            "Main_malekarpaty": "Malé Karpaty - BikeSharing",
            "Main_mkparking": "Malé Karpaty - BikeParking",
            "MainFile": "xmarton_carsharing_manual_sk.pdf",
            "MainFile_auto-lion": "carsharing_auto-lion_manual_cs.pdf",
            "MainFile_carsharingkacmacek": "carsharing_kacmacek_manual_cs.pdf",
            "MainFile_kb": "kb_bikesharing_manual_cs.pdf",
            "MainFile_malekarpaty": "male_karpaty_bikesharing_manual_sk.pdf",
            "MainFile_mkparking": "male_karpaty_bikeparking_manual_sk.pdf",
            "MainName": "Xmarton Carsharing - Návod.pdf",
            "MainName_auto-lion": "Carsharing AUTO-LION - Návod.pdf",
            "MainName_carsharingkacmacek": "CARsharing Kačmáček - Návod.pdf",
            "MainName_kb": "KB BikeSharing - Návod.pdf",
            "MainName_malekarpaty": "Malé Karpaty BikeSharing - Návod.pdf",
            "MainName_mkparking": "Malé Karpaty BikeParking - Návod.pdf"
        },
        "ServisSupport": "Servisná podpora bicyklov",
        "Support": "Technická podpora",
        "Title": "Príručka a kontakty"
    },
    "Damage": {
        "Data": "Údaje",
        "DataNotLoadedErr": "Nepodarilo sa načítať dáta vozidla.",
        "Email": "E-mail vodiča",
        "EmailPlaceholder": "Zadajte e-mail vodiča",
        "FindCar": "Vyhľadať vozidlo",
        "Name": "Meno vodiča",
        "NamePlaceholder": "Zadajte meno vodiča",
        "NoDataErr": "Neexistujúce alebo neautorizované vozidlo.",
        "RegNum": "Evidenčné číslo",
        "RegNumPlaceholder": "Zadajte evidenčné číslo",
        "Title": "NEDOSTATKY VOZIDLA"
    },
    "Damages": {
        "NewBtn": "Nahlásiť nový",
        "NoDamages": "Na vozidle nie sú nahlásené žiadne nedostatky.",
        "NoNameEmail": "Najprv prosím vyplňte meno a e-mail.",
        "NoRegNum": "Najprv prosím vyplňte evidenčné číslo.",
        "Title": "Nedostatky a poškodenie"
    },
    "Filter": {
        "All": "všetky",
        "Title": "Modely"
    },
    "FinishSettingsRequiredModal": {
        "Btn": "Nastavenie používateľa",
        "ReqIdentity": "Pred rezerváciou vozidla prosím overte Vašu totožnosť.",
        "ReqPhone": "Pred rezerváciou vozidla prosím doplňte kontaktný telefón.",
        "ReqPhoneAndIdentity": "Pred rezerváciou vozidla prosím doplňte kontaktný telefón a overte Vašu totožnosť.",
        "Title": "Dokončenie nastavenia"
    },
    "Handover": {
        "Code": "Kód pre vyzdvihnutie",
        "DataLoading": "Data se načítají...",
        "Name": "Vozidlo",
        "NoCarId": "Žádné id vozidla.",
        "Nodata": "Nebyla načtena žádná data.",
        "NoType": "Žádný typ předání.",
        "ParkingNo": "Číslo státia",
        "Place": "Parkovisko",
        "ReturnAndLock": "Vrátiť a zamknúť",
        "ReturnTitle": "VRÁTENIE VOZIDLA",
        "SuccessReturnText": "Ďakujeme a tešíme sa na ďalšiu rezerváciu.",
        "SuccessReturnTitle": "Vozidlo bolo úspešne vrátené",
        "SuccessTakeoverText": "Teraz sa odistí reťaz kolesa na 30 sekúnd, počas ktorých reťaz vyberte. Odistite bicykel a reťaz vráťte späť do zámku.",
        "SuccessTakeoverTitle": "Vozidlo bolo úspešne prevzaté",
        "TakeAndUnlock": "Prevziať a odomknúť",
        "TakeoverTitle": "PREVZATIE VOZIDLA",
        "UnlockBike": "Odomknúť bicykel",
        "UnlockError": "Chyba odoslania príkazu na odomknutie státia.",
        "UnlockParking": "Odomknúť státie",
        "UnlockSuccess": "Požiadavka na odomknutie státia bola odoslaná."
    },
    "Check": {
        "BeforeLeave": "Naozaj chcete opustiť stránku a zahodiť rozpracovaný fromulár?",
        "Consents": "Súhlasy",
        "Data": "Údaje",
        "DataNotLoadedErr": "Nepodarilo sa načítať dáta vozidla.",
        "DataSaved": "Dáta boli úspešne odoslané.",
        "DataSaveErr": "Došlo k chybe pri odosielaní dát.",
        "Email": "E-mail vodiča",
        "EmailPlaceholder": "Zadajte e-mail vodiča",
        "EmailRequired": "Doplňte prosím meno a e-mail vodiča.",
        "FindCar": "Vyhľadať vozidlo",
        "Name": "Meno vodiča",
        "NamePlaceholder": "Zadajte meno vodiča",
        "NameRequired": "Doplňte prosím meno a e-mail vodiča.",
        "NoDataErr": "Neexistujúce alebo neautorizované vozidlo.",
        "NotAllConsentsChecked": "Je potrebné potvrdiť všetky súhlasy.",
        "NotAllPhotosLoaded": "Doplňte prosím fotografie.",
        "Photos": "Vyfotenie",
        "RegNum": "Evidenčné číslo",
        "RegNumPlaceholder": "Zadajte evidenčné číslo",
        "ReturnBtn": "Vrátiť vozidlo",
        "ReturnDone": "Vozidlo bolo úspešne vráceno.",
        "Sig": "Podpis",
        "SigRequired": "Doplňte prosím podpis.",
        "TakeDone": "Vozidlo bylo úspěšně převzato.",
        "TakeoverBtn": "Prevziať vozidlo",
        "Title": "ODOVZDANIE VOZIDLA",
        "Type": "Kontrola pri",
        "TypePlaceholder": "Vyberte typ kontroly",
        "TypeRequired": "Vyberte prosím typ kontroly."
    },
    "Identity": {
        "Unverified": "Neoverená"
    },
    "Lang": {
        "cs": "Čeština",
        "de": "Deutsch",
        "en": "English",
        "pl": "Polski",
        "sk": "Slovenčina"
    },
    "Login": {
        "BadLogin": "Neplatné prihlasovacie údaje",
        "ByGoogle": "Prihlásiť sa cez Google",
        "ByXmarton": "Prihlásiť sa cez Xmarton",
        "ConsentLink": "https://www.xmarton.com/data/Informace_o_zpracovani_osobnich_udaju_pro_zakazniky.pdf",
        "ConsentLinkText": "spracovaním osobných údajov",
        "ConsentTextPostfix": ".",
        "ConsentTextPrefix": "Pokračovaním súhlasíte so ",
        "ErrTitle": "Chyba prihlásenia",
        "Password": "Heslo",
        "Title": "Prihlásenie",
        "Username": "E-mail"
    },
    "Logout": {
        "ErrTitle": "Chyba odhlásenia",
        "NotLocalLogin": "Odhlásenie nebolo iniciované v aplikácii."
    },
    "Map": {
        "ApiKeyError": "Došlo k chybe konfigurácie aplikácie. Skúste to prosím znova alebo kontaktujte správcu systému.",
        "EnableLocationMsg": "Povoľte prosím oprávnenie na prístup k polohe.",
        "Filter": "Modely",
        "FindClose": "Nájsť najbližší",
        "LoadError": "Nepodarilo sa načítať mapu, skúste to prosím znova.",
        "Loading": "Načítanie mapy",
        "Location": "Vaša poloha",
        "Reservations": "Rezervace",
        "Satellite": "Satelitná mapa",
        "ZoomInTitle": "Priblížiť",
        "ZoomOutTitle": "Oddialiť"
    },
    "Nav": {
        "App": "Otvoriť aplikáciu",
        "Login": "Prihlásiť sa",
        "Logouted": "Ste odhlásení",
        "Title": "VOZIDLÁ NA ZAPOŽIČANIE"
    },
    "NewDamage": {
        "Category": "Kategória",
        "CategoryRequired": "Kategória je povinná.",
        "DataSaved": "Nedostatok bol úspešne nahlásený.",
        "DataSaveErr": "Došlo k chybe pri odosielaní dát.",
        "Description": "Popis",
        "DescriptionRequired": "Doplňte prosím popis.",
        "Event": "Poistná udalosť",
        "Photo": "Fotografia",
        "PhotoRequired": "Doplňte prosím fotografiu.",
        "RegNum": "Evidenčné číslo",
        "Title": "Nahlásenie nedostatku"
    },
    "PaymentResult": {
        "Error": "Došlo k chybe pri spracovaní výsledku platby. Kontaktujte prosím správcu systému.",
        "Fail": "Platba nebola úspešne vykonaná",
        "Success": "Platba bola úspešne vykonaná",
        "Title": "Platba"
    },
    "PhoneConfirmModal": {
        "Code": "Overovací kód",
        "Text": "Na overenie správnosti telefónneho čísla sme Vám zaslali SMS s overovacím kódom. Pre dokončenie ho prosím zadajte.",
        "Title": "Overenie telefónneho čísla"
    },
    "PhotoCapture": {
        "Title": "Zhotovenie fotografie"
    },
    "PhotoInput": {
        "MaxFileSize": "Bol prekročený limit veľkosti.",
        "Photo": "Fotka",
        "UnsupportedFileType": "Nepodporovaný typ súboru."
    },
    "Rent": {
        "Title": "Zapožičanie vozidla"
    },
    "Reservation": {
        "Cancel": {
            "Btn": "Zrušiť rezerváciu",
            "Confirm": {
                "Question": "Naozaj chcete zrušiť rezerváciu?",
                "Title": "Zrušiť rezerváciu"
            },
            "Error": "Rezervácia nebola zrušená.",
            "Success": "Rezervácia bola úspešne zrušená."
        },
        "Car": "Vozidlo",
        "CarNotAvaibleForPeriod": "Do zvoleného obdobia nie je vozidlo voľné",
        "CarPlaceholder": "Vyberte vozidlo",
        "DepositInfo": "K cene prenájmu bude započítaná aj vratná kaucia {deposit}.",
        "Equipment": "Výbava",
        "EquipmentFor": "Výbava pre",
        "Finish": {
            "Btn": "Ukončiť rezerváciu",
            "Confirm": {
                "Question": "Naozaj chcete ukončiť rezerváciu?",
                "Title": "Ukončiť rezerváciu"
            },
            "Error": "Rezervácia nebola ukončená.",
            "Success": "Rezervácia bola úspešne ukončená."
        },
        "From": "Od",
        "GdprLink": "spracovania osobných údajov",
        "GdprPrefix": "Zásady",
        "CheckError": "Platobnú bránu sa nepodarilo otvoriť, kontaktujte prosím technickú podporu.",
        "LastCarForPeriod": "Vo zvolenom období je tu voľné posledné vozidlo",
        "Model": "Model",
        "NoCarForModelPeriod": "Vo zvolenom období tu nie je voľný daný model vozidla",
        "NoCarForPeriod": "Vo zvolenom období tu nie je voľné vozidlo",
        "Parking": "Parkovisko",
        "Pay": {
            "Error": "Došlo k chybe, platbu teraz nie je možné zaplatiť. Skúste to prosím neskôr znova alebo kontaktujte správcu systému."
        },
        "PayAndSend": "Odoslať a zaplatiť",
        "PickUpTooltip": "Vozidlo bude možné prevziať až v čase rezervácie.",
        "Price": "Cena",
        "PriceFor": "Cena pre",
        "Pricelist": "Kompletný cenník",
        "RepeteAfter": "Po objednaní prichystať rezerváciu ďalšieho vozidla",
        "SaveError": "Rezerváciu sa nepodarilo vytvoriť.",
        "SaveSuccess": "Rezervácia bola úspešne vytvorená.",
        "Title": "Rezervácia vozidla",
        "To": "Do",
        "VopLink": "obchodnými podmienkami",
        "VopPrefix": "Odoslaním objednávky súhlasíte s"
    },
    "ReservationState": {
        "Approved": "Schválená",
        "Canceled": "Zrušená",
        "Finished": "Ukončená",
        "New": "Čaká na schválenie",
        "Paid": "Objednaná",
        "ReadyToTake": "Vozidlo si môžete prevziať",
        "Rejected": "Zamietnutá",
        "Taken": "Vozidlo zapožičané",
        "Unknown": "Neznámy",
        "WaitForPay": "Čaká na zaplatenie"
    },
    "ReservList": {
        "Detail": "Detail rezervácie",
        "LoadCarDetailError": "Detail vozidla sa nepodarilo načítať.",
        "LoginText": "Pre zobrazenie Vašich rezervácií sa prosím prihláste.",
        "NoParking": "Bez parkoviska",
        "NoReserv": "Žiadne rezervácie",
        "Return": "Vrátiť vozidlo",
        "Takeover": "Prevziať vozidlo",
        "Title": "Moje rezervácie",
        "UnknownControl": "Neznámy typ ovládania",
        "Unlock": "Odomknúť"
    },
    "Select": {
        "NoOptions": "Žiadne možnosti"
    },
    "Settings": {
        "ConfirmPhoneError": "Telefónne číslo nebolo overené.",
        "ConfirmPhoneSuccess": "Telefónne číslo bolo úspešne overené a nastavené.",
        "Email": "E-mail",
        "Identity": "Totožnosť",
        "Lang": "Jazyk",
        "Link": "Nastavenia",
        "Phone": "Telefónne číslo",
        "SetPhoneError": "Došlo k chybe nastavenia telefónneho čísla.",
        "Title": "Nastavenie používateľa",
        "VerifyIdentity": "Overiť totožnosť",
        "VerifyPhone": "Overiť a uložiť"
    },
    "SidePanSel": {
        "Filter": "Filter",
        "Reserv": "Rezervácie"
    },
    "Sig": {
        "Title": "Podpis"
    },
    "TakeoverInfoModal": {
        "Open": "Otvoriť aplikáciu",
        "Text": "Pre prevzatie a vrátenie vozidla si prosím nainštalujte mobilnú aplikáciu XFLEET by Xmarton, pomocou ktorej vozidlo v čase rezervácie tiež odomknete a povolíte štart.",
        "Title": "Prevzatie vozidla"
    },
    "TakeoverTypes": {
        "return": "Vrátenie",
        "takeover": "Prevzatie",
        "takeover_and_return": "Prevzatie a vrátenie"
    },
    "WebTitle": "Carsharing"
}

export default sk;