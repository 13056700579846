const cs = {
    "Carshare": {
        "NoCar": "Aktuálně není žádné volné stání k zapůjčení"
    },
    "FinishSettingsRequiredModal": {
        "ReqIdentity": "Před rezervací stání prosím ověřte Vaši totožnost.",
        "ReqPhone": "Před rezervací stání prosím doplňte kontaktní telefon.",
        "ReqPhoneAndIdentity": "Před rezervací stání prosím doplňte kontaktní telefon a ověřte Vaši totožnost."
    },
    "Nav": {
        "Title": "BikeParking"
    },
    "Reservation": {
        "Car": "Stání",
        "CarPlaceholder": "Vyberte stání",
        "LastCarForPeriod": "Ve zvoleném období je zde volné poslední stání",
        "NoCarForModelPeriod": "Ve zvoleném období zde není volný daný model nabíječky",
        "NoCarForPeriod": "Ve zvoleném období zde není volné stání",
        "PickUpTooltip": "Stání bude možné odemknout až v čase rezervace",
        "RepeteAfter": "Po objednání přichystat rezervaci dalšího stání",
        "Title": "Rezervace parkování"
    },
    "ReservationState": {
        "ReadyToTake": "Parkování zapůjčeno",
        "Taken": "Parkování zapůjčeno"
    },
    "ReservList": {
        "Return": "Odemknout stání",
        "Takeover": "Odemknout stání"
    }
}

export default cs;