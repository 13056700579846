import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';

import './DamageListItem.scss';
import DamagePhotoModal from './DamagePhotoModal';


const DamageListItem = ({ data }) => {

    const [photo, setPhoto] = useState(false);

    const click = () => {
        if (data.imageHash && data.imageHash.length) {
            setPhoto(true);
        }
    }

    const close = () => {
        setPhoto(false);
    }

    const getSrc = () => {
        if (data.imageHash && data.imageHash.length) {
            return `/api/image/${data.imageHash[0]}`;
        }

        return null;
    }

    return (
        <>
            <div
                className={classNames('damage-item', { 'has-photo': !!getSrc() })}
                onClick={click}
            >
                <div className='damage-item-img-container'>
                    {getSrc() ? (
                        <img src={getSrc()} />
                    ) : (
                        <FontAwesomeIcon className='no-img' icon='fa-solid fa-image' />
                    )}
                </div>
                <div className='info-container'>
                    <div className='info-title'>{data.categoryName}</div>
                    <div className='info-description'>{data.description}</div>
                </div>
            </div>
            {photo &&
                <DamagePhotoModal
                    data={data}
                    onClose={close}
                />
            }
        </>
    );
}

export default DamageListItem;