import * as toastr from 'toastr';
import React, { useContext, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useHistory } from 'react-router';
import classNames from 'classnames';

import { AppContext } from './AppContext';
import apiService from './../util/api';
import { invoke } from './../util/helper';
import Spinner from './Spinner';
import { getLoginUrl } from './api-authorization/ApiAuthorizationConstants';

import './LoginModal.js.scss';
import XmartonIco from './XmartonIco';
import GoogleIco from './GoogleIco';
import qs from 'qs';
import { useEffect } from 'react';
import { Fa5FadInfoCircle } from './fontawesome5/fa5-custom';


export const LoginModal = ({ onCancel, onSubmit, args }) => {

    const { t, pathConfig, showContacts, isSharingLayout } = useContext(AppContext);
    let history = useHistory();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [pending, setPending] = useState(false);
    const [showGoogle, setShowGoogle] = useState(true);
    const [showXmarton, setShowXmarton] = useState(true);
    const [hideClose, setHideClose] = useState(true);

    useEffect(() => {
        let { authFilter, forceLogin } = pathConfig || {};
        setShowGoogle(!(authFilter && authFilter.length && !authFilter.includes('google')));
        setShowXmarton(!(authFilter && authFilter.length && !authFilter.includes('xmarton')));
        setHideClose(forceLogin === true);
    }, [pathConfig]);

    const save = async () => {
        try {
            setPending(true);
            let res = await apiService.login({ username: login, password });
            console.log('login', res);
            invoke(onSubmit);
        } catch (error) {
            console.log(error);
            toastr.error(t('Login.BadLogin'));
        } finally {
            setPending(false);
        }
    };

    const cancel = e => {
        let { forceLogin } = pathConfig || {};
        if (forceLogin) {
            e.preventDefault();
        } else {
            invoke(onCancel);
        }
    }

    const loginByXmarton = () => doLogin('xmarton')

    const loginByGoogle = () => doLogin('google')

    const doLogin = type => {
        let argsQs = qs.stringify({ ...(args || {}), type });
        let returnUrl = `${getLoginUrl()}&${argsQs}`;
        history.push(returnUrl);
        invoke(onCancel);
    }

    return (
        <Modal
            className={classNames('login-modal', { 'hide-close': hideClose })}
            isOpen={true}
            autoFocus={false}
            centered
            size="sm"
            toggle={cancel}
            backdrop='static'
        >
            <ModalHeader
                className={classNames({ 'title-btn-modal-header': isSharingLayout })}
                toggle={cancel}
            >
                <span className='title-text'>{t('Login.Title')}</span>
                <Spinner visible={pending} />
                {isSharingLayout &&
                    <div className='modal-header-contact-btn' onClick={showContacts}>
                        <Fa5FadInfoCircle />
                    </div>
                }
            </ModalHeader>
            <ModalBody>
                <div className="login-buttons-container">
                    {showGoogle &&
                        <Button
                            color="outline-secondary"
                            onClick={loginByGoogle}
                        >
                            <GoogleIco />
                            <span>{t('Login.ByGoogle')}</span>
                        </Button>
                    }
                    {showXmarton &&
                        <Button
                            color="outline-secondary"
                            onClick={loginByXmarton}
                        >
                            <XmartonIco />
                            <span>{t('Login.ByXmarton')}</span>
                        </Button>
                    }
                </div>
                <p>
                    <span>{t('Login.ConsentTextPrefix')}</span>
                    <a
                        className='link-btn'
                        href={t('Login.ConsentLink')}
                        target='_blank'
                    >{t('Login.ConsentLinkText')}</a>
                    <span>{t('Login.ConsentTextPostfix')}</span>
                </p>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>
    );
}
