import React from 'react'
import { Component } from 'react';
import authService from './AuthorizeService';
import { AuthenticationResultStatus } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import Loader from '../Loader';
import { AppContext } from '../AppContext';
import ErrorPage from './ErrorPage';
import { useContext } from 'react';
import { error } from 'toastr';
import { stringify } from '../../util/helper';


function LoginError({ message }) {
    const { t } = useContext(AppContext);

    return (
        <ErrorPage
            title={t('Login.ErrTitle')}
            message={message}
            resetErrorBoundary={() => window.location.assign('/')}
        />
    );
}

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export class Login extends Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);

        this.state = {
            message: undefined,
            error: false
        };
    }

    componentDidMount() {
        try {
            const action = this.props.action;
            switch (action) {
                case LoginActions.Login:
                    this.login(this.getReturnUrl(), this.getLoginType());
                    break;
                case LoginActions.LoginCallback:
                    this.processLoginCallback();
                    break;
                case LoginActions.LoginFailed:
                    const params = new URLSearchParams(window.location.search);
                    const error = params.get(QueryParameterNames.Message);
                    this.setState({ message: error });
                    break;
                case LoginActions.Profile:
                    this.redirectToProfile();
                    break;
                case LoginActions.Register:
                    this.redirectToRegister();
                    break;
                default:
                    throw new Error(`Invalid action '${action}'`);
            }
        } catch (error) {
            console.error('Error componentDidMount:', error);
            this.setState({ error: true, message: error });
        }
    }

    render() {
        const action = this.props.action;
        const { message, error } = this.state;
        const { t } = this.context;

        if (error) {
            return <LoginError />;
        }
        if (!!message) {
            return <div>{stringify(message)}</div>
        } else {
            switch (action) {
                case LoginActions.Login:
                    return (
                        <Loader
                            text={t('App.ProcLogin')}
                            subtext={t('App.ProcLoginTxt')}
                        />
                    );
                case LoginActions.LoginCallback:
                    return (
                        <Loader
                            text={t('App.ProcLoginClbck')}
                            subtext={t('App.ProcLoginClbckTxt')}
                        />
                    );
                case LoginActions.Profile:
                case LoginActions.Register:
                    return (<div></div>);
                default:
                    this.setState({ error: true, message: new Error(`Invalid action '${action}'`) });
            }
        }
    }

    async login(returnUrl, type) {
        try {
            const state = { returnUrl, type };
            const result = await authService.signIn(state);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    break;
                case AuthenticationResultStatus.Success:
                    await this.navigateToReturnUrl(returnUrl);
                    break;
                case AuthenticationResultStatus.Fail:
                    console.error('Authentication failed:', result.message);
                    this.setState({ error: true, message: result.message });
                    break;
                default:
                    throw new Error(`Invalid status result ${result.status}.`);
            }
        } catch (error) {
            console.error('Error login:', error);
            this.setState({ error: true, message: error });
        }
    }

    async processLoginCallback() {
        try {
            const url = window.location.href;
            const result = await authService.completeSignIn(url);
            switch (result.status) {
                case AuthenticationResultStatus.Redirect:
                    // There should not be any redirects as the only time completeSignIn finishes
                    // is when we are doing a redirect sign in flow.
                    throw new Error('Should not redirect.');
                case AuthenticationResultStatus.Success:
                    await this.navigateToReturnUrl(this.getReturnUrl(result.state));
                    break;
                case AuthenticationResultStatus.Fail:
                    console.error('Authentication failed:', result.message);
                    this.setState({ error: true, message: result.message });
                    break;
                default:
                    throw new Error(`Invalid authentication result status '${result.status}'.`);
            }
        } catch (error) {
            console.error('Error processLoginCallback:', error);
            this.setState({ error: true, message: error });
        }
    }

    getReturnUrl(state) {
        if (state && state.returnUrl) {
            return state.returnUrl;
        }

        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        let returnUrl = fromQuery || `${window.location.origin}/`;
        let ctxId = params.get('ctxId');
        if (ctxId) {
            returnUrl += `${returnUrl.includes('?') ? '&' : '?'}ctx=${ctxId}`;
        }
        return returnUrl;
    }

    getLoginType() {
        const params = new URLSearchParams(window.location.search);
        const type = params.get('type') === 'xmarton' ? 'xmarton' : 'google';
        return type;
    }

    redirectToRegister() {
        this.redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    redirectToProfile() {
        this.redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    redirectToApiAuthorizationPath(apiAuthorizationPath) {
        const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser they get sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    navigateToReturnUrl(returnUrl) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }
}
