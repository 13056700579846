import React, { useContext } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AppContext } from './AppContext';

//import './Contacts.js.scss';


export const InfoModal = ({ title, text, onClose }) => {

    const { t } = useContext(AppContext);

    return (
        <Modal
            className={'info-modal'}
            isOpen={true}
            autoFocus={false}
            centered
            toggle={onClose}
            backdrop='static'
            size="sm"
        >
            <ModalHeader>
                <span>{title}</span>
            </ModalHeader>
            <ModalBody>
                <p>{text}</p>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="carshare"
                    onClick={onClose}
                >{t('Btn.Close')}</Button>
            </ModalFooter>
        </Modal>
    );
}