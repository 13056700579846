export const selectStyle = {
    control: (cur, st) => {
        let result = {
            ...cur,
            borderWidth: '0px',
            boxShadow: 'none',
            position: 'relative'
        };
        result['&::before'] = {
            content: '""',
            position: 'absolute',
            height: '1px',
            right: '4px',
            left: '4px',
            bottom: '0',
            backgroundColor: '#c3c3c3'
        };
        result['&::after'] = {
            content: '""',
            position: 'absolute',
            height: '2px',
            width: '0',
            left: '4px',
            bottom: '0',
            backgroundColor: '#c3c3c3',
            transition: 'all 0.250s ease-out'
        };
        result['&:hover::after'] = {
            width: 'calc(100% - 8px)'
        }
        result['&:focus-within::after'] = {
            width: 'calc(100% - 8px)'
        }
        if (st.isInvalid) {
            result['&:hover'] = {
                borderWidth: '0px',
            }
            if (st.isFocused) {
                result.boxShadow = 'none';
            }
        } else if (st.isFocused) {
            result['&:hover'] = {
                borderWidth: '0px',
            }
        }
        return result;
    },
    indicatorSeparator: (cur, state) => ({
        ...cur,
        width: '0'
    }),
    dropdownIndicator: (cur, state) => {
        let res = {
            ...cur,
            color: '#999'
        };
        res['&:hover'] = {
            color: '#666'
        }
        return res;
    }
}

export const selectReadOnlyStyle = {
    control: (cur, st) => {
        let result = {
            ...cur,
            borderWidth: '0px',
            boxShadow: 'none',
            position: 'relative'
        };
        result['&::before'] = {
            content: '""',
            position: 'absolute',
            height: '1px',
            right: '4px',
            left: '4px',
            bottom: '0',
            backgroundColor: '#c3c3c3'
        };
        result['&::after'] = {
            content: '""',
            position: 'absolute',
            height: '2px',
            width: '0',
            left: '4px',
            bottom: '0',
            backgroundColor: '#c3c3c3',
            transition: 'all 0.250s ease-out'
        };
        if (st.isInvalid) {
            result['&:hover'] = {
                borderWidth: '0px',
            }
            if (st.isFocused) {
                result.boxShadow = 'none';
            }
        } else if (st.isFocused) {
            result['&:hover'] = {
                borderWidth: '0px',
            }
        }
        return result;
    },
    indicatorSeparator: (cur, state) => ({
        ...cur,
        width: '0'
    }),
    dropdownIndicator: (cur, state) => {
        let res = {
            ...cur,
            color: '#999',
            display: 'none'
        };
        res['&:hover'] = {
            color: '#999'
        }
        return res;
    }
}