import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';


const Spinner = ({ visible }) => {

    return (
        <>
            {visible &&
                <FontAwesomeIcon style={{ marginLeft: '10px' }} icon='fa-solid fa-spinner' spinPulse={true} />
            }
        </>
    );
}

export default Spinner;