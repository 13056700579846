import * as toastr from 'toastr';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../components/AppContext';
import AuthView from '../components/AuthView';
import DamageListComponent from '../components/check/DamageListComponent';
import ConsentListComponent from '../components/ConsentListComponent';
import FormSection from '../components/FormSection';
import PageForm from '../components/PageForm';
import TextItem from '../components/TextItem';
import { TT_RETURN, TT_TAKEOVER, TT_TAKEOVERANDRETURN } from '../constants/takeoverType';
import { getEnumOptions, getOption } from '../util/options';
import { parse } from 'qs';

import './Handover.js.scss';
import { useHistory, useLocation } from 'react-router';
import * as _ from 'lodash';
import PhotoListComponent from '../components/check/PhotoListComponent';
import apiService from '../util/api';
import Loader from '../components/Loader';
import Error from '../components/Error';
import { Button } from 'reactstrap';
import { CT_2, CT_3, CT_4, CT_5 } from '../constants/controlType';
import SelectItem from '../components/SelectItem';
import { joinNullable } from '../util/helper';


const Handover = () => {

    const history = useHistory();

    const { t, user, setPageTitle, pathConfig } = useContext(AppContext);

    const location = useLocation();
    let { car: carId, type } = parse(location.search.substring(1));


    const [data, setData] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [error, setError] = useState(null);
    const [consents, setConsents] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [reload, setReload] = useState(0);
    const [selectedParking, setSelectedParking] = useState(null);
    const [signal, setSignal] = useState(null);
    const [done, setDone] = useState(false);

    useEffect(() => {
        let ac = new AbortController();
        setSignal(ac.signal);
        return () => {
            ac.abort();
        };
    }, []);

    useEffect(() => {
        setPageTitle(() => {
            switch (type) {
                case TT_TAKEOVER:
                    return t('Handover.TakeoverTitle');
                case TT_RETURN:
                    return t('Handover.ReturnTitle');
                default:
                    return '';
            }
        });
    }, [type]);

    useEffect(() => {
        let key = new Date().getTime();
        setConsents(getAgreements(data, key));
        setPhotos(getPhotos(data, key));
    }, [data, type]);

    useEffect(() => {
        let ac = new AbortController();
        if (!!user) {
            if (!carId) {
                setError(t('Handover.NoCarId'));
            } else if (!type) {
                setError(t('Handover.NoType'));
            } else {
                loadData(carId, type, ac.signal);
            }
        } else {
            setError(null);
        }
        return () => {
            ac.abort();
        };
    }, [user, carId, type, reload]);

    useEffect(() => {
        setSelectedParking(prev => {
            if (data) {
                let opts = getParkongOptions(data);
                if (prev) {
                    let next = _.find(opts, prev);
                    if (next) {
                        return next;
                    } else {
                        return opts.length === 1 ? opts[0] : null;
                    }
                } else {
                    return opts.length === 1 ? opts[0] : null;
                }
            } else {
                return null;
            }
        });
    }, [data]);

    useEffect(() => {
        if (!done) {
            window.addEventListener('beforeunload', beforeLeave);
            return () => {
                window.removeEventListener('beforeunload', beforeLeave);
            }
        }
    }, [done]);

    const loadData = async (carId, checkType, signal) => {
        try {
            setDataLoading(true);
            let resp = await apiService.getHandoverData(carId, { checkType }, signal);
            if (!signal || !signal.aborted) {
                let data = resp.data || null;
                //if (data) {
                //    data.controlType = 4; // simulace dat
                //}
                setData(data);
                if (!data) {
                    setError(t('Handover.Nodata'));
                }
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
                setError('Nepovedlo se načíst data.');
            }
        } finally {
            if (!signal || !signal.aborted) {
                setDataLoading(false);
            }
        }
    }

    const save = async () => {
        if (data) {
            try {
                //setDataSending(true);
                let input = await validateAndGetDataToSend();
                if (input) {
                    let resp = await apiService.takeover(data.id, input, signal);
                    if (!signal || !signal.aborted) {
                        //toastr.success(t('Check.DataSaved'));
                        history.push(_.get(pathConfig, 'url', '/'), { info: 'handoverDone', type });
                        setDone(true);
                    }
                }
            } catch (error) {
                if (!signal || !signal.aborted) {
                    console.log(error);
                    toastr.error(_.get(error, 'response.data.message') || t('Check.DataSaveErr'));
                }
            } finally {
                if (!signal || !signal.aborted) {
                    //setDataSending(false);
                }
            }
        } else {
            toastr.error(t('Check.NoDataErr'));
        }
    }

    const unlockPlace = async () => {
        try {
            let input = {
                idStand: _.get(selectedParking, 'value')
            };
            if (input) {
                let resp = await apiService.unlockStand(data.id, input, signal);
                if (!signal || !signal.aborted) {
                    toastr.success(t('Handover.UnlockSuccess'));
                }
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.log(error);
                toastr.error(_.get(error, 'response.data.message') || t('Handover.UnlockError'));
            }
        }
    }

    const beforeLeave = e => {
        //console.log('beforeLeave');
        const msg = t('Check.BeforeLeave');
        (e || window.event).returnValue = msg;
        return msg;
    }

    const getName = data => {
        return joinNullable(' - ', _.get(data, 'carName'), _.get(data, 'registrationNumber'));
    }

    const getAgreements = (data, key) => {
        return ((data || {}).agreements || [])
            .filter(o => type ? (o.type === TT_TAKEOVERANDRETURN || type === o.type) : true)
            .map(o => ({ ...o, key }));
    }

    const getPhotos = (data, key) => {
        return ((data || {}).photos || [])
            .filter(o => type ? (o.type === TT_TAKEOVERANDRETURN || type === o.type) : true)
            .map(o => ({ ...o, key }))
    }

    const getBtnText = () => {
        const { controlType } = data || {};
        //console.log('dev', 'controlType', controlType);
        switch (type) {
            case TT_RETURN:
                switch (controlType) {
                    case CT_2:
                        return t('Handover.ReturnAndLock');
                    default:
                        return t('Check.ReturnBtn');
                }
            case TT_TAKEOVER:
                switch (controlType) {
                    case CT_2:
                    case CT_3:
                    case CT_4:
                        return t('Handover.TakeAndUnlock');
                    default:
                        return t('Check.TakeoverBtn');
                }
            default:
                return t('Btn.Send');
        }
    }

    const validateAndGetDataToSend = async () => {
        if (done) {
            return null;
        }

        let res = new FormData();
        //console.log('dev', 'type', type);
        if (!type) {
            toastr.error(t('Check.TypeRequired'));
            return null;
        }
        res.append('type', type);

        let c = consents || [];
        if (_.some(c, o => !o.checked)) {
            toastr.error(t('Check.NotAllConsentsChecked'));
            return null;
        }
        c.forEach(o => res.append('agreements', o.id));

        let p = photos || [];
        if (_.some(p, o => !o.imgFile)) {
            toastr.error(t('Check.NotAllPhotosLoaded'));
            return null;
        }
        p.forEach(o => {
            res.append('photoIds', o.id);
            res.append('photos', o.imgFile);
        });

        if (selectedParking) {
            res.append('idTabBikeStand', selectedParking.value);
        }

        return res;
    }

    const showParkingNo = () => {
        const { controlType } = data || {};
        return type === TT_TAKEOVER && (controlType === CT_4 || controlType === CT_3);
    };

    const showParkingSelect = () => {
        const { controlType } = data || {};
        return type === TT_RETURN && (controlType === CT_4 || controlType === CT_3);
    };

    const getParkongOptions = data => {
        let res = [];
        if (data && data.stands && data.stands.length) {
            res = data.stands.map(o => ({ value: o.id, label: o.name }))
        }
        return res;
    }

    const getUnlockBtnText = () => {
        if (type === TT_RETURN) {
            const { controlType } = data || {};
            switch (controlType) {
                case CT_3:
                    return t('Handover.UnlockBike');
                case CT_4:
                    return t('Handover.UnlockParking');
                default:
                    return undefined;
            }
        } else {
            return undefined;
        }
    }

    const showCode = () => {
        const { controlType } = data || {};
        return controlType === CT_5;
    }

    return (
        <AuthView>
            {dataLoading &&
                <Loader
                    text={t('Handover.DataLoading')}
                    delay={500}
                />
            }
            {!dataLoading && error &&
                <Error text={error} />
            }
            {!dataLoading && !error && !!done &&
                <div className='page-from-container' >
                    <div>{t(_.get(type, 'value') === TT_RETURN ? 'Check.ReturnDone' : 'Check.TakeDone')}</div>
                </div >
            }
            {!dataLoading && !error && !done &&
                <PageForm>
                    <FormSection
                        title={t('Check.Data')}
                        btnText={getUnlockBtnText()}
                        onBtnClick={unlockPlace}
                    >
                        <div className='prop-list'>
                            <TextItem
                                label={t('Handover.Name')}
                                value={getName(data)}
                                readOnly={true}
                            />
                            <TextItem
                                label={t('Handover.Place')}
                                value={_.get(data, 'parkingPlaceName')}
                                readOnly={true}
                            />
                            {showParkingNo() &&
                                <TextItem
                                    label={t('Handover.ParkingNo')}
                                    value={_.get(data, 'stands[0].name')}
                                    readOnly={true}
                                />
                            }
                            {showParkingSelect() &&
                                <SelectItem
                                    label={t('Handover.ParkingNo')}
                                    className="cs-form-select"
                                    options={getParkongOptions(data)}
                                    value={selectedParking}
                                    onChange={setSelectedParking}
                                    placeholder={''}
                                />
                            }
                            {showCode() &&
                                <TextItem
                                    label={t('Handover.Code')}
                                    value={_.get(data, 'takeoverCode')}
                                    readOnly={true}
                                />
                            }
                        </div>
                    </FormSection>
                    <DamageListComponent
                        damages={_.get(data, 'damages')}
                        regNum={_.get(data, 'registrationNumber')}
                        name={_.get(user, 'name', _.get(user, 'email'))}
                        email={_.get(user, 'email')}
                        carId={_.get(data, 'id')}
                        onChange={() => setReload(new Date().getTime())}
                    />
                    <ConsentListComponent
                        consents={consents}
                        type={type}
                    ></ConsentListComponent>
                    <PhotoListComponent
                        photos={photos}
                        onChange={setPhotos}
                        showDelimiter={false}
                    />
                    <div className='section-container'>
                        <div className='buttons'>
                            <Button
                                color="carshare"
                                onClick={save}
                            >{getBtnText()}</Button>
                        </div>
                    </div>
                </PageForm>
            }
        </AuthView>
    );
}

export default Handover;