const sk = {
    "Carshare": {
        "NoCar": "Aktuálne nie je žiadne voľné státie na zapožičanie"
    },
    "FinishSettingsRequiredModal": {
        "ReqIdentity": "Pred rezerváciou parkovacieho státia prosím overte Vašu totožnosť.",
        "ReqPhone": "Pred rezerváciou parkovacieho státia prosím doplňte kontaktný telefón.",
        "ReqPhoneAndIdentity": "Pred rezerváciou parkovacieho státia prosím doplňte kontaktný telefón a overte Vašu totožnosť."
    },
    "Nav": {
        "Title": "BikeParking"
    },
    "Reservation": {
        "Car": "Státie",
        "CarPlaceholder": "Vyberte státie",
        "LastCarForPeriod": "Vo zvolenom období je tu voľné posledné státie",
        "NoCarForModelPeriod": "Vo zvolenom období tu nie je voľný daný model nabíjačky",
        "NoCarForPeriod": "Vo zvolenom období tu nie je voľné státie",
        "PickUpTooltip": "Státie bude možné odomknúť až v čase rezervácie",
        "RepeteAfter": "Po objednaní prichystať rezerváciu ďalšieho státia",
        "Title": "Rezervácia parkovania"
    },
    "ReservationState": {
        "ReadyToTake": "Parkovanie zapožičané",
        "Taken": "Parkovanie zapožičané"
    },
    "ReservList": {
        "Return": "Odomknúť státie",
        "Takeover": "Odomknúť státie"
    }
}

export default sk;