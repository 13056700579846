import _ from "lodash";


export const toOption = o => {
    if (!o) {
        return null;
    }

    let res = { ...o, value: o.id, label: o.name };
    return res;
}

export const getEnumOption = (translate, key, value) => ({ value, label: translate(`${key}.${value}`) });
export const getEnumOptions = (translate, key, items) => items.map(o => getEnumOption(translate, key, o));

export const getOption = (options, value) => _.find(options, { value });