import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useHistory, useLocation, withRouter } from 'react-router';
import { Layout } from './Layout';
import classnames from 'classnames';
import ApiAuthorizationRoutes from './api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths, getLoginUrl, getLogoutUrl } from './api-authorization/ApiAuthorizationConstants';
import { AppContext } from './AppContext';
import "react-datepicker/dist/react-datepicker.css";
import i18n, { updatei18nResources } from '../i18n/i18n';
import Carshare from './carshare/Carshare';
import { LoginModal } from './LoginModal';
import apiService from '../util/api';
import { Helmet } from "react-helmet";
import qs from 'qs';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faLocationCrosshairs,
    faCheck,
    faLayerGroup,
    faFilter,
    faPlus,
    faMinus,
    faSpinner,
    faImage,
    faChevronLeft,
    faChevronRight,
    faCalendarDays as fasCalendarDays,
    faSpinner as fasSpinner,
    faCircleExclamation as fasCircleExclamation,
    faCirclePlus as fasCirclePlus,
    faLocationDot as fasLocationDot,
    faCircle as fasCicle,
    faCircleInfo as fasCircleInfo
} from '@fortawesome/free-solid-svg-icons';
import { faUserCircle, faClock, faCalendar as farCalendar } from '@fortawesome/free-regular-svg-icons';
library.add(
    faLocationCrosshairs,
    faCheck,
    faLayerGroup,
    faUserCircle,
    faFilter,
    faPlus,
    faMinus,
    faClock,
    faSpinner,
    faImage,
    faChevronLeft,
    faChevronRight,
    farCalendar,
    fasCalendarDays,
    fasSpinner,
    fasCircleExclamation,
    fasCirclePlus,
    fasLocationDot,
    fasCicle,
    fasCircleInfo
);
import CheckVehicle from './check/CheckVehicle';
import DamagePage from './damage/DamagePage';
import _ from 'lodash';
import Settings from './settings/Settings';
import { useState } from 'react';
import { useEffect } from 'react';
import { getPathConfig, useApiConfig } from '../util/config';
import { currentLanguage } from '../util/lang';
import * as path from 'path';
import Loader from './Loader';
import Error from './Error';

import '../style/custom.scss';
import '../style/modal.scss';
import '../style/sweetalert.scss';
import '../style/bootstrap.scss';
import Handover from '../containers/Handover';
import PaymentCallback from '../containers/PaymentCallback';
import authService from './api-authorization/AuthorizeService';
import { Contacts } from './Contacts';


const App = () => {
    //static displayName = App.name;

    let { pathname, search } = useLocation();
    let history = useHistory();
    const { config, configLoading, configError } = useApiConfig();

    const [user, setUser] = useState(undefined);
    const [userLoading, setUserLoading] = useState(false);
    const [bodyClass, setBodyClass] = useState('');
    const [login, setLogin] = useState(null);
    const [settings, setSettings] = useState(null);
    const [pageTitle, setPageTitle] = useState('');
    const [auth, setAuth] = useState(undefined);
    const [showContacts, setShowContacts] = useState(false);

    const pathConfig = getPathConfig(config, pathname);
    const qsParams = qs.parse(search && search.substr(1));
    const isInFrame = _.get(qsParams, 'inframe');

    const t = (...args) => {
        //console.log('dev', 'args', args);
        let key = _.get(pathConfig, 'key')
        if (key) {
            switch (args.length) {
                case 1:
                    args.push({ context: key });
                    break;
                case 2:
                    args[1] = { ...args[1], context: key };
                    break;
            }
        }
        let res = i18n.t(...args);
        return res;
    };

    function getIsSharingLayout() {
        let baseUrl = _.get(pathConfig, 'url', '/');
        let match = p => (pathname + '/').startsWith(path.join(baseUrl, p) + '/');
        let noSharing = match('check') || match('damage');
        return !noSharing;
    }

    const openApp = () => {
        window.open(window.location.origin + window.location.pathname, "csapp");
    }

    useEffect(() => {
        let subscription = authService.subscribe(checkAuthentication);
        return () => {
            authService.unsubscribe(subscription);
        }
    }, []);

    useEffect(() => {
        if (config) {
            let interval = window.setInterval(() => {
                loadUser();
            }, 300000);
            loadUser();
            checkAuthentication();
            return () => {
                window.clearInterval(interval);
            };
        }
    }, [config]);

    useEffect(() => {
        //console.log('dev', 'config', { config, configLoading, pathname, context });
        initBodyClass();
    }, [config, pathname]);

    useEffect(() => {
        if (pathname !== ApplicationPaths.ApiAuthorizationPrefix && auth === false && _.get(pathConfig, 'forceLogin') === true) {
            context.invokeLogin();
        }
    }, [pathname, auth])

    const checkAuthentication = async () => {
        let auth = await authService.isAuthenticated();
        setAuth(auth);
    }

    const loadUser = async () => {
        try {
            setUserLoading(true);

            let res = await apiService.getUserProfile();
            setUser(res.data);
        } catch (error) {
            console.error(error);
            setUser(null);
        } finally {
            setUserLoading(false);
        }
    }

    const context = {
        t,
        lang: currentLanguage,
        pathConfig,
        user,
        userLoading,
        loadUser,
        invokeLogin: args => setLogin({ key: new Date().getTime(), args }),
        config,
        configLoading,
        pageTitle,
        setPageTitle,
        showContacts: _ => setShowContacts(true),
        isSharingLayout: getIsSharingLayout(),
        isInFrame,
        openApp,
        openSettings,
        closeSettings
    };

    function logout() {
        history.push(getLogoutUrl(), { local: true })
    }

    function getContainerClass() {
        let res = {};

        if (pathConfig) {
            res[pathConfig.key] = true;
        }
        if (_.some(['check', 'damage'], o => pathname.startsWith(`${_.get(pathConfig, 'url', '')}/${o}`))) {
            res.form = true;
        }

        return res;
    }

    function initBodyClass() {
        if (pathConfig && pathConfig.key) {
            if (bodyClass !== pathConfig.key) {
                if (bodyClass) {
                    document.body.classList.remove(bodyClass);
                }
                document.body.classList.add(pathConfig.key);
                setBodyClass(pathConfig.key);
            }
        } else {
            if (bodyClass) {
                document.body.classList.remove(bodyClass);
                setBodyClass('');
            }
        }
    }

    function closeLogin() {
        setLogin(null);
    }

    function handleLogin() {
        loadUser();
        closeLogin();
    }

    function openSettings() {
        setSettings(new Date().getTime());
    }

    function closeSettings() {
        setSettings(null);
    }

    return (
        <AppContext.Provider value={context}>
            {config &&
                <Layout
                    key={_.get(pathConfig, 'url', '')}
                    className={classnames('carshare-app-container', getContainerClass())}
                    onLogout={logout}
                    onSettings={openSettings}
                >
                    <Helmet>
                        <title>{_.get(pathConfig, 'name', t('WebTitle'))}</title>
                    </Helmet>
                    <Switch>
                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                        <Route path={path.join(_.get(pathConfig, 'url', '/'), 'payment', 'callback', ':definitionId')}>
                            <PaymentCallback />
                        </Route>
                        <Route path={path.join(_.get(pathConfig, 'url', '/'), 'check')}>
                            <CheckVehicle />
                        </Route>
                        <Route path={path.join(_.get(pathConfig, 'url', '/'), 'damage')}>
                            <DamagePage />
                        </Route>
                        <Route path={path.join(_.get(pathConfig, 'url', '/'), 'handover')}>
                            <Handover />
                        </Route>
                        <Route path='*'>
                            <Carshare key={_.get(pathConfig, 'type', 'all')} />
                        </Route>
                    </Switch>
                    {login &&
                        <LoginModal
                            key={login.key}
                            args={login.args}
                            onSubmit={handleLogin}
                            onCancel={closeLogin}
                        />
                    }
                    {settings &&
                        <Settings
                            key={settings}
                            onClose={closeSettings}
                        />
                    }
                    {showContacts &&
                        <Contacts onClose={_ => setShowContacts(false)} />
                    }
                </Layout>
            }
            {!config && configLoading &&
                <Loader
                    text={context.t('App.Init')}
                    subtext={context.t('App.ConfigLoadign')}
                    //delay={1000}
                />
            }
            {!config && !configLoading && configError &&
                <Error text={context.t('App.ConfigError')} />
            }
        </AppContext.Provider>
    );
}

export default App;