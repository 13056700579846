import * as toastr from 'toastr';
import path from 'path';
import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { AppContext } from '../components/AppContext';
import apiService from '../util/api';
import qs from 'qs';
import Loader from '../components/Loader';


const PaymentCallback = () => {
    const { definitionId } = useParams();
    //console.log('dev', 'definitionId', definitionId);
    const { pathname, search } = useLocation();
    const history = useHistory();
    const { pathConfig, t } = useContext(AppContext);

    useEffect(() => {
        handleCallback();
    }, [])

    const handleCallback = async signal => {
        try {
            let params = qs.parse(search && search.substr(1));
            let resp = await apiService.handlePaymentCallback(definitionId, params, signal);
            if (!signal || !signal.aborted) {
                if (_.get(resp, 'data.data.paid')) {
                    toastr.success(t('PaymentResult.Success'));
                }
                history.replace({ pathname: _.get(pathConfig, 'url', '/') });
            }
        } catch (error) {
            if (!signal || !signal.aborted) {
                console.error(error);
                toastr.error(t('PaymentResult.Error'));
                history.replace({ pathname: _.get(pathConfig, 'url', '/') });
            }
        } finally {
            if (!signal || !signal.aborted) {
            }
        }
    }

    return (
        <Loader
            text={t('App.ProcPay')}
            subtext={t('App.ProcPayCallback')}
        />
    );
}

export default PaymentCallback;