const pl = {
    "Carshare": {
        "NoCar": "Obecnie nie ma możliwości bezpłatnego wypożyczenia roweru"
    },
    "Contacts": {
        "Manuals": {
            "Main": "Bikesharig",
            "MainFile": "xmarton_bikesharing_manual_cs.pdf",
            "MainName": "Xmarton Bikesharing - Návod.pdf.pdf"
        }
    },
    "Damage": {
        "DataNotLoadedErr": "Nie udało się załadować danych koła.",
        "FindCar": "Znajdź rower",
        "NoDataErr": "Nieistniejący lub nieautoryzowany rower.",
        "Title": "WADY ROWERU"
    },
    "Damages": {
        "NoDamages": "Rower nie ma zgłoszonych usterek."
    },
    "FinishSettingsRequiredModal": {
        "ReqIdentity": "Przed dokonaniem rezerwacji roweru prosimy o zweryfikowanie swojej tożsamości.",
        "ReqPhone": "Przed dokonaniem rezerwacji roweru prosimy o dodanie numeru telefonu kontaktowego.",
        "ReqPhoneAndIdentity": "Przed dokonaniem rezerwacji roweru prosimy o podanie numeru telefonu kontaktowego i zweryfikowanie swojej tożsamości."
    },
    "Handover": {
        "Name": "Rower",
        "Place": "Miejsce",
        "ReturnTitle": "ZWROT ROWERU",
        "SuccessReturnTitle": "Rower został pomyślnie zwrócony",
        "SuccessTakeoverTitle": "Rower został pomyślnie odebrany",
        "TakeoverTitle": "ODBIÓR ROWERU"
    },
    "Check": {
        "DataNotLoadedErr": "Nie udało się załadować danych koła.",
        "FindCar": "Znajdź rower",
        "NoDataErr": "Nieistniejący lub nieautoryzowany rower.",
        "ReturnBtn": "Zwróć rower",
        "ReturnDone": "Rower został pomyślnie zwrócony.",
        "TakeDone": "Rower został pomyślnie odebrany.",
        "TakeoverBtn": "Przejmij koło",
        "Title": "DOSTAWA ROWERU"
    },
    "Nav": {
        "Title": "ROWERY DO WYPOŻYCZENIA"
    },
    "Rent": {
        "Title": "Wypożyczalnia rowerów"
    },
    "Reservation": {
        "Car": "Rower",
        "CarNotAvaibleForPeriod": "Rower nie jest bezpłatny do wybranego okresu",
        "CarPlaceholder": "Wybierz rower",
        "LastCarForPeriod": "Ostatnia runda jest bezpłatna w wybranym okresie",
        "NoCarForModelPeriod": "Podany model roweru nie jest dostępny w wybranym okresie",
        "NoCarForPeriod": "W wybranym okresie nie ma darmowej rundy",
        "Parking": "Miejsce",
        "PickUpTooltip": "Odbiór roweru będzie możliwy wyłącznie w momencie rezerwacji.",
        "RepeteAfter": "Po złożeniu zamówienia przygotuj rezerwację kolejnego roweru",
        "Title": "Rezerwacja roweru"
    },
    "ReservationState": {
        "ReadyToTake": "Możesz przejąć rower",
        "Taken": "Pożyczony rower"
    },
    "ReservList": {
        "LoadCarDetailError": "Nie udało się załadować szczegółów koła.",
        "Return": "Zwróć rower",
        "Takeover": "Przejmij koło"
    }
}

export default pl;