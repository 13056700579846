import React, { useContext } from 'react';
import { AppContext } from '../AppContext';

import './FindClose.js.scss';


const FindClose = ({ onClick }) => {

    const { t } = useContext(AppContext);

    return (
        <div className='find-close-container'>
            <div
                className='find-close-btn'
                onClick={onClick}
            >{t('Map.FindClose')}</div>
        </div>
    );
}

export default FindClose;