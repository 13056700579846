const pl = {
    "Carshare": {
        "NoCar": "Obecnie nie ma wolnych miejsc parkingowych do wynajęcia"
    },
    "FinishSettingsRequiredModal": {
        "ReqIdentity": "Przed zarezerwowaniem miejsca parkingowego zweryfikuj swoją tożsamość.",
        "ReqPhone": "Przed dokonaniem rezerwacji miejsca parkingowego prosimy o dodanie numeru telefonu kontaktowego.",
        "ReqPhoneAndIdentity": "Przed zarezerwowaniem miejsca parkingowego prosimy o podanie numeru telefonu kontaktowego i zweryfikowanie swojej tożsamości."
    },
    "Nav": {
        "Title": "BikeParking"
    },
    "Reservation": {
        "Car": "Na stojąco",
        "CarPlaceholder": "Wybierz stoisko",
        "LastCarForPeriod": "W wybranym okresie wolne jest ostatnie miejsce parkingowe",
        "NoCarForModelPeriod": "Podany model ładowarki nie jest dostępny w wybranym okresie",
        "NoCarForPeriod": "W wybranym okresie nie ma bezpłatnego parkingu",
        "PickUpTooltip": "Parking zostanie odblokowany dopiero w momencie rezerwacji",
        "RepeteAfter": "Po złożeniu zamówienia przygotuj rezerwację kolejnego miejsca parkingowego",
        "Title": "Rezerwacja parkingu"
    },
    "ReservationState": {
        "ReadyToTake": "Parking wynajmowany",
        "Taken": "Parking wynajmowany"
    },
    "ReservList": {
        "Return": "Odblokuj parkowanie",
        "Takeover": "Odblokuj parkowanie"
    }
}

export default pl;