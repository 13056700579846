import React from 'react';


const XmartonIco = () => (
    <svg className="xmarton" version="1.1" id="Vrstva_1" x="0px" y="0px" viewBox="0 0 92.1 70.9" style={{ enableBackground: 'new 0 0 92.1 70.9' }}>
        <polygon style={{ fill: '#3399CC' }} points="39,31.9 12.1,5 5,5 35.4,35.4 0,70.9 0,63.8 28.3,35.4 0,7.1 0,0 14.2,0 14.2,0 42.5,28.3 " />
        <polygon style={{ fill: '#3399CC' }} points="63.8,35.4 92.1,63.8 92.1,70.9 77.9,70.9 49.6,42.5 53.1,39 80.1,65.9 87.1,65.9 56.7,35.4 92.1,0 92.1,7.1 " />
        <polygon style={{ fill: '#2E67B2' }} points="19.1,65.9 26.2,65.9 46.1,46.1 63.8,63.8 63.8,70.9 46.1,53.1 28.3,70.9 7.1,70.9 73,5 65.9,5 46.1,24.8 28.3,7.1 28.3,0 46.1,17.7 63.8,0 85,0 " />
    </svg>
)

export default XmartonIco;