import React, { useContext, useRef, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas'
import { AppContext } from '../AppContext';

import './SigInput.scss';
import { invoke } from '../../util/helper';
import { useEffect } from 'react';


const SigInput = ({ value, onChange, containerRef, reason }) => {

    const { t } = useContext(AppContext);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isWacom, setIsWacom] = useState(false);

    const sigEl = useRef(null);
    const divRef = containerRef || useRef();

    useEffect(() => {
        actionWhenRestarted(() => {
            console.log('Wacom is ready');
            setIsWacom(true);
        });
    }, []);

    const openDialog = () => {
        setDialogOpen(true);
        setTimeout(() => {
            let ratio = 1.0 / 3;
            let elm = document.getElementsByClassName('sig-canvas')[0];
            let coef = elm.offsetWidth / 600.0;
            elm.setAttribute('width', `${elm.offsetWidth}`);
            elm.setAttribute('height', `${elm.offsetWidth * ratio}`);
            let sp = sigEl.current.getSignaturePad();
            sp.maxWidth = 2.5 * coef;
            sp.minWidth = 0.5 * coef;
            sp.minDistance = 5 * coef;
            if (value) {
                sigEl.current.fromDataURL(value, { ratio: 1 });
            }
            // console.log('SigInput.openDialog', { elm, sig: sigEl.current, sp: sigEl.current.getSignaturePad() });
        }, 50);
    }

    const cancel = () => {
        setDialogOpen(false);
    }

    const save = () => {
        let val = sigEl.current.getCanvas().toDataURL('image/png');
        // console.log('SigInput.save', { val });
        invoke(onChange, val);
        setDialogOpen(false);
    }

    const clear = () => {
        sigEl.current.clear();
    }

    const wacom = () => {
        console.log('Loading from wacom.');
        window.capture(' ', reason, divRef.current, img => {
            // console.log('Načteno', img);
            if (img) {
                invoke(onChange, img);
            } else {
                console.log('No image.');
            }
        }, err => {
            console.log('Error capture by wacom.', err);
            openDialog();
        });
    }

    const handleClick = () => {
        console.log('Sig clicked.');
        if (isWacom) {
            wacom();
        } else {
            openDialog();
        }
    }

    return (
        <>
            <div
                className='sig-panel'
                onClick={handleClick}
                ref={divRef}
            >
                {value &&
                    <img
                        className='sig-img'
                        src={value}
                    />
                }
            </div>
            <>
                {dialogOpen &&
                    <Modal
                        className="sig-modal"
                        isOpen={true}
                        autoFocus={false}
                        toggle={cancel}
                        backdrop='static'
                    >
                        <ModalHeader toggle={cancel}>
                            <span>{t('Sig.Title')}</span>
                        </ModalHeader>
                        <ModalBody>
                            <div className='sig-canvas-container'>
                                <SignatureCanvas
                                    penColor='blue'
                                    canvasProps={{ width: 600, height: 200, className: 'sig-canvas' }}
                                    ref={sigEl}
                                />
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="carshare"
                                onClick={save}
                            >{t('Btn.Accept')}</Button>
                            <Button
                                color="outline-secondary"
                                onClick={clear}
                            >{t('Btn.Clear')}</Button>
                            <Button
                                color="outline-secondary"
                                onClick={cancel}
                            >{t('Btn.Cancel')}</Button>
                        </ModalFooter>
                    </Modal>
                }
            </>
        </>
    );
}

export default SigInput;