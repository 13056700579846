import React, { useContext } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { defaultLanguage, supportedLanguages } from '../../util/lang';
import { AppContext } from '../AppContext';

import './TakeoverInfoModal.js.scss';


export const TakeoverInfoModal = ({ onClose }) => {

    const { t, lang } = useContext(AppContext);

    const btnLang = supportedLanguages.includes(lang) ? lang : defaultLanguage;

    return (
        <Modal
            className={'takeover-info-modal'}
            isOpen={true}
            autoFocus={false}
            centered
            toggle={onClose}
            backdrop='static'
            size="sm"
        >
            <ModalHeader>
                <span>{t('TakeoverInfoModal.Title')}</span>
            </ModalHeader>
            <ModalBody>
                <p>{t('TakeoverInfoModal.Text')}</p>
                <div className="flex-col-center-container">
                    <div className="app-link-btns">
                        <div className="app-link-qr app-link-qr1">
                            <img src="/img/qr-fleet-ios.svg" loading="eager" />
                        </div>
                        <div className="app-link-btn app-link-btn1">
                            <a className="app-btn" href="https://apps.apple.com/cz/app/xfleet-by-xmarton/id1530906400" target="_blank">
                                <img src={`/img/btn-ios-${btnLang}.svg`} />
                            </a>
                        </div>
                        <div className="app-link-qr app-link-qr2">
                            <img src="/img/qr-fleet-android.svg" />
                        </div>
                        <div className="app-link-btn app-link-btn2">
                            <a className="app-btn" href="https://play.google.com/store/apps/details?id=com.xfleet.app" target="_blank">
                                <img src={`/img/btn-play-${btnLang}.png`} />
                            </a>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="flex-col-center-container">
                    <a
                        className="btn btn-carshare"
                        href="https://link.xmarton.com/xfleet"
                        target="xfleet"
                    >{t('TakeoverInfoModal.Open')}</a>
                    <Button
                        color="carshare"
                        onClick={onClose}
                    >{t('Btn.Close')}</Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}