import React, { useState } from 'react';
import LinkBtn from './LinkBtn';
import { Input } from 'reactstrap';
import Selectbox from './selectbox/Selectbox';

import './PhoneInput.js.scss';
import { invoke } from '../util/helper';

const czPrefix = { label: '+420', value: '+420' };
const skPrefix = { label: '+421', value: '+421' };

function PhoneInput({ phone, verifyBtn, onVerifyClick }) {

    const [prefix, setPrefix] = useState(czPrefix);
    const [value, setValue] = useState('');

    const phonePrefix = () => {
        if (phone.startsWith('+420') || phone.startsWith('00420'))
            return czPrefix;
        if (phone.startsWith('+421') || phone.startsWith('00421'))
            return skPrefix;
        return null;
    }

    const phonePostfix = () => {
        if (phone.startsWith('+420') || phone.startsWith('+421'))
            return phone.substring(4);
        if (phone.startsWith('00420') || phone.startsWith('00421'))
            return phone.substring(5);
        return phone;
    }

    return (
        <div className="phone-input-container">
            {(!phone || phonePrefix()) &&
                <Selectbox
                    className="cs-form-select"
                    options={[czPrefix, skPrefix]}
                    value={phone ? phonePrefix() : prefix}
                    onChange={setPrefix}
                    readonly={!!phone}
                />
            }
            <div className="custom-control-container readonly phone-value-container">
                <Input
                    value={phone ? phonePostfix() : value}
                    onChange={e => { if (!phone) setValue(e.target.value); }}
                    readOnly={!!phone}
                />
                {!phone && value &&
                    <LinkBtn text={verifyBtn} onClick={e => invoke(onVerifyClick, e, prefix.value + value)} />
                }
            </div>
        </div>
    );
}

export default PhoneInput;