import { registerLocale } from "react-datepicker";
import { cs, sk, enUS, de, pl } from 'date-fns/locale';
import { getLocStorItem, LSK_LANG, setLocStorItem } from "./store";

import * as moment from "moment";
import 'moment/locale/cs';
import 'moment/locale/sk';
import 'moment/locale/de';
import 'moment/locale/pl';

registerLocale('cs', cs);
registerLocale('sk', sk);
registerLocale('en', enUS);
registerLocale('de', de);
registerLocale('pl', pl);

export const defaultLanguage = 'en';
export const supportedLanguages = ['cs', 'sk', 'en', 'de', 'pl'];

//console.log('dev', 'locale', moment.locales());

function choseLanguage() {
    const navLngs = getNavigatorLanguages();
    const lngs = normalizeLanguages(navLngs).filter(o => supportedLanguages.includes(o));
    const storedLang = getLocStorItem(LSK_LANG);
    let res = storedLang || (lngs && lngs.length ? lngs[0] : defaultLanguage);
    console.log('Language detection:', { navigatorLanguages: navLngs, supportedLanguages, defaultLanguage, selectedLanguage: res, storedLang });
    return res;
}

function normalizeLanguages(languages) {
    if (!languages || !languages.length) {
        return [];
    }

    return languages.reduce((r, c) => {
        if (c) {
            let parts = c.split('-');
            if (parts && parts.length) {
                if (!r.includes(parts[0])) {
                    r.push(parts[0]);
                }
            }
        }
        return r;
    }, []);
}

function getNavigatorLanguages() {
    let found = [];

    if (typeof navigator !== 'undefined') {
        if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
            for (let i = 0; i < navigator.languages.length; i++) {
                found.push(navigator.languages[i]);
            }
        }
        if (navigator.userLanguage) {
            found.push(navigator.userLanguage);
        }
        if (navigator.language) {
            found.push(navigator.language);
        }
    }

    return found.length > 0 ? found : undefined;
}

export let currentLanguage = choseLanguage();
moment.locale(currentLanguage);

export function setCurrentLanguage(lang) {
    if (lang) {
        currentLanguage = lang;
        setLocStorItem(LSK_LANG, lang);
        moment.locale(lang);
    }
}