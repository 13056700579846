import React from 'react';

import './PageForm.js.scss';


const PageForm = ({ children }) => {
    return (
        <div className='page-from-container'>
            <div className='page-form'>
                {children}
            </div>
        </div>
    );
}

export default PageForm;