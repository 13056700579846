const de = {
    "Carshare": {
        "NoCar": "Derzeit gibt es keinen kostenlosen Fahrradverleih"
    },
    "Contacts": {
        "Manuals": {
            "Main": "Bikesharig",
            "MainFile": "xmarton_bikesharing_manual_cs.pdf",
            "MainName": "Xmarton Bikesharing - Návod.pdf.pdf"
        }
    },
    "Damage": {
        "DataNotLoadedErr": "Laufraddaten konnten nicht geladen werden.",
        "FindCar": "Finden Sie ein Fahrrad",
        "NoDataErr": "Nicht vorhandenes oder nicht autorisiertes Fahrrad.",
        "Title": "NACHTEILE DES FAHRRADS"
    },
    "Damages": {
        "NoDamages": "Es sind keine Mängel am Fahrrad gemeldet."
    },
    "FinishSettingsRequiredModal": {
        "ReqIdentity": "Bitte überprüfen Sie Ihre Identität, bevor Sie ein Fahrrad reservieren.",
        "ReqPhone": "Bitte geben Sie eine Kontakttelefonnummer an, bevor Sie ein Fahrrad reservieren.",
        "ReqPhoneAndIdentity": "Bevor Sie ein Fahrrad reservieren, fügen Sie bitte eine Kontakttelefonnummer hinzu und überprüfen Sie Ihre Identität."
    },
    "Handover": {
        "Name": "Fahrrad",
        "Place": "Ort",
        "ReturnTitle": "FAHRRADRÜCKGABE",
        "SuccessReturnTitle": "Das Fahrrad wurde erfolgreich zurückgegeben",
        "SuccessTakeoverTitle": "Das Fahrrad wurde erfolgreich abgeholt",
        "TakeoverTitle": "DAS FAHRRAD ERHALTEN"
    },
    "Check": {
        "DataNotLoadedErr": "Laufraddaten konnten nicht geladen werden.",
        "FindCar": "Finden Sie ein Fahrrad",
        "NoDataErr": "Nicht vorhandenes oder nicht autorisiertes Fahrrad.",
        "ReturnBtn": "Fahrrad zurückgeben",
        "ReturnDone": "Das Fahrrad wurde erfolgreich zurückgegeben.",
        "TakeDone": "Das Fahrrad wurde erfolgreich abgeholt.",
        "TakeoverBtn": "Übernimm das Steuer",
        "Title": "LIEFERUNG DES FAHRRADS"
    },
    "Nav": {
        "Title": "FAHRRÄDER ZU VERMIETEN"
    },
    "Rent": {
        "Title": "Fahrradverleih"
    },
    "Reservation": {
        "Car": "Fahrrad",
        "CarNotAvaibleForPeriod": "Das Fahrrad ist erst im ausgewählten Zeitraum kostenlos",
        "CarPlaceholder": "Wählen Sie ein Fahrrad aus",
        "LastCarForPeriod": "Die letzte Runde ist im gewählten Zeitraum kostenlos",
        "NoCarForModelPeriod": "Das angegebene Fahrradmodell ist im ausgewählten Zeitraum nicht verfügbar",
        "NoCarForPeriod": "Im ausgewählten Zeitraum gibt es keine kostenlose Runde",
        "Parking": "Ort",
        "PickUpTooltip": "Die Übernahme des Fahrrades ist nur zum Zeitpunkt der Buchung möglich.",
        "RepeteAfter": "Bereiten Sie nach der Bestellung die Reservierung eines weiteren Fahrrads vor",
        "Title": "Fahrradreservierung"
    },
    "ReservationState": {
        "ReadyToTake": "Sie können das Fahrrad übernehmen",
        "Taken": "Fahrrad ausgeliehen"
    },
    "ReservList": {
        "LoadCarDetailError": "Raddetail konnte nicht geladen werden.",
        "Return": "Fahrrad zurückgeben",
        "Takeover": "Übernimm das Steuer"
    }
}

export default de;