import * as classNames from 'classnames';
import React from 'react';

import './FormSection.js.scss';


const FormSection = ({ delimiter, title, logo, loading, children, btnText, onBtnClick }) => {
    return (
        <div className={classNames('form-section-container', { 'with-delimiter': !!delimiter })}>
            <div className='title'>
                <span className="title-text">{title}</span>
                {loading &&
                    <FontAwesomeIcon className='after-title-ico' icon='fa-solid fa-spinner' spinPulse={true} />
                }
                {logo &&
                    <div className="title-logo">
                        <img src={`api/img/${logo}`} />
                    </div>
                }
            </div>
            {children}
            {btnText &&
                <div className='btn-part'>
                    <button
                        className={'section-btn'}
                        onClick={onBtnClick}
                    >{btnText}</button>
                </div>
            }
        </div>
    );
}

export default FormSection;