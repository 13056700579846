import FileResizer from "react-image-file-resizer";

export const defaultCompressImgOptions = {
    maxWidth: 2016,
    maxHeight: 2016,
    compressFormat: 'JPEG',
    quality: 50,
    rotation: 0,
    outputType: 'blob'
}

export const compressImg = (file, options) => {
    return new Promise(resolve => {
        let o = { ...defaultCompressImgOptions, ...(options || {})};
        FileResizer.imageFileResizer(
            file, // the file from input
            o.maxWidth, // width
            o.maxHeight, // height
            o.compressFormat, // compress format WEBP, JPEG, PNG
            o.quality, // quality
            o.rotation, // rotation
            (compressedFile) => {
                console.log(`Image compressed: ${file.size} -> ${compressedFile.size}`);

                resolve(compressedFile);
            },
            o.outputType // blob or base64 default base64
        );
    });
}